import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import de from '@/locales/de.json'

const i18n = createI18n({
  locale: 'de',
  legacy: false,
  fallbackLocale: 'en',
  messages: {
    en,
    de
  }
})

const $t = i18n.global.t
try {
  if (window.navigator.language ?? undefined) {
    i18n.global.locale.value = window.navigator.language.toString().split('-')[0]
  }
}
catch (err) {
  i18n.global.locale.value = 'de'
  console.log(err)
}
export { i18n, $t }
