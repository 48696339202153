<!-- TODO: Translation -->
<template>
  <div class="flex flex-col items-center justify-center flex-grow w-full min-h-screen">
    <LoadingSpinner v-model="showSpinner" :fullPage="true"></LoadingSpinner>
    <div class="w-full max-w-xl space-y-8 bg-white border rounded-2xl border-mossgray-200">
      <div class="w-full p-12 pt-0 space-y-6">
        <div>
          <div class="flex justify-center px-16 py-8 mt-6 -mb-2">
            <img :src="img" />
          </div>

          <h2 class="text-lg text-center">
            <span v-if="inLoginProcess">{{ $t('login.text.login_in_process') }}<br />{{
              $t('login.text.login_in_process_2') }}</span>
          </h2>
        </div>
        <form class="space-y-4" action="#" method="POST" v-if="!inLoginProcess">
          <input type="hidden" name="remember" value="true" />
          <div class="-space-y-px rounded-md shadow-sm">
            <div>
              <label for="email-address" class="sr-only">{{ $t('email') }}</label>
              <input v-model="user.username" id="email-address" name="email" type="email" autocomplete="email" required
                class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border rounded-none appearance-none border-mossgray-200 rounded-t-md focus:outline-none focus:ring-mossgray focus:border-mossgray focus:z-10 sm:text-sm"
                :placeholder="$t('email')" />
            </div>
            <div>
              <label for="password" class="sr-only">{{ $t('password') }}</label>
              <input v-model="user.password" id="password" name="password" type="password"
                autocomplete="current-password" required
                class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border rounded-none appearance-none border-mossgray-200 rounded-b-md focus:outline-none focus:ring-mossgray focus:border-mossgray focus:z-10 sm:text-sm"
                :placeholder="$t('password')" />
            </div>
          </div>
          <div class="text-sm text-center">
            <div class="text-red-500" v-text="message"></div>
            <div class="pb-2 my-2" v-show="showRedirect">{{ $t('login.text.automatic_redirect') }}</div>
          </div>

          <div>
            <button type="submit" @click.prevent="handleLogin"
              class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md group bg-mossgray hover:bg-white hover:text-mossgray ring-2 ring-mossgray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mossgray">
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg class="w-5 h-5 text-white group-hover:text-mossgray" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd" />
                </svg>
              </span>
              {{ $t('login.text.login') }}
            </button>
          </div>
          <div class="px-2 text-center">
            <router-link :to="{ name: 'ResetPassword' }" class="text-sm font-medium hover:underline">
              {{ $t('login.text.forget_password') }}
            </router-link>
            <div class="mt-2 text-base leading-4 text-center" v-if="!inLoginProcess">
              <div class="mt-4 text-sm">
                {{ $t('login.text.no_account_yet') }}<br />
                <router-link to="/registration" class="font-medium hover:underline">
                  {{ $t('login.text.register.now', { trial_days: trial_days }) }}
                </router-link>
              </div>
            </div>
          </div>
        </form>
        <div class="" v-if="!inLoginProcess">
          <div class="mb-2 text-sm font-semibold">{{ $t('login.text.additional_login_variants') }}:</div>
          <div class="flex flex-col mb-2 border divide-y rounded-md divide-mossgray-200 border-mossgray-200">
            <a v-bind:href="microsoft_oauth" class="flex items-center p-3 space-x-3 rounded-t-md hover:underline">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                <title>MS-SymbolLockup</title>
                <rect x="1" y="1" width="9" height="9" fill="#f25022" />
                <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
                <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
                <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
              </svg>
              <span class="text-sm font-semibold">{{ $t('login.text.sso.microsoft') }}</span>
            </a>
            <router-link to="/sso" class="flex items-center p-3 space-x-4 rounded-b-md hover:underline">
              <font-awesome-icon :icon="['fa-kit', 'tl-key']" />
              <span class="text-sm font-semibold">
                SSO
                <span v-if="ssoAddress">( <span class="text-mossgray" v-text="ssoAddress"></span> )</span></span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthUserStore } from '@/stores/auth-user'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import deckService from '@/services/deck.service'
import DesktopService from '@/services/desktop.service'
import { captureException } from '@sentry/vue'
import img from '@/assets/timelink_primary_logo_5f_moosgrauRGB.svg'
import { $t } from '@/config/i18n'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const authUserStore = useAuthUserStore()
const desktop = DesktopService
const trial_days = APP_TEST_DAYS
const api_domain = import.meta.env.VITE_API_URL
const route = useRoute()
const router = useRouter()

const message = ref('')
const user = ref({
  username: '',
  password: ''
})
const domain = ref(null)
const set_callback = ref(false)
const microsoft_oauth = ref(api_domain + '/oauth/microsoft/')
const redirect_url = ref('')
const showSpinner = ref(false)
const showRedirect = ref(false)
const ssoAddress = ref(null)
const inLoginProcess = ref(false)

const loggedIn = computed(() => {
  return authUserStore.isAuthenticated
})


onMounted(() => {
  if (authUserStore.salt == null) {
    authUserStore.salt = Math.random().toString(20).substr(2, 6)
  }
  loadStreamDeck()
  if (desktop.getConfig('ssoDomain')) {
    ssoAddress.value = desktop.getConfig('ssoDomain')
  }
})
created()

function created() {
  if (route.query['error_code']) {
    const error_code = route.query['error_code'] ?? null
    if (error_code == 10) {
      message.value =
        $t('login.errors.user_not_active_or_not_found')
    }
    else if (error_code == 16) {
      message.value =
        $t('login.errors.company_deleted')
    }
    else if (error_code == 20) {
      message.value =
        $t('login.errors.not_enough_licenses')
    }
    else if (error_code == 31) {
      message.value = $t('login.errors.invitation_no_same_email')
    }
    else if (error_code == 50) {
      message.value = $t('login.errors.not_supported_login_method')
    } else if (error_code == 70) {
      message.value = $t('login.errors.canceled_sso_login')
    } else if (error_code == 71) {
      message.value = $t('login.errors.sso_session_expired')
    } else if (error_code != null) {
      message.value = $t('login.errors.unknown')
    }
  }
  if (authUserStore.salt == null) {
    authUserStore.salt = Math.random().toString(20).substr(2, 6)
  }
  const givenTokenSalt = route.params['token'] ?? null
  const wasInvited = route.query['invited'] ?? false
  let givenToken = givenTokenSalt ?? null
  if (givenTokenSalt != null) {
    let givenTokenRaw
    try {
      givenTokenRaw = atob(givenTokenSalt)
    } catch (error) {
      givenTokenRaw = givenTokenSalt
    }
    let givenSalt = givenTokenRaw.substring(0, authUserStore.salt.length)
    if (givenSalt != authUserStore.salt) {
      givenToken = givenTokenRaw
    } else {
      givenToken = givenTokenRaw.substring(authUserStore.salt.length)
    }
  }
  if (!set_callback.value) {
    redirect_url.value = window.location.toString()
    redirect_url.value = redirect_url.value.substring(
      0,
      redirect_url.value.indexOf('/login') + '/login'.length
    )
    microsoft_oauth.value =
      microsoft_oauth.value +
      authUserStore.salt +
      '?redirect=' +
      encodeURIComponent(redirect_url.value)
  }
  if (givenToken) {
    inLoginProcess.value = true
  }
  setTimeout(() => {
    authUserStore
      .checkLoginStatus(givenToken)
      .then((response) => {
        showSpinner.value = false
        inLoginProcess.value = false
        if (response == true) {
          authUserStore.checkInvitationStatus()
          authUserStore.showInvitationScreen = Boolean((wasInvited) ?? authUserStore.showInvitationScreen ?? false)
          router.push('/')
        } else if (response === false) {
          if (givenToken) {
            router.push('/login' + (givenToken ? '?error_code=99' : ''))
            message.value = $t('login.errors.unknown')
          }
        } else if (response === null) {
          router.push('/login' + (givenToken ? '?error_code=98' : ''))
          if (givenToken) {
            message.value = $t('login.errors.unknown')
          }
        }
      })
      .catch((error) => {
        router.push('/login' + (givenToken ? '?error_code=99' : ''))
        if (givenToken) {
          message.value = $t('login.errors.unknown')
        }
        inLoginProcess.value = false
        captureException(error)
      })
  }, 50)

  if (loggedIn.value) {
    router.push('/profile')
  }
}


async function handleLogin() {
  // loading.value = true
  try {
    await authUserStore.loggedIn(user.value)
    router.push('/')
  } catch (error) {
    if (error.response) {
      if (error.response.status == 401 || error.response.status == 422) {
        if ((error.response?.data?.error_code ?? 0) == 16) {
          message.value = $t('login.errors.company_deleted')
        }
        else {
          message.value = $t('login.errors.invalid_credentials')
        }
      }
      if (error.response.status == 403) {
        message.value = $t('login.errors.user_not_active')
      }
      if (error.response.status == 400) {
        if (error.response.data.error_code == 10) {
          if (error.response.data?.url) {
            handleSso(error.response.data.url)
          }
          if (error.response.data.provider == 'microsoft') {
            message.value = $t('login.errors.forced_oauth.microsoft')
          } else {
            message.value = $t('login.errors.forced_oauth.other')
          }
        }
      }
    } else if (error.code === 'ERR_NETWORK') {
      message.value = $t('login.errors.no_connection')
    } else {
      message.value = $t('errors.ups')
    }
  }
}
async function handleSso(url) {
  showRedirect.value = true
  redirect_url.value = window.location.toString()
  redirect_url.value = redirect_url.value.substring(0, redirect_url.value.indexOf('/login'))
  redirect_url.value = redirect_url.value + '/login'
  let redirect_to = url
  redirect_to =
    redirect_to +
    '/' +
    authUserStore.salt +
    '?redirect=' +
    encodeURIComponent(redirect_url.value) +
    (user.value.username != '' ? '&email=' + user.value.username : '')

  await new Promise((r) => setTimeout(r, 3000))
  window.location.href = redirect_to
}
async function loadStreamDeck() {
  await deckService.init()
  await deckService.showLoginScreen()
}
</script>
