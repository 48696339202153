import panelService from "@/services/panel.service"
import timelinkStoresService from "@/services/timelink-stores.service"
import { useTimeEntryStore } from "@/stores/timeEntry"
import { nextTick, reactive, ref, shallowRef, toRaw, toValue, watch } from "vue"
import { icon, findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import viewDashboard from "./views/dashboard"
import deckService from "@/services/deck.service"
import viewTracking from "./views/tracking"
import { $t } from "@/config/i18n"
import Clients from "./views/selections/Clients"
import Projects from "./views/selections/Projects"
import Services from "./views/selections/Services"
import { useImagesStore } from "@/stores/images"
import idleService from "@/services/idle.service"
import { useAuthUserStore } from "@/stores/auth-user"
import viewLock from "./views/lock"
import arraylib from "@/lib/arraylib"
import { useDebuggingStore } from "@/stores/debugging"


/**
 * @callback PanelGridItemCallback
* @param {number} id
* @returns {?PanelGridItemCallbackInternal}
*/

/**
 * @callback PanelGridItemCallbackInternal
 * @returns {any}
 */

/**
 * @typedef {?('dashboard'|'tracking'|'selection'|'lock')} ViewManagerView
 * @typedef {?('client_id'|'service_id'|'project_id'|'tasks')} ViewManagerSubView
 * @typedef {?('last_used'|'alphabetical'|'historical')} ViewManagerInnerView
 * @typedef {?(Number|String)} ViewManagerPage
 * @typedef {{
 * id: number,
 * view: ViewManagerView,
 * subView: ViewManagerSubView,
 * innerView: ViewManagerInnerView,
 * page: ViewManagerPage,
 * lastChange: number,
 * updateTime: number,
 * type: ?('text'|'image'),
 * text: string,
 * image: ?any,
 * color: ?string,
 * bgColor: ?string,
 * storeItem: ?any,
 * callback: ?PanelGridItemCallback,
 * interval: ?PanelGridItemCallback,
 * intervalTime: ?number,
 * timeout: ?PanelGridItemCallback,
 * timeoutTime: ?number,
 * init: ?PanelGridItemCallback
 * }} PanelGridItem
 */



// the view manager will be responsible for managing the views and the change of the view
class ViewManager {
    static manageableRequiredFields = ['client_id', 'service_id', 'project_id']
    /**
     * @type {import('vue').Ref<ViewManagerView>}
     */
    view = null
    /**
     * @type {import('vue').Ref<ViewManagerSubView>}
     */
    subView = null

    /**
     * @type {import('vue').Ref<ViewManagerInnerView>}
     */
    innerView = null

    /**
     * @type {import('vue').Ref<ViewManagerPage>}
     */
    page = null

    /**
     * @type {import('vue').Ref<Boolean>}
     */
    initialized = false

    initializedFirstPanel = false

    /**
     * @type {import('vue').Ref<number>}
     */
    lastChange = null

    /**
     * @type {import('vue').Ref<Array<{
     * view: ViewManagerView,
     * subView: ViewManagerSubView,
     * innerView: ViewManagerInnerView,
     * page: ViewManagerPage,
     * lastChange: number
     * }>>}
     */
    history = []

    /**
     * @type {Array<PanelGridItem>}
     */
    panel = []

    /**
     * @type {import('vue').Ref<{columns: number, rows: number}>}
     */
    dimensions = null

    lastSendToDeck = null

    /**
     * @type {import('vue').Ref<Boolean>}
     */
    updateRequested = false


    debug = false
    timing = false

    subscription = null

    /**
     * @type {import('vue').Ref<Boolean>}
     */
    panelLock = false

    /**
     * @type {import('vue').Ref<Boolean>}
     */
    buttonLock = false

    constructor() {
        this.resetManager()
    }

    resetManager() {
        this.view = ref(null)
        this.subView = ref(null)
        this.page = ref(null)
        this.innerView = ref(null)
        this.initialized = ref(false)
        this.lastChange = ref(null)
        this.history = ref([])
        this.panelLock = ref(false)
        this.buttonLock = ref(false)
        this.dimensions = ref({
            columns: 5,
            rows: 3
        })
        this.updateRequested = ref(false)
        this.initializedFirstPanel = ref(false)
        if (typeof this.subscription == 'function') {
            const func = this.subscription
            func()
        }
        this.initPanelGrid()
    }

    // don't move it inside the constructor it will fail based on pinias initialization order
    init() {
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'call init')
        }
        if (this.initialized.value) {
            if (this.debug) {
                useDebuggingStore().addEntry('debug', 'ViewManager', 'already initialized')
            }
            return
        }
        // set default values
        this.view.value = null
        this.subView.value = null
        this.page.value = null
        this.lastChange.value = null
        this.history.value = []
        this.initialized.value = true
        this.resetIds(arraylib.range(0, 15), true)

        // start checking which screen should be shown first
        if (this.hasActiveTracking()) {
            this.switchToTracking()
        }
        else {
            this.switchToDashboard()
        }

        // watcher -- later more
        this.subscription = useTimeEntryStore().$subscribe((mutation, payload) => {
            // useDebuggingStore().addEntry('debug', mutation', 'payload)
            this.startTimeoutForNextCheck()
        })
    }

    /*
    panel lock handling
     */

    /**
     * It is a very simple and unbound locking mechanism for panel actions.
     * @param {number|null} max_timeout - how long should the lock hold until it will be released by itself
     * @returns {boolean} - True if the lock could be aquired false if not.
     */
    acquireLock(max_timeout = 30_000) {
        if (this.panelLock.value) {
            if (this.debug) {
                useDebuggingStore().addEntry('debug', 'ViewManager', 'lock is already acquired')
                // console.trace()
            }
            return false
        }
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'acquired lock')
        }
        this.panelLock.value = true
        this.releaseLock(max_timeout)
        return true
    }

    async releaseLock(timeout = 0) {
        if (timeout != 0) {
            timelinkStoresService.setOrRenewTimeout('viewManager', 'resetLock', () => {
                this.panelLock.value = false
                if (this.debug) {
                    useDebuggingStore().addEntry('debug', 'ViewManager', 'lock released')
                }
            }, timeout)
        }
        else {
            timelinkStoresService.removeTimeout('viewManager', 'resetLock')
            this.panelLock.value = false
            if (this.debug) {

                useDebuggingStore().addEntry('debug', 'ViewManager', 'lock released')
            }
        }
    }

    acquireButtonLock(max_timeout = 30_000) {
        if (this.buttonLock.value) {
            if (this.debug) {
                useDebuggingStore().addEntry('debug', 'ViewManager', 'button lock is already acquired')
                // console.trace()
            }
            return false
        }
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'acquired button lock')
        }
        this.buttonLock.value = true
        this.releaseButtonLock(max_timeout)
        return true
    }

    async releaseButtonLock(timeout = 0) {
        if (timeout != 0) {
            timelinkStoresService.setOrRenewTimeout('viewManager', 'resetButtonLock', () => {
                this.buttonLock.value = false
                if (this.debug) {
                    useDebuggingStore().addEntry('debug', 'ViewManager', 'button lock released')
                }
            }, timeout)
        }
        else {
            timelinkStoresService.removeTimeout('viewManager', 'resetButtonLock')
            this.buttonLock.value = false
            if (this.debug) {
                useDebuggingStore().addEntry('debug', 'ViewManager', 'button lock released')

            }
        }
    }


    /**
     * 
     */
    switchTo() {
        if (this.hasActiveTracking()) {
            this.switchToTracking()
        }
        else {
            this.switchToDashboard()
        }
    }

    resetSubView() {
        this.subView.value = null
        this.innerView.value = null
        this.page.value = null
    }

    resetInnerView() {
        this.innerView.value = null
        this.page.value = null
    }

    resetPage() {
        this.page.value = null
    }

    updateLastChange() {
        this.lastChange.value = Date.now()
    }


    // fifo
    pushToHistory() {
        if (this.view.value === null) {
            return
        }
        const previous = this.getActualStateAsObject()
        this.history.value.push(previous)
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'History', { ...this.history.value })
        }

        if (this.history.value.length > 10) {
            this.history.value.shift()
        }

        return previous
    }

    getActualStateAsObject() {
        return {
            view: toValue(this.view),
            page: toValue(this.page),
            innerView: toValue(this.innerView),
            subView: toValue(this.subView),
            lastChange: toValue(this.lastChange)
        }
    }

    getPreviousHistory(removeLock = true) {
        let history = this.history.value
        if (removeLock) {
            history = history.filter((item) => item.view != 'lock')
        }
        return history[history.length - 1] ?? null
    }

    /**
     * @param {boolean} removeLock
     * @returns {{[key: string]: {first: any, second: any}}}
     */
    getDiffOfActualAndPrevious(removeLock = false) {
        let previous = this.getPreviousHistory(removeLock)
        const actual = this.getActualStateAsObject()
        if (!previous) {
            // otherwise it could prevent the loading of some selection parts
            previous = {}
        }

        return this.getDiffBetweenStates(actual, previous)
    }

    /**
     * @param {[key: string]: any} first
     * @param {[key: string]: any} second
     * @returns {{[key: string]: {first: any, second: any}}}
     */
    getDiffBetweenStates(first, second) {
        if (!first && !second) {
            throw new Error('No parameters are given. Or both are empty!')
        }
        if (!first) {
            first = {}
        }
        if (!second) {
            second = {}
        }
        const diffObj = {}
        Object.entries(first).forEach((item) => {
            if (second[item[0]] != first[item[0]]) {
                diffObj[item[0]] = {
                    first: first[item[0]] ?? null,
                    second: second[item[0]] ?? null
                }
            }
        })
        return diffObj
    }

    getDimensions() {
        return this.dimensions.value
    }

    getDefaultInnerView() {
        return useAuthUserStore().user?.settings?.panel?.selection?.innerView ?? 'last_used'
    }


    /*
     * condition functions 
     **/

    hasActiveTracking() {
        return Boolean(useTimeEntryStore().activeTimeEntry)
    }

    hasActiveWizard() {
        // TODO Verweis auf docs 
        return (useAuthUserStore().user?.settings?.panel?.wizard ?? true)
    }

    hasRemainingRequiredFields() {
        return Boolean(this.getRemainingRequiredFields().length)
    }

    canBeLocked() {
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'Can be locked?', [idleService.isStarted() && (useAuthUserStore()?.user?.settings?.autoLockPanel ?? true) && idleService.screenState() == 'locked', idleService.isStarted(), (useAuthUserStore()?.user?.settings?.autoLockPanel ?? true), idleService.screenState() == 'locked'])
        }
        return idleService.isStarted() && (useAuthUserStore()?.user?.settings?.autoLockPanel ?? true) && idleService.screenState() == 'locked'
    }

    getRemainingRequiredFields() {
        // return []
        let validate = useTimeEntryStore().validate(useTimeEntryStore().getActiveTimeEntry)
        return typeof validate == 'boolean' ? [] : validate.filter((item) => ViewManager.manageableRequiredFields.includes(item))
        // return ['client_id', 'service_id', 'project_id']
    }

    getActiveTimeEntry() {
        return useTimeEntryStore().getActiveTimeEntry
    }

    /*
     * switch functions
     **/

    switchToDashboard() {
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'start switch to dashboard')
        }
        if (this.hasActiveTracking()) {
            // switch to tracking
            this.switchToTracking()
            return
        }
        this.showDashboard()
    }

    switchToTracking() {
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'start switch to tracking')
        }
        if (!this.hasActiveTracking()) {
            this.switchToDashboard()
            return
        }
        if (this.hasActiveWizard()) {
            //TODO: Switch to project selection - reset project if not required - Kein Projekt wählen 
            if (this.hasRemainingRequiredFields()) {
                this.switchToSelectionBasedOnRemainingFields()
            }
            else {
                this.showTracking()
            }
        }
        else {
            this.showTracking()
        }
    }

    /**
     * 
     * @param {('client_id'|'project_id'|'service_id')} type 
     */
    switchToTrackingThroughQuickAccess(type) {
        if (this.hasActiveWizard()) {
            if (type == 'client_id') {
                this.switchToSelection('project_id')
            }
            else if (type == 'project_id') {
                this.switchToSelection('service_id')
            }
        }
        else {
            this.switchToTracking()
        }
    }

    switchToSelectionBasedOnRemainingFields() {
        if (this.debug) {

            useDebuggingStore().addEntry('debug', 'ViewManager', 'start switch to selection based on remaining fields')
        }
        let remaining = this.getRemainingRequiredFields()
        if (this.subView.value == 'client_id') {
            if (!remaining.includes('project_id')) {
                remaining.unshift('project_id')
            }
        }
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'remaining', remaining)
        }
        this.switchToSelection(remaining[0])
    }

    /**
     * 
     * @param {ViewManagerSubView} userSelection 
     * @param {ViewManagerInnerView} innerView 
      * @param {ViewManagerPage} page
     */
    switchToSelection(userSelection = null, innerView = null, page = null) {
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'start switch to selection', [userSelection, innerView, page])
        }
        if (!this.hasActiveTracking()) {
            this.switchToDashboard()
            return
        }

        this.showSelection(userSelection, innerView, page)
    }

    /**
     * 
     * @param {ViewManagerInnerView} innerView 
     * @param {ViewManagerPage} page
     */
    switchToInnerView(innerView = null, page = null) {
        if (toValue(this.view) != 'selection') {
            return
        }

        this.switchToSelection(toValue(this.subView), innerView, page)
    }
    fixedRotation = ['last_used', 'alphabetical']
    /**
     * Only for selection at the moment
     * @returns 
     */
    rotateInnerView() {
        if (this.view.value != 'selection') {
            return
        }

        this.switchToInnerView(this.getNextRotationInnerView())
    }

    /**
     * 
     * @param {ViewManagerPage} page 
     */
    switchToPage(page = null) {
        if (toValue(this.view) != 'selection') {
            return
        }

        this.switchToInnerView(toValue(this.innerView), page)
    }

    switchToNextPage() {
        const page = toValue(this.page) ?? 0

        this.switchToPage(page + 1)
    }

    switchToPreviousPage() {
        const page = toValue(this.page) ?? 1

        if (page == 0) {
            page = 1
        }

        this.switchToPage(page - 1)
    }

    switchToPreviousScreen() {
        const previous = this.getPreviousHistory()
        if (previous.view == 'dashboard') {
            this.switchToDashboard()
        }
        if (previous.view == 'tracking') {
            this.switchToTracking()
        }
        if (previous.view == 'selection') {
            this.switchToSelection(previous.subView, previous.innerView, previous.page)
        }
    }

    switchToLock() {
        if (this.canBeLocked()) {
            if (this.debug) {
                useDebuggingStore().addEntry('debug', 'ViewManager', 'switch to lock screen')
            }
            this.showLock()
        }
    }

    unlockIfLocked() {
        if (this.view.value != 'lock') {
            // let foundLock = false
            // for (item of this.panel) {
            //     if (item.view == 'lock') {
            //         foundLock = true
            //     }
            // }
            // if (!foundLock) {
            //     return
            // }
            return
        }
        if (idleService.screenState() == 'locked') {
            return
        }

        this.switchToPreviousScreen()
    }


    /**
     * 
     * @returns {ViewManagerInnerView}
     */
    getNextRotationInnerView() {
        //TODO: add own user rotation? - later
        const actualInnerView = toValue(this.innerView) ?? this.getDefaultInnerView()
        const index = this.fixedRotation.findIndex((item) => item == actualInnerView)

        let nextIndex = index + 1
        if (nextIndex == this.fixedRotation.length) {
            nextIndex = 0
        }

        return this.fixedRotation[nextIndex]
    }

    showDashboard() {
        if (this.view.value == 'dashboard') {
            return
        }
        this.pushToHistory()
        this.view.value = 'dashboard'
        this.resetSubView()

        this.handlePanel()
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'show dashboard')
        }
    }

    showTracking() {
        if (this.view.value == 'tracking') {
            return
        }
        this.pushToHistory()
        this.view.value = 'tracking'
        this.resetSubView()

        this.handlePanel()
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'show tracking')
        }
    }

    /**
     * @param {ViewManagerSubView} type
     * @param {ViewManagerInnerView} innerView
     * @param {ViewManagerPage} page 
     */
    showSelection(type, innerView = null, page = null) {
        if (type == null) {
            return
        }
        if (innerView == null) {
            innerView = toValue(this.innerView) ?? this.getDefaultInnerView()
        }
        if (this.view.value == 'selection' && this.subView.value == type && (this.innerView.value == innerView || innerView == null) && this.page.value == page) {
            return
        }

        if (this.subView.value != type) {
            innerView = this.getDefaultInnerView()
            page = null
        }
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'show selection', [type, innerView, page])
        }

        this.pushToHistory()
        this.view.value = 'selection'
        this.subView.value = type
        this.innerView.value = innerView
        this.page.value = page

        this.updateLastChange()

        this.handlePanel()
    }

    showLock() {
        if (this.view.value == 'lock') {
            return
        }
        this.pushToHistory()
        this.view.value = 'lock'
        this.subView.value = null
        this.innerView.value = null
        this.page.value = null

        this.updateLastChange()

        this.handlePanel()
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'show lock')
        }
    }

    handlePanel() {
        timelinkStoresService.removeAllIntervalsFrom('panel')
        timelinkStoresService.removeAllTimeoutsFrom('panel')
        timelinkStoresService.removeAllWatcher('panel')


        this.acquireButtonLock(300)
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'handle panel view change', [this.view.value, this.subView.value, this.innerView.value, this.page.value])
        }

        if (this.view.value == 'dashboard') {
            viewDashboard(this)
            // panelService.loadActualPanelPage('start', toValue(this.page.value))
        }
        else if (this.view.value == 'tracking') {
            if (this.debug) {
                useDebuggingStore().addEntry('debug', 'ViewManager', 'handle panel view change to tracking ')
            }
            viewTracking(this)
            // panelService.loadActualPanelPage('recording', toValue(this.page.value))
        }
        else if (this.view.value == 'selection') {
            let selection = null
            if (this.subView.value == 'client_id') {
                selection = Clients
            }
            if (this.subView.value == 'project_id') {
                selection = Projects
            }
            if (this.subView.value == 'service_id') {
                selection = Services
                // panelService.loadActualPanelPage('selectService', toValue(this.page.value))
            }
            if ('handle' in selection) {
                if (this.debug) {
                    useDebuggingStore().addEntry('debug', 'ViewManager', 'Handle selection')
                }
                selection.handle(this)
            }
        }
        else if (this.view.value == 'lock') {
            viewLock(this)
        }

        this.sendToHwPanel()
        // this.releaseButtonLock()
    }

    getPanel() {
        return this.panel
    }


    /*
     * Watcher and handler
     */



    startTimeoutForNextCheck() {
        if (this.panelLock.value) {
            return
        }
        timelinkStoresService.setOrRenewTimeout('viewManager', 'startTimeoutForNextCheck', () => {
            this.checkIfViewShouldSwitch()
        }, 100)
    }

    async checkIfViewShouldSwitch() {
        await nextTick()
        if (this.view.value == 'dashboard') {
            if (this.hasActiveTracking()) {
                this.switchToTracking()
                return
            }
        }
        if (this.view.value == 'tracking') {
            if (!this.hasActiveTracking()) {
                this.switchToDashboard()
                return
            }
            else {
                if (this.hasActiveWizard() && this.hasRemainingRequiredFields()) {
                    this.switchToSelectionBasedOnRemainingFields()
                    return
                }
            }
        }
        if (this.view.value == 'selection') {
            if (!this.hasActiveTracking()) {
                this.switchToDashboard()
                return
            }
        }
    }

    /*
     * Panel Grid management
    */

    initPanelGrid() {
        this.panel = reactive([])
        for (let i = 0; i < 15; i++) {
            this.panel.push(reactive(
                {
                    id: i,
                    view: null,
                    subView: null,
                    innerView: null,
                    page: null,
                    lastChange: null,
                    updateTime: null,
                    type: null,
                    text: null,
                    image: null,
                    color: null,
                    bgColor: null,
                    deckColor: null,
                    deckBgColor: null,
                    callback: () => {
                        return null
                    },
                    interval: () => {
                        return null
                    },
                    intervalTime: null,
                    timeout: () => {
                        return null
                    },
                    timeoutTime: null,
                    init: () => {
                        return null
                    }
                }
            )
            )
        }
    }


    /**
     * 
     * @param {number} id 
     * @param {PanelGridItem} data 
     * @returns 
     */
    setId(id, data) {
        if (!this.panel[id]) {
            return
        }
        this.resetId(id, true, 'setId')
        this.updateId(id, data, null, 'setId')
        this.startHandlerFor(id)
    }

    /**
     * 
     * @param {number} id 
     * @param {PanelGridItem} data 
     */
    updateId(id, data, state = null, functionCall) {
        if (!this.panel[id]) {
            return
        }
        if (state && typeof state == 'object') {
            const actual = this.getActualStateAsObject()
            const diff = this.getDiffBetweenStates(actual, state)
            if ('view' in diff || 'page' in diff || 'innerView' in diff || 'subView' in diff) {
                if (this.debug) {
                    useDebuggingStore().addEntry('debug', 'ViewManager', 'Diff between states found -> No update of panel grid item.', id)
                }
                return
            }
        }

        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'Update id' + (functionCall ? ' for function ' + functionCall : ''), id)

        }

        Object.entries(data).forEach((item) => {
            this.panel[id][item[0]] = item[1]
            if (item[0] == 'init') {
                this.panel[id][item[0]](id)
            }
        })
        this.panel[id].view = toValue(this.view)
        this.panel[id].subView = toValue(this.subView)
        this.panel[id].innerView = toValue(this.innerView)
        this.panel[id].page = toValue(this.page)
        this.panel[id].lastChange = toValue(this.lastChange)
        this.panel[id].updateTime = Date.now()

        this.updatePanelViews()
    }

    /**
     * 
     * @param {Array<number>} ids 
     */
    resetIds(ids, noUpdate = false) {
        if (!Array.isArray(ids)) {
            throw new Error('The given parameter ids should be an array.')
        }
        ids.forEach((item) => this.resetId(item, noUpdate))
    }

    resetId(id, noUpdate = false, functionCall = null) {
        if (!this.panel[id]) {
            return
        }
        const nullFor = [
            'view',
            'subView',
            'innerView',
            'page',
            'lastChange',
            'type',
            'text',
            'image',
            'color',
            'bgColor',
            'deckColor',
            'deckBgColor',
            'intervalTime',
            'timeoutTime'
        ]
        let foundValue = false
        for (const val of nullFor) {
            if (this.panel[id][val] != null) {
                foundValue = true
                break
            }
        }
        if (!foundValue) {
            if (this.debug) {
                useDebuggingStore().addEntry('debug', 'ViewManager', 'No values found to reset id' + (functionCall ? ' for function ' + functionCall : ''), id)
            }
            return
        }
        timelinkStoresService.removeTimeout('panel', this.getHandlerName(this.panel[id]))
        timelinkStoresService.removeInterval('panel', this.getHandlerName(this.panel[id]))
        timelinkStoresService.removeWatcher('panel', this.getHandlerName(this.panel[id]))
        if (this.debug) {
            // console.trace(id)
            useDebuggingStore().addEntry('debug', 'ViewManager', 'Reset id' + (functionCall ? ' for function ' + functionCall : ''), id)
        }

        const resetFunctions = [
            'callback',
            'interval',
            'timeout',
            'init'
        ]
        // otherwise the reference could be lost and some artifacts could be left behind.
        for (const key of nullFor) {
            this.panel[id][key] = null
        }
        for (const key of resetFunctions) {
            this.panel[id][key] = () => {
                return null
            }
        }
        this.panel[id].updateTime = Date.now()

        if (!noUpdate) {
            this.updatePanelViews()
        }
    }


    /**
     * 
     * @param {*} id 
     * @param {*} data 
     * @param {{
     * acronym: ?string,
     * color: ?string,
     * image: ?string}
     * } item 
     * @returns 
     */
    setIdForCPS(id, data, item) {
        if (!item) {
            if (this.debug) {
                useDebuggingStore().addEntry('debug', 'ViewManager', 'No item found! - setIdForCPS')
            }
            this.setId(id, data)
            return
        }

        this.setId(id, this.generateDataBasedOnCPS(id, data, item))
    }

    /**
     * 
     * @param {*} id 
     * @param {*} data 
     * @param {{
    * acronym: ?string,
    * color: ?string,
    * image: ?string}
    * } item 
    * @returns 
    */
    updateIdForCPS(id, data, item) {
        if (!item) {
            this.updateId(id, data)
            return
        }

        this.updateId(id, this.generateDataBasedOnCPS(id, data, item))
    }

    /**
     * 
     * @param {*} data 
     * @param {{
    * acronym: ?string,
    * color: ?string,
    * image_id: ?string}
    * } item 
     */
    generateDataBasedOnCPS(id, data, item) {
        if (item.id) {
            data = {
                ...data,
                item_id: item.id,
            }
        }
        data = {
            ...data,
            type: (data.image ?? null) ? 'image' : 'text',
            text: item.name,
        }
        const imagesStore = useImagesStore()
        let shouldLoadImage = (item.image_id && !imagesStore.has(item.image_id))
        if (item.acronym && (!item.image_id || shouldLoadImage)) {
            data = {
                ...data,
                text: item.acronym,
                color: (item.color ? timelinkStoresService.generateForegroundColor(item.color) : null),
                bgColor: item.color ?? null
            }
        }
        if (item.image_id) {
            const previousInit = data.init
            data = {
                ...data,
                type: 'image',
                image: imagesStore.get(item.image_id),
                init: (initItem) => {
                    if (shouldLoadImage) {
                        if (this.debug) {
                            useDebuggingStore().addEntry('debug', 'ViewManager', 'load image', initItem)
                        }
                        this.getImageFor(initItem, item)
                    }
                    if (typeof previousInit == 'function') {
                        // useDebuggingStore().addEntry('debug', 'Call', 'revious item init')
                        previousInit(initItem)
                    }
                }
            }
        }

        return data
    }

    /**
     * 
     * @param {PanelGridItem|number} item 
     * @returns 
     */
    getHandlerName(item) {
        if (typeof item == 'number') {
            item = this.panel[item]
        }
        let handlerName = ''
        if (item.id !== null && item.id !== undefined) {
            handlerName += item.id.toString()
        }
        else {
            useDebuggingStore().addEntry('debug', 'No', 'd found. ViewManager::getHandlerName', item)
        }
        return handlerName
    }

    /**
     * 
     * @param {PanelGridItem|number} item 
     */
    startHandlerFor(item) {
        if (typeof item == 'number') {
            item = this.panel[item]
        }
        if (item?.interval ?? null) {
            const interval = item.interval(item.id)
            const intervalTime = item.intervalTime ?? 1000
            if (interval) {
                timelinkStoresService.setOrRenewInterval(
                    'panel',
                    this.getHandlerName(item),
                    interval,
                    intervalTime
                )
            }
        }
        if (item?.timeout ?? null) {
            const timeout = item.timeout(item.id)
            const timeoutTime = item.timeoutTime ?? 1000
            if (timeout) {
                timelinkStoresService.setOrRenewTimeout(
                    'panel',
                    this.getHandlerName(item),
                    timeout,
                    timeoutTime
                )
            }
        }
    }

    /**
     * Reduces the update frequency a little bit.
     * @returns 
     */
    async updatePanelViews() {
        if (this.updateRequested.value || !this.initializedFirstPanel.value) {
            return
        }
        this.updateRequested.value = true
        await nextTick()
        this.sendDiffToHwPanel()
        this.updateRequested.value = false
    }

    /*
     * HW Panel functiosn
     */
    async initHwPanel() {
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'Init hw panel')
        }
        await nextTick()
        this.sendAllToHwPanel()
    }

    sendToHwPanel() {
        if (!this.initializedFirstPanel.value) {
            return
        }
        // this.sendDiffToHwPanel()
    }

    sendAllToHwPanel() {
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'Send all data to hw panel')
        }

        const panel = this.getPanel()
        let highestUpdateTime = 0

        panel.forEach((item) => {
            if (item.updateTime > highestUpdateTime) {
                highestUpdateTime = item.updateTime
            }
            deckService.setId(
                item.id,
                item.type == 'image' && item.image != null ? item.type : 'text', // type
                item.type == 'image' ? (item.image != null ? item.image : item.text) : item.text, // data
                {
                    color: item.deckColor ?? item.color ?? null,
                    bgColor: item.deckBgColor ?? item.bgColor ?? null
                }
            )
        })
        this.lastSendToDeck = highestUpdateTime

        this.initializedFirstPanel.value = true
    }

    sendDiffToHwPanel() {
        if (this.timing) {
            console.time('diff')
        }
        if (this.debug) {
            console.trace()
            useDebuggingStore().addEntry('debug', 'ViewManager', 'Send only diff', Date.now())
        }
        const panel = this.getPanel()
        let highestUpdateTime = null
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'last time of sending to hw-panel', this.lastSendToDeck)
        }
        panel.forEach((item) => {
            if (this.timing) {
                console.time('id_' + item.id)
            }
            if (item.updateTime == null || item.updateTime <= this.lastSendToDeck) {
                if (this.debug) {
                    useDebuggingStore().addEntry('debug', 'ViewManager', 'skip send item', item.id)
                }
                if (this.timing) {
                    console.timeEnd('id_' + item.id)
                }
                return
            }
            if (item.updateTime > highestUpdateTime) {
                highestUpdateTime = item.updateTime
            }
            if (this.debug) {
                useDebuggingStore().addEntry('debug', 'ViewManager', 'send item', { ...item })
            }
            if (this.timing) {
                console.timeLog('id_' + item.id)
            }
            deckService.setId(
                item.id,
                item.type == 'image' && item.image != null ? item.type : 'text', // type
                item.type == 'image' ? (item.image != null ? item.image : item.text) : item.text, // data
                {
                    color: item.deckColor ?? item.color ?? null,
                    bgColor: item.deckBgColor ?? item.bgColor ?? null
                }
            )
            if (this.timing) {
                console.timeEnd('id_' + item.id)
            }
        })
        if (highestUpdateTime !== null) {
            this.lastSendToDeck = highestUpdateTime
        }
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'send diff completed', this.lastSendToDeck)
        }
        if (this.timing) {
            console.timeEnd('diff')
        }
    }


    /*
     * Helper functions
     */

    getIcon(iconLookup, options) {
        const iconClass = icon(iconLookup, options)
        if (iconClass == undefined || iconClass == null) {
            return null
        }
        const div = document.createElement('div')
        div.innerHTML = iconClass.html.toString()
        let svg = div.firstChild
        const removeAttrs = ['aria-hidden', 'focusable', 'data-prefix', 'data-icon', 'class', 'role']
        removeAttrs.forEach((item) => {
            svg.removeAttribute(item)
        })
        svg.firstChild.removeAttribute('fill', '')
        svg.setAttribute('class', 'object-fit h-full w-full p-3 lg:p-4 xl:p-6')
        return div.innerHTML
    }

    getCancelOrBackButton() {
        if (this.hasActiveWizard() && this.hasRemainingRequiredFields()) {
            return this.getCancelButton()
        }
        else {
            return this.getBackButton()
        }
    }

    getBackButton() {
        timelinkStoresService.removeWatcher('panel', 'cancelOrBackButton')
        timelinkStoresService.removeWatcher('panel', 'cancelOrBackButton_user_settings')
        const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
        const user = useAuthUserStore().user
        return {
            type: 'image',
            text: $t('back'),
            bgColor: null,
            image: this.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-arrow-up-left' })),
            init: (id) => {
                const panelItem = this.getPanel()[id]
                if (activeTimeEntry && this.view.value == 'selection') {
                    timelinkStoresService.setOrRenewWatcher('panel', 'cancelOrBackButton', watch(activeTimeEntry, () => {
                        if (this.hasActiveWizard() && this.hasRemainingRequiredFields()) {
                            this.updateId(id, this.getCancelButton())
                        }
                    }))
                    timelinkStoresService.setOrRenewWatcher('panel', 'cancelOrBackButton_user_settings', watch(() => user?.settings?.panel?.wizard, () => {
                        if (this.hasActiveWizard() && this.hasRemainingRequiredFields()) {
                            this.updateId(id, this.getCancelButton())
                        }
                    }))
                }
            },
            callback: () => {
                return () => {
                    this.switchTo()
                }
            }
        }
    }

    getCancelButton() {
        timelinkStoresService.removeWatcher('panel', 'cancelOrBackButton')
        timelinkStoresService.removeWatcher('panel', 'cancelOrBackButton_user_settings')
        const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
        const user = useAuthUserStore().user
        return {
            type: 'image',
            text: $t('cancel'),
            image: this.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-trash' })),
            color: '#fff',
            bgColor: '#ff0000',
            init: (id) => {
                const panelItem = this.getPanel()[id]
                timelinkStoresService.setOrRenewWatcher('panel', 'cancelOrBackButton', watch(activeTimeEntry, () => {
                    if (this.hasActiveWizard() && this.hasRemainingRequiredFields()) {
                        return
                    }
                    else {
                        this.updateId(id, this.getBackButton())
                    }
                }))
                timelinkStoresService.setOrRenewWatcher('panel', 'cancelOrBackButton_user_settings', watch(() => user?.settings?.panel?.wizard, () => {
                    if (this.hasActiveWizard() && this.hasRemainingRequiredFields()) {
                        return
                    }
                    else {
                        this.updateId(id, this.getBackButton())
                    }
                }))
            },
            callback: () => {
                return () => {
                    if (useTimeEntryStore().getActiveTimeEntry) {
                        useTimeEntryStore().deleteId(useTimeEntryStore().getActiveTimeEntry.id)
                    }
                    // switch to is getting called by the subscription method to the time entry store.
                }
            }
        }
    }

    getPagination(startRow, startColumn, maxRows, maxColumns, counter, page = 0, nullKey = false) {
        let result = {
            startId: startColumn + startRow * maxColumns,
            start: 0,
            end: counter,
            pages: 0,
            previous: false,
            next: false
        }
        let maxPerPage = maxRows * maxColumns - (startColumn + startRow * maxColumns + 2)
        let firstPage = maxPerPage + 1
        if (counter <= firstPage) {
            firstPage = firstPage + 1
        }
        if (nullKey) {
            firstPage = firstPage - 1
        }
        let leftCounter = counter - firstPage
        let pagesFloat = leftCounter / maxPerPage

        let pages = Math.ceil(pagesFloat) + 1

        let lastPageItemsLeft = leftCounter % maxPerPage
        if (lastPageItemsLeft <= 1) {
            pages = pages - 1
        }
        result.pages = pages

        if (page == 0) {
            result.end = firstPage
            result.next = firstPage < counter

            return result
        }
        result.previous = true
        result.next = true
        result.start = firstPage + (page - 1) * maxPerPage
        result.end = result.start + maxPerPage
        if (page == pages - 1) {
            result.end = result.end + 1
        }
        result.next = result.end >= counter ? false : true
        return result
    }
    getPaginationNextButton() {
        return {
            type: 'image',
            text: $t('next_page'),
            color: null,
            deckColor: null,
            image: this.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-arrow-right' })),
            callback: () => {
                return () => {
                    this.switchToNextPage()
                }
            }
        }
    }
    getPaginationPreviousButton() {
        return {
            type: 'image',
            text: $t('previous_page'),
            image: this.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-arrow-left' })),
            color: '#fff',
            callback: () => {
                return () => {
                    this.switchToPreviousPage()
                }
            }
        }
    }

    setPaginationNextButton(id) {
        if (this.getPanel()[id].text == $t('next_page')) {
            return
        }
        this.setId(id, this.getPaginationNextButton())
    }
    setPaginationPreviousButton(id) {
        if (this.getPanel()[id].text == $t('previous_page')) {
            return
        }
        this.setId(id, this.getPaginationPreviousButton())
    }



    async getImageFor(id, item) {
        const actualState = this.getActualStateAsObject()
        const panelItem = this.getPanel()[id]

        const imagesStore = useImagesStore()
        const actualItem = toValue(item)

        const image = await imagesStore.getOrFetch(actualItem.image_id)
        this.updateId(id, {
            image: image
        }, actualState)
    }

    pressButton(index) {
        if (index === null || index === undefined) {
            throw Error('No index given! Please provide a index')
        }
        const item = this.getPanel()[index] ?? undefined
        if (item === undefined) {
            throw Error('No index found! Please provide a valid index')
        }

        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'Button press for id ' + index)
        }

        // TODO: Add AllowBypassingLock
        if (this.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'Lock state while pressing button: ' + this.panelLock.value, this.panelLock.value && (item.allowBypassingLock ?? false))
        }
        // && (item.allowBypassingLock ?? false)
        if (this.panelLock.value || this.buttonLock.value || item.lastChange > (Date.now() - 40)) {
            if (this.debug) {
                useDebuggingStore().addEntry('debug', 'ViewManager', 'A Button was pressed while panel is locked.')
            }
            return
        }
        if (typeof item.callback == 'function') {
            const buttonCallback = item.callback(index)
            if (typeof buttonCallback == 'function') {
                buttonCallback(index)
            }
        }

    }
}

export default new ViewManager()