<template>
    <div class="px-8 py-4">
        <div>
            {{ $t('first_steps.header') }}
        </div>
    </div>

    <div class="grid justify-between w-full grid-cols-2 mt-8 mb-12">
        <div class="flex justify-center w-full">
            <div class="p-12 space-y-6 text-center bg-white border cursor-pointer group rounded-xl border-mossgray-200 hover:shadow hover:bg-ivory"
                @click="gotoIntegrationPage">
                <div>
                    <font-awesome-icon :icon="['fal', 'link']" class="w-16 h-16" />
                </div>
                <div>
                    <BaseButton class="button-lime " @click="gotoIntegrationPage">
                        {{ $t('first_steps.integration_button') }}
                    </BaseButton>
                </div>
            </div>
        </div>
        <div class="flex justify-center w-full ">
            <div class="p-12 space-y-6 text-center border cursor-pointer group rounded-xl border-mossgray-200 hover:shadow hover:bg-ivory"
                @click="gotoClientPage">
                <div>
                    <font-awesome-icon :icon="['fal', 'users']" class="w-16 h-16" />
                </div>
                <div>
                    <BaseButton class="button-lime " @click="gotoClientPage">
                        {{ $t('first_steps.base_data_button') }}
                    </BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import BaseButton from '@/components/general/BaseButton.vue';
import { $t } from '@/config/i18n';
import { useRouter } from 'vue-router';

const router = useRouter()
const emits = defineEmits(['clicked-box'])

function gotoIntegrationPage() {
    router.push({ name: 'Integrations' })
    emits('clicked-box')
}

function gotoClientPage() {
    router.push({ name: 'ClientsList' })
    emits('clicked-box')

}
</script>