import { captureMessage } from '@sentry/vue'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'

export const useIdleStore = defineStore('idle', () => {
  const states = ref([])

  function $reset() {
    states.value = []
  }

  function addState(userState, screenState) {
    if (!userState || !screenState) {
      return
    }
    if (!Array.isArray(states.value)) {
      states.value = []
      captureMessage('States was not an array like it was defined')
    }
    states.value.push({ time: Date.now(), userState: userState, screenState: screenState })
    if (states.value.length >= 400) {
      states.value = states.value.slice(-300, states.value.length)
    }
  }

  return { states, $reset, addState }
}, {
  persist: true,
  storage: localStorage,
  pick: ['states'],
})


if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIdleStore, import.meta.hot))
}
