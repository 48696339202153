import { setSelectableProject } from "@/panel/helper/projects";
import Selection from "./Selection";
import ViewManager from "@/panel/ViewManager";
import { useProjectsStore } from "@/stores/projects";
import { useTimeEntryStore } from "@/stores/timeEntry";
import { useClientsStore } from "@/stores/clients";
import { toValue } from "vue";
import { useCompanyStore } from "@/stores/company";
import timelinkStoresService from "@/services/timelink-stores.service";
import { $t } from "@/config/i18n";

class Projects extends Selection {
    showButtonId = 2
    showButtonTextI18n = "panel.selection.active.project"
    /**
     * 
     * @param {ViewManager} manager 
     */
    header(manager) {
        this.setClient(manager)
        this.setService(manager)
    }

    getLastUsed() {
        const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
        return useProjectsStore()
            .getLastUsed(activeTimeEntry?.client_id)
            .filter((item) => item.active)
    }

    setSelectableItem(manager, id, item) {
        setSelectableProject(manager, id, item)
    }

    /**
     * 
    * @param {ViewManager} manager 
    * @returns {number}
    */
    getAlphabeticCount(manager) {
        const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

        return useClientsStore().getId(activeTimeEntry?.client_id)?.tl?.activeProjects ?? useCompanyStore().company.active_projects_count ?? 0
    }

    /**
     * @param  {ViewManager} manager
     * @returns {Array<any>}
     */
    getAlphabetic(manager) {
        const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

        let projects = null
        if (activeTimeEntry.client_id) {
            projects = useProjectsStore()
                .projects.filter((item) => item.client_id == activeTimeEntry?.client_id && item.active)
        }
        else {
            projects = useProjectsStore().projects
        }

        return projects.toSorted((a, b) => a.name.localeCompare(b.name))
    }

    /**
     * 
     * @param {ViewManager} manager 
     * @param {number} page 
     * @param {any} pagination 
     * @param {any} dims 
     * @param {any} needToLoadMore 
     */
    async fetchAlphabetic(manager, page, pagination, dims, needToLoadMore) {
        const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

        useProjectsStore().fetch(
            {
                active: true,
                client_id: activeTimeEntry?.client_id, // the projectscontroller uses this filter only on non empty values
                limit: dims.columns * dims.rows * 2,
                page: page + 2,
                orders: [
                    {
                        column: 'name',
                        direction: 'asc'
                    }
                ]
            },
            false,
            true,
            (data) => {
                if (toValue(manager.subView) != 'project_id' || !pagination.next || !needToLoadMore) {
                    return
                }
                let client = useClientsStore().getId(activeTimeEntry?.client_id)
                if (client && client.tl) {
                    client.tl.activeProjects = data.meta.total
                }
                manager.setId(dims.rows * dims.columns - 1, manager.getPaginationNextButton(page))
            }
        )
    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    async initFetch(manager) {
        const dims = manager.getDimensions()

        const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
        useProjectsStore().fetch(
            {
                active: true,
                client_id: activeTimeEntry?.client_id,
                limit: dims.columns * dims.rows * 2,
                page: 1,
                orders: [
                    {
                        column: 'name',
                        direction: 'asc'
                    }
                ]
            },
            false,
            true,
            (data) => {
                let client = useClientsStore().getId(activeTimeEntry?.client_id)
                if (client && client.tl) {
                    client.tl.activeProjects = data.meta.total
                }
            }
        )
    }

    canBeNull() {
        return !(useTimeEntryStore().requiredFields ?? []).includes('project_id')
    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    setNullKey(manager) {
        const dims = manager.getDimensions()

        manager.setId(dims.columns, {
            type: 'text',
            text: $t('panel.select_no_project'),
            callback: () => {
                return () => {
                    const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
                    activeTimeEntry.project_id = null
                    timelinkStoresService.updateTl(activeTimeEntry)
                    useTimeEntryStore().delayUpdateId(activeTimeEntry.id, 1000, true)
                    manager.switchToTracking()
                }
            }
        })
    }
}

export default new Projects()