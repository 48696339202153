import { useApiStore } from '@/stores/api'
import { captureException } from '@sentry/vue'
import axios from 'axios'
import { StringifyObjectsHook, createLogger } from 'vue-logger-plugin'

/**
 *
 * @param {import("vue-logger-plugin").LogEvent} event
 */
const sendToServer = {
  run(event) {
    try {
      if (useApiStore().connectionError || useApiStore().needQueue) {
        return
      }
      axios.post(import.meta.env.VITE_API_URL + '/api/v1/frontend/logging', {
        severity: event.level,
        data: [navigator.onLine ? 'online' : 'offline', ...event.argumentArray],
        // eslint-disable-next-line no-undef
        version: APP_VERSION
      })
    } catch (error) {
      captureException(error)
      // console.log(error)
      return
    }
  }
}

const logger = createLogger({
  enabled: true,
  level: 'debug',
  callerInfo: true,
  beforeHooks: [StringifyObjectsHook],
  afterHooks: [sendToServer]
})

export default logger
