<template>
  <BaseModal v-model="showModal" @close-modal="closeModal" :stop-auto-focus="true">
    <template v-slot:header>
      <span v-show="timeEntry && timeEntry.id == 'tempNewEntry'">{{ $t('create_entry') }}</span><span
        v-show="timeEntry && timeEntry.id != 'tempNewEntry' && !isActiveTimeEntry">{{
          $t('edit_entry')
        }}</span>
      <span v-show="timeEntry && timeEntry.id != 'tempNewEntry' && isActiveTimeEntry">
        {{ $t('edit_recording') }}
      </span>
      <span v-if="isDev"> ( {{ timeEntry?.id }} ) </span>
    </template>
    <form @submit.prevent="" ref="timeEntryForm">
      <div class="px-4 py-6 sm:p-6 sm:pt-4">
        <div class="" v-if="timeEntry">
          <div class="grid grid-cols-2 gap-x-8 gap-y-4">
            <div>
              <DateTimeInput v-model="timeEntry.started_at" ref="form_started_at">
                <template #button="vProps">
                  <button :class="vProps.classes" type="button" @click="setToPreviousEntry" :disabled="!previousEntry ||
                    Date.parse(previousEntry.ended_at) == Date.parse(timeEntry.started_at)
                    " v-tippy="{
                      allowHTML: true,
                      content: previousEntry
                        ? '<div class=\'text-center\'>' +
                        $t('timeEntry.follow_up_on_previous') +
                        '<br>' +
                        (new Date(Date.parse(timeEntry.started_at)).toLocaleDateString() !=
                          new Date(Date.parse(previousEntry.ended_at)).toLocaleDateString()
                          ? new Date(Date.parse(previousEntry.ended_at)).toLocaleDateString() +
                          ' - '
                          : '') +
                        new Date(Date.parse(previousEntry.ended_at)).toLocaleTimeString() +
                        '</div>'
                        : ''
                    }">
                    <div class="px-3 pr-4">
                      <font-awesome-icon :icon="['fa-kit', 'tl-arrow-line-up']" size="lg" />
                    </div>
                  </button>
                </template>
              </DateTimeInput>
            </div>

            <div>
              <template v-if="!isActiveTimeEntry">
                <DateTimeInput v-model="timeEntry.ended_at" ref="form_ended_at">
                  <template #label>
                    {{ $t('timeEntries.end') }}
                  </template>
                  <template #button="vProps">
                    <button :class="vProps.classes" type="button" @click="setToFollowingEntry" :disabled="!followingEntry ||
                      Date.parse(followingEntry.started_at) == Date.parse(timeEntry.ended_at)
                      " v-tippy="{
                        content: followingEntry
                          ? '<div class=\'text-center\'>' +
                          $t('timeEntry.follow_up_on_next') +
                          '<br>' +
                          (new Date(Date.parse(timeEntry.ended_at)).toLocaleDateString() !=
                            new Date(Date.parse(followingEntry.started_at)).toLocaleDateString()
                            ? new Date(
                              Date.parse(followingEntry.started_at)
                            ).toLocaleDateString() + ' - '
                            : '') +
                          new Date(Date.parse(followingEntry.started_at)).toLocaleTimeString() +
                          '</div>'
                          : '',
                        allowHTML: true
                      }">
                      <div class="px-3 pr-4">
                        <font-awesome-icon :icon="['fa-kit', 'tl-arrow-line-down']" size="lg" />
                      </div>
                    </button>
                  </template>
                </DateTimeInput>
              </template>
            </div>

            <div>
              <div>
                <label for="duration" class="input-label">{{ $t('duration') }}</label>
              </div>

              <div class="relative inline-flex w-full">
                <template v-if="!isActiveTimeEntry">
                  <input type="text" id="duration" name="duration" :value="duration"
                    class="w-full input disabled:bg-mossgray-100 disabled:text-mossgray-800" />
                </template>
                <template v-else>
                  <div class="w-full leading-4 border-gray-200 input" v-if="stopwatch != null">
                    <span v-if="stopwatch.days > 0"><span
                        v-text="stopwatch.days.toString().padStart(2, '0')"></span>:</span>
                    <span v-text="stopwatch.hours.toString().padStart(2, '0')"></span>:<span
                      v-text="stopwatch.minutes.toString().padStart(2, '0')"></span>:<span
                      v-text="stopwatch.seconds.toString().padStart(2, '0')"></span>
                  </div>
                </template>
                <div class="absolute top-0 flex items-center h-full right-4">
                  <font-awesome-icon :icon="['fa-kit', 'tl-duration']" class="my-auto" />
                </div>
              </div>

              <span v-show="timeEntry && Date.parse(timeEntry?.started_at) >= Date.parse(timeEntry?.ended_at)
                " class="text-sm font-semibold text-red-500">{{ $t('validation.error.end_before_start') }}</span>
            </div>

            <div class="col-start-1">
              <Combobox as="div" v-model="selectedProject" :nullable="!timeEntryStore.requiredFields.includes('project_id') &&
                !(timeEntry.billable ?? false)
                " immediate v-slot="{ open }">
                <ComboboxLabel class="input-label" :class="{
                  'label-required': timeEntryStore.requiredFields.includes('project_id')
                }">{{ $t('project', 1) }}</ComboboxLabel>
                <div class="relative">
                  <ComboboxInput
                    class="w-full pl-4 pr-16 truncate border-0 rounded-full ring-1 ring-inset ring-mossgray-200 focus:ring-2 focus:ring-inset focus:outline-none focus:ring-mossgray sm:text-sm"
                    @change="queryProject = $event.target.value" :displayValue="(project) => project?.name"
                    autocomplete="off" :required="timeEntryStore.requiredFields.includes('project_id')"
                    :placeholder="$t('search_dots')" @blur="resetQueryProject(open)" ref="form_project_id" />
                  <button type="button" @click="timeEntry.project_id = null"
                    class="absolute inset-y-0 flex items-center px-2 right-9 ring-inset focus:outline-none focus:ring-mossgray focus:ring-2"
                    v-show="timeEntry?.project_id">
                    <font-awesome-icon :icon="['fa-kit', 'tl-cross']" />
                  </button>
                  <ComboboxButton class="absolute inset-y-0 flex items-center px-2 right-2 rounded-r-md">
                    <font-awesome-icon :icon="['fa-kit', 'tl-arrow-up-down']" />
                  </ComboboxButton>

                  <ComboboxOptions
                    class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-lg shadow-lg max-h-56 ring-1 ring-mossgray-200 ring-opacity-20 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="item in filteredProjects" :key="item.id" :value="item"
                      :disabled="!item.active" as="template" v-slot="{ active, selected }">
                      <ComboBoxListItem :active="active" :selected="selected" :item="item" />
                    </ComboboxOption>
                    <li class="relative py-2 pl-3 cursor-default select-none pr-9">
                      <div class="flex items-center text-center">
                        <span class="ml-3 truncate"> {{ $t('search_by_entering') }} </span>
                      </div>

                      <!-- TODO: can be removed? -->
                      <span class="absolute inset-y-0 right-0 flex items-center pr-4">
                      </span>
                    </li>
                  </ComboboxOptions>
                </div>
              </Combobox>
            </div>
            <div>
              <Combobox as="div" v-model="selectedClient" :nullable="!timeEntryStore.requiredFields.includes('client_id') &&
                !(timeEntry.billable ?? false)
                " immediate v-slot="{ open }">
                <ComboboxLabel class="input-label" :class="{
                  'label-required':
                    timeEntryStore.requiredFields.includes('client_id') ||
                    (timeEntry.billable ?? false)
                }">{{ $t('client', 1) }}</ComboboxLabel>
                <div class="relative">
                  <ComboboxInput
                    class="w-full pl-4 pr-16 truncate bg-white border-0 rounded-full shadow-sm textmossgray ring-1 ring-inset ring-mossgray-200 focus:ring-2 focus:ring-inset focus:outline-none focus:ring-mossgray sm:text-sm"
                    @change="queryClient = $event.target.value" :display-value="(client) => client?.name"
                    autocomplete="off" :required="timeEntryStore.requiredFields.includes('client_id') ||
                      (timeEntry.billable ?? false)
                      " :placeholder="$t('search_dots')" @blur="resetQueryClient(open)" ref="form_client_id" />
                  <button type="button" @click="timeEntry.client_id = null"
                    class="absolute inset-y-0 flex items-center px-2 right-9 ring-inset focus:outline-none focus:ring-mossgray focus:ring-2"
                    v-show="timeEntry?.client_id">
                    <font-awesome-icon :icon="['fa-kit', 'tl-cross']" />
                  </button>
                  <ComboboxButton
                    class="absolute inset-y-0 flex items-center px-2 right-2 rounded-r-md focus:outline-none">
                    <font-awesome-icon :icon="['fa-kit', 'tl-arrow-up-down']" />
                    <!-- <ChevronUpDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" /> -->
                  </ComboboxButton>

                  <ComboboxOptions
                    class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-lg shadow-lg max-h-56 ring-1 ring-mossgray-200 ring-opacity-20 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="item in filteredClients" :key="item.id" :value="item"
                      :disabled="!item.active" as="template" v-slot="{ active, selected }">
                      <ComboBoxListItem :active="active" :selected="selected" :item="item" />
                    </ComboboxOption>
                    <li class="relative py-2 pl-3 cursor-default select-none pr-9">
                      <div class="flex items-center text-center">
                        <span class="ml-3 truncate"> {{ $t('search_by_entering') }} </span>
                      </div>

                      <!-- TODO: Can be removed? -->
                      <span class="absolute inset-y-0 right-0 flex items-center pr-4">
                        <!-- <CheckIcon class="w-5 h-5" aria-hidden="true" /> -->
                      </span>
                    </li>
                  </ComboboxOptions>
                </div>
              </Combobox>
            </div>

            <div>
              <Combobox as="div" v-model="selectedService" immediate :nullable="!timeEntryStore.requiredFields.includes('service_id') &&
                !(timeEntry.billable ?? false)
                " v-slot="{ open }">
                <ComboboxLabel class="input-label" :class="{
                  'label-required':
                    timeEntryStore.requiredFields.includes('service_id') ||
                    (timeEntry.billable ?? false)
                }">{{ $t('service', 1) }}</ComboboxLabel>
                <div class="relative">
                  <ComboboxInput
                    class="w-full pl-4 pr-16 text-gray-900 truncate bg-white border-0 rounded-full ring-1 ring-inset ring-mossgray-200 focus:ring-2 focus:ring-inset focus:ring-mossgray focus:outline-none sm:text-sm"
                    @change="queryService = $event.target.value" :display-value="(service) => service?.name"
                    autocomplete="off" :required="timeEntryStore.requiredFields.includes('service_id') ||
                      (timeEntry.billable ?? false)
                      " :placeholder="$t('search_dots')" @blur="resetQueryService(open)" ref="form_service_id" />
                  <button type="button" @click="timeEntry.service_id = null"
                    class="absolute inset-y-0 flex items-center px-2 right-9 ring-inset focus:outline-none focus:ring-mossgray focus:ring-2"
                    v-show="timeEntry?.service_id">
                    <font-awesome-icon :icon="['fa-kit', 'tl-cross']" />
                  </button>
                  <ComboboxButton
                    class="absolute inset-y-0 flex items-center px-2 right-2 rounded-r-md focus:outline-none">
                    <font-awesome-icon :icon="['fa-kit', 'tl-arrow-up-down']" />
                    <!-- <ChevronUpDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" /> -->
                  </ComboboxButton>

                  <ComboboxOptions
                    class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-lg shadow-lg max-h-56 ring-1 ring-mossgray-200 ring-opacity-20 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="item in filteredServices" :key="item.id" :value="item"
                      :disabled="!item.active" as="template" v-slot="{ active, selected }">
                      <ComboBoxListItem :active="active" :selected="selected" :item="item" />
                    </ComboboxOption>
                    <li class="relative py-2 pl-3 cursor-default select-none pr-9">
                      <div class="flex items-center text-center">
                        <!-- <img
                                  :src="project.imageUrl"
                                  alt=""
                                  class="flex-shrink-0 w-6 h-6 rounded-full"
                                /> -->
                        <span class="ml-3 truncate"> {{ $t('search_by_entering') }} </span>
                      </div>

                      <span class="absolute inset-y-0 right-0 flex items-center pr-4">
                        <!-- <CheckIcon class="w-5 h-5" aria-hidden="true" /> -->
                      </span>
                    </li>
                  </ComboboxOptions>
                </div>
              </Combobox>
            </div>

            <div>
              <label for="paid" class="input-label" :class="{
                'label-required': timeEntryStore.requiredFields.includes('billable')
              }">{{ $t('billable') }}
              </label>
              <div class="mt-1">
                <BaseSwitch v-model="timeEntry.billable" :class="[
                  timeEntry.billable ? 'bg-lime' : 'bg-mossgray-300',
                  'relative inline-flex h-8 w-16 flex-shrink-0 cursor-pointer rounded-full border-4 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-mossgray focus:ring-offset-2'
                ]">
                  <span class="sr-only">{{ $t('billable') }}</span>
                  <span aria-hidden="true" :class="[
                    timeEntry.billable ? 'translate-x-8' : 'translate-x-0',
                    'pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  ]" />
                </BaseSwitch>
              </div>
            </div>

            <div class="col-span-2">
              <label for="description" class="input-label" :class="{
                'label-required': timeEntryStore.requiredFields.includes('description')
              }">{{ $t('description') }}
              </label>
              <textarea ref="form_description" maxlength="1000" v-model="timeEntry.description"
                :required="timeEntryStore.requiredFields.includes('description')"
                class="block w-full textarea !max-w-full !rounded-lg min-h-32"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div v-if="datetime.diff(timeEntry.started_at, timeEntry.ended_at) > (1000 * 60 * 60 * 6)" class="alert-info">
        Die Dauer des Zeiteintrages übersteigt 6 Stunden.
      </div>
      <div class="" v-if="
        timeEntry && (timeEntry.id ?? null) && !timeEntry.id.startsWith('temp-') && timeEntry.push_state >= 5
      ">
        <div class="alert-danger">
          <strong> {{ $t('errors.sync_provider') }}: </strong>
          <ul class="pl-2 list-disc list-inside">
            <template v-for="(error, index) in timeEntry.push_errors" :key="index">
              <li class="font-semibold text-red-500" v-text="error"></li>
            </template>
          </ul>
        </div>
      </div>
      <div class="alert-danger" v-if="timeEntry?.tl?.error ?? null">
        <span v-text="$t(timeEntry?.tl.error)"> </span>
      </div>

      <div class="px-4 py-2 mt-4 border-l-4 border-lime-600 bg-lime-200 text-mossgray-900"
        v-if="isDev && timeEntry?.last_push">
        {{ $t('timeEntry.last_synchronisation') }}:
        {{ new Date(Date.parse(timeEntry?.last_push)).toLocaleString() }}
      </div>
    </form>

    <template v-slot:footer>
      <div class="flex w-full space-x-4" :class="{
        'justify-end': timeEntry?.id == 'tempNewEntry',
        'justify-between': timeEntry?.id != 'tempNewEntry'
      }">
        <div class="flex justify-start space-x-4" v-if="timeEntry?.id != 'tempNewEntry'">
          <BaseButton type="button" class="button-red" @click="openDeleteModal()">
            <font-awesome-icon :icon="['fa-kit', 'tl-trash']" class="pr-2 text-lg" />
            {{ $t('delete') }}
          </BaseButton>
        </div>

        <div class="flex items-center justify-end space-x-4">
          <BaseButton type="button" class="button-gray" @click="closeModal()">
            {{ $t('cancel') }}
          </BaseButton>
          <BaseButton type="button" @click="updateTimeEntry" class="inline-flex button-mossgray" :disabled="isLoading || timeEntry &&
            Date.parse(timeEntry.started_at) >
            (timeEntry.ended_at ? Date.parse(timeEntry.ended_at) : Date.now())
            ">
            <span v-if="timeEntry && (timeEntry.id ?? null) && timeEntry.id.startsWith('tempNewEntry')">{{
              $t('create')
            }}</span><span v-if="timeEntry && (timeEntry.id ?? null) && !timeEntry.id.startsWith('tempNewEntry')">{{
                $t('save')
              }}</span>
          </BaseButton>
          <BaseButton v-if="isActiveTimeEntry" type="button" @click="updateAndStop"
            class="inline-flex space-x-4 button-mossgray" :disabled="timeEntry &&
              Date.parse(timeEntry.started_at) >
              (timeEntry.ended_at ? Date.parse(timeEntry.ended_at) : Date.now() - 60_000)
              ">
            <span>{{ $t('save_and_stop') }}</span>
          </BaseButton>
        </div>
      </div>
    </template>
    <LoadingSpinner v-model="isLoading" />
  </BaseModal>
  <BaseModal v-model="showDeleteModal" :loading="isLoadingDelete" @close-modal="closeDeleteModal">
    <template #header> {{ $t('confirm_deletion') }} </template>

    <div class="p-6">
      <span>
        {{ $t('confirm_deletion_descriptions.timeEntry') }}
      </span>
    </div>

    <template #footer>
      <div class="flex justify-end w-full gap-4">
        <BaseButton class="button-gray" type="button" @click="closeDeleteModal">
          {{ $t('cancel') }}</BaseButton>
        <BaseButton class="button-red" @click="deleteEntry">{{ $t('delete') }}</BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script setup>
import { useClientsStore } from '@/stores/clients'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import { useTimeEntryStore } from '@/stores/timeEntry'
import {
  Switch as BaseSwitch,
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import BaseButton from '@/components/general/BaseButton.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useAuthUserStore } from '@/stores/auth-user'
import ComboBoxListItem from '@/components/general/ComboBoxListItem.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { useCompanyStore } from '@/stores/company'
import { captureException } from '@sentry/vue'
import datetime from '@/lib/datetime'
import DateTimeInput from '@/components/general/DateTimeInput.vue'
import { useStopwatch, useTimer } from 'vue-timer-hook'
import { $t } from '@/config/i18n'
import { useKeySave } from '@/composables/keySave'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRaw, toValue, unref, useTemplateRef, watch, watchEffect } from 'vue'

const timeEntryStore = useTimeEntryStore()
const saveOnKeyDown = useKeySave()
const projectsStore = useProjectsStore()
const clientsStore = useClientsStore()
const servicesStore = useServicesStore()
const authUserStore = useAuthUserStore()

const props = defineProps(
  {
    isActiveTimeEntry: {
      type: Boolean,
      default: false
    },
    stopActiveEntry: {
      type: Boolean,
      default: false
    },
    focusOn: {
      type: String,
      default: null
    }
  }
)
const showModal = defineModel({
  type: [null, Object, Boolean],
  default: false
})


const createModal = ref(false)
const modalHandler = ref(null)
const timeEntryHandler = ref(null)
const clients = ref(null)
const services = ref(null)
const projects = ref(null)
const queryProject = ref('')
const queryProjectHandler = ref(null)
const queryClient = ref('')
const queryClientHandler = ref(null)
const queryService = ref('')
const queryServiceHandler = ref(null)
const waitingForAction = ref(false)
const keyListener = ref(false)
const isDev = ref(false)
const testOpen = ref(false)
const isLoading = ref(false)
const showDeleteModal = ref(false)
const isLoadingDelete = ref(false)
const platform = ref('')
const stopwatch = ref(null)

const givenEntry = defineModel('item')
const timeEntry = ref(null)

watchEffect(() => {
  copyFromGivenToEntry()
})

const duration = computed(() => {
  if (timeEntry.value && timeEntry.value.started_at && timeEntry.value.ended_at) {
    return datetime.showDiffInColon(timeEntry.value.started_at, timeEntry.value.ended_at)
  }
  return '00:00:00'
})

const selectProjects = computed(() => {
  timeEntry.value.id
  if (timeEntry.value.client_id ?? null) {
    return projects.value
      .filter((item) => item.client_id == timeEntry.value.client_id)
      .filter((item) => item.active)
  }
  return projects.value.filter((item) => item.active)
})

const filteredProjects = computed(() => {

  timeEntry.value.id
  let ps = null
  if (timeEntry.value.client_id ?? null) {
    ps = projects.value
      .filter((item) => item.client_id == timeEntry.value.client_id)
      .filter((item) => item.active)
  } else {
    ps = projects.value.filter((item) => item.active && (item?.tl?.clientActive ?? true))
  }
  if (queryProject.value == null || queryProject.value == undefined || queryProject.value == '') {
    return ps.slice(0, 10)
  }
  return ps
    .filter(
      (item) =>
        item.name.toLowerCase().includes(queryProject.value.toLowerCase()) ||
        (item.info != null &&
          item.info != undefined &&
          item.info.toLowerCase().includes(queryProject.value.toLowerCase())) ||
        (item.ext_tool_id != null &&
          item.ext_tool_id != undefined &&
          item.ext_tool_id.toLowerCase().includes(queryProject.value.toLowerCase()))
    )
    .slice(0, 10)

})

const selectClients = computed(() => {
  timeEntry.value.id

  return clients.value.filter((item) => item.active)
})
const filteredClients = computed(() => {
  timeEntry.value.id

  if (queryClient.value == null || queryClient.value == undefined) {
    return selectClients.value.slice(0, 10)
  }
  return selectClients.value
    .filter(
      (item) =>
        item.name.toLowerCase().includes(queryClient.value.toLowerCase()) ||
        (item.info != null &&
          item.info != undefined &&
          item.info.toLowerCase().includes(queryClient.value.toLowerCase())) ||
        (item.ext_tool_id != null &&
          item.ext_tool_id != undefined &&
          item.ext_tool_id.toLowerCase().includes(queryClient.value.toLowerCase()))
    )
    .slice(0, 10)
})
const selectServices = computed(() => {
  timeEntry.value.id

  return services.value.filter((item) => item.active)
})
const filteredServices = computed(() => {
  timeEntry.value.id

  if (queryService.value == null || queryService.value == undefined || queryService.value == '') {
    return selectServices.value
  }
  return selectServices.value.filter(
    (item) =>
      item.name.toLowerCase().includes(queryService.value.toLowerCase()) ||
      (item.info != null &&
        item.info != undefined &&
        item.info.toLowerCase().includes(queryService.value.toLowerCase())) ||
      (item.ext_tool_id != null &&
        item.ext_tool_id != undefined &&
        item.ext_tool_id.toLowerCase().includes(queryService.value.toLowerCase()))
  )
})

const selectedProject = computed({
  get() {
    return projectsStore.getId(timeEntry.value.project_id) ?? null
  },
  set(newVal) {
    timeEntry.value.project_id = newVal?.id
    resetQueryProject()
    if (timeEntry.value.project_id) {
      timeEntry.value.client_id = newVal.client_id
    }
  }
})
const selectedClient = computed({
  get() {
    return clientsStore.getId(timeEntry.value.client_id) ?? null
  },
  set(newVal) {
    timeEntry.value.client_id = newVal?.id
    resetQueryClient()
    if (timeEntry.value.project_id) {
      const project = projectsStore.getId(timeEntry.value.project_id)
      if (project?.client_id != newVal?.id) {
        timeEntry.value.project_id = null
      }
    }
  }
})
const selectedService = computed({
  get() {
    return servicesStore.getId(timeEntry.value.service_id) ?? null
  },
  set(newVal) {
    timeEntry.value.service_id = newVal?.id
    resetQueryService()
  }
})

const previousEntry = computed(() => {
  if (!timeEntry.value) {
    return null
  }
  let timeEntries = timeEntryStore.timeEntries.filter(
    (item) =>
      item.id != timeEntry.value.id &&
      item.ended_at &&
      Date.parse(item.ended_at) >=
      Date.parse(timeEntry.value.started_at) - 24 * 60 * 60 * 1000 &&
      Date.parse(item.ended_at) <=
      Date.parse(props.isActiveTimeEntry ? timeEntry.value.started_at : timeEntry.value.ended_at)
  )
  let max = null
  let maxId = null
  timeEntries.forEach((item) => {
    if (!item.ended_at) {
      return
    }
    let start = Date.parse(item.ended_at)
    if (start > max) {
      max = start
      maxId = item.id
    }
  })
  return timeEntryStore.getId(maxId)
})
const followingEntry = computed(() => {
  if (!timeEntry.value) {
    return null
  }
  let timeEntries = timeEntryStore.timeEntries.filter(
    (item) =>
      item.id != timeEntry.value.id &&
      item.started_at &&
      Date.parse(item.started_at) <=
      Date.parse(timeEntry.value.ended_at) + 24 * 60 * 60 * 1000 &&
      Date.parse(item.started_at) >= Date.parse(timeEntry.value.started_at)
  )
  let min = null
  let minId = null
  timeEntries.forEach((item) => {
    let end = Date.parse(item.started_at)
    if (min == null) {
      min = end
      minId = item.id
    }
    if (end < min) {
      min = end
      minId = item.id
    }
  })

  if (
    min > Date.now() ||
    (min == null && Date.parse(timeEntry.value.ended_at) >= Date.now() - 24 * 60 * 60 * 1000)
  ) {
    min = Date.now()
    minId = null
    return {
      id: 'now',
      started_at: new Date(Date.now()).toISOString(),
      ended_at: new Date(Date.now()).toISOString()
    }
  }

  return timeEntryStore.getId(minId)
})

const formRefs = {
  timeEntryForm: useTemplateRef('timeEntryForm'),
  form_started_at: useTemplateRef('form_started_at'),
  form_ended_at: useTemplateRef('form_ended_at'),
  form_client_id: useTemplateRef('form_client_id'),
  form_project_id: useTemplateRef('form_project_id'),
  form_service_id: useTemplateRef('form_service_id'),
  form_description: useTemplateRef('form_description')
}

watch(() => showModal.value, async (newVal) => {
  if (newVal) {
    timelinkStoresService.removeTimeout('timeEntries', 'clearSearch')
    timelinkStoresService.removeTimeout('timeEntryModal', 'resetSyncMessage')
  }
  if (newVal && !keyListener.value) {
    window.addEventListener('keyup', onKeyUp)
    keyListener.value = true
  }
  if (!newVal && keyListener.value) {
    window.removeEventListener('keyup', onKeyUp)
    keyListener.value = false
  }
  if (!newVal && timeEntry.value && timeEntry.value.id == 'tempNewEntry') {
    timeEntry.value = timeEntryStore.newEntry()
  }
  if (newVal && !timeEntry.value) {
    timeEntry.value = timeEntryStore.newEntry()
  }
  if (newVal && props.isActiveTimeEntry) {
    if (props.stopActiveEntry) {
      if (props.focusOn) {
        await nextTick()
        formRefs['form_' + props.focusOn]?.value?.focus()
      } else {
        let validate = timeEntryStore.validate(timeEntry.value)
        await nextTick()
        if (validate.length > 0) {
          if (validate.includes('project_id')) {
            formRefs.form_project_id?.value.$el.focus()
          } else if (validate.includes('client_id')) {
            formRefs.form_client_id?.value.$el.focus()
          } else if (validate.includes('service_id')) {
            formRefs.form_service_id?.value.$el.focus()
          } else if (validate.includes('description')) {
            formRefs.form_description?.value.focus()
          } else {
            formRefs.form_started_at?.value?.focusTime()
          }
        } else {
          formRefs.form_started_at?.value?.focusTime()
        }
      }
    } else {
      await nextTick()
      if (props.focusOn) {
        formRefs['form_' + props.focusOn]?.value?.focus()

      } else {
        formRefs.form_started_at?.value?.focusTime()
      }
    }
    stopwatch.value = useStopwatch(
      (Date.now() - Date.parse(timeEntry.value.started_at)) / 1000,
      true
    )
  } else if (newVal && !props.isActiveTimeEntry) {
    await nextTick()
    if (props.focusOn) {
      formRefs['form_' + props.focusOn]?.value?.focus()
    } else {
      formRefs.form_started_at?.value?.focusTime()
    }
  } else {
    stopwatch.value = null
  }
})

watch(() => timeEntry.value?.started_at, (newVal) => {
  if (newVal && props.isActiveTimeEntry) {
    if (Date.parse(newVal) > Date.now()) {
      stopwatch.value = null
      timeEntry.value.started_at = datetime.iso(Date.now())
      return
    }
    try {
      stopwatch.value = useStopwatch(
        (Date.now() - Date.parse(timeEntry.value.started_at)) / 1000,
        true
      )
    } catch (error) {
      //
    }
  }
})

watch(() => queryProject.value, (newVal) => {
  if (newVal != null && newVal != undefined && newVal != '') {
    callProjectSearch()
  }
})
watch(() => queryClient.value, (newVal) => {
  if (newVal != null && newVal != undefined && newVal != '') {
    callClientSearch()
  }
})
watch(() => queryService.value, (newVal) => {
  if (newVal != null && newVal != undefined && newVal != '') {
    callServiceSearch()
  }
})
onMounted(() => {
  isDev.value = import.meta.env.DEV
  platform.value = navigator.platform
  saveOnKeyDown.addCallback(() => {
    saveKeyEvent()
  })
  timeEntryHandler.value = timeEntryStore.$subscribe((mutation, state) => {
    if (waitingForAction.value) {
      if (timeEntry.value) {
        if (state.createdIds.length > 0) {
          if (
            (timeEntry.value.id ?? null) &&
            timeEntry.value?.id?.startsWith('temp-') &&
            state.createdIds.find((item) => item.new == timeEntry.value?.id)
          ) {
            hideModal()
          }
        }
        if (state.changedIds.length > 0) {
          if (state.changedIds.find((item) => item == timeEntry.value.id)) {
            hideModal()
          }
        }
        if (state.deletedIds.length > 0) {
          if (state.deletedIds.find((item) => item == timeEntry.value.id)) {
            timeEntry.value = timeEntryStore.newEntry()
            hideModal()
          }
        }
      }
    }
  })
  clients.value = clientsStore.clients
  services.value = servicesStore.services
  projects.value = projectsStore.projects
  clientsStore.$subscribe(() => {
    clients.value = clientsStore.clients
  })
  servicesStore.$subscribe(() => {
    services.value = servicesStore.services
  })
  projectsStore.$subscribe(() => {
    projects.value = projectsStore.projects
  })
  authUserStore.$subscribe(() => {
    clients.value = clientsStore.clients
    services.value = servicesStore.services
    projects.value = projectsStore.projects
  })
})

onBeforeUnmount(() => {
  if (modalHandler.value) {
    modalHandler()
  }
  if (keyListener.value) {
    window.removeEventListener('keyup', onKeyUp)
    window.removeEventListener('keydown', onKeyDown)
    keyListener.value = false
  }
})

function onKeyUp(event) {
  if (event.which === 27) {
    closeModal()
    return
  }
}

function closeModal() {
  if (showDeleteModal.value) {
    return
  }
  if (timeEntry.value) {
    copyFromGivenToEntry()
  }
  hideModal()
}

function copyFromGivenToEntry() {
  timeEntry.value = { ...unref(givenEntry.value) }
}

function hideModal() {
  showModal.value = false
  waitingForAction.value = false
  queryService.value = ''
  queryProject.value = ''
  queryClient.value = ''
  // timeEntry.value = { ...unref(givenEntry.value) }

  timelinkStoresService.setOrRenewTimeout(
    'timeEntries',
    'clearSearch',
    () => {
      timeEntryStore.clearSearch()
    },
    2000
  )
}

function openDeleteModal() {
  showDeleteModal.value = true
  isLoadingDelete.value = false
}
async function deleteEntry() {
  isLoadingDelete.value = true
  waitingForAction.value = true

  await timeEntryStore.deleteId(timeEntry.value?.id)
  closeDeleteModal()
  showModal.value = false
}
function closeDeleteModal() {
  showDeleteModal.value = false
  isLoadingDelete.value = false
}

const fields = [
  'started_at',
  'ended_at',
  'client_id',
  'project_id',
  'service_id',
  'description',
  'paid',
  'billable'
]

async function updateTimeEntry(shouldHideModal = true) {
  // TODO: Add alerts if possible
  if (!props.isActiveTimeEntry) {
    if (!formRefs.timeEntryForm.value.reportValidity()) {
      return
    }
  }

  waitingForAction.value = true
  isLoading.value = true
  try {
    if (timeEntry.value.id == 'tempNewEntry') {
      timeEntryStore.tempNewEntry = timeEntry.value
    }
    for (let item of fields) {
      givenEntry.value[item] = unref(timeEntry.value[item])
    }
    let newEntry = null
    if (timeEntry.value.id == 'tempNewEntry') {
      newEntry = await timeEntryStore.create(unref({ ...timeEntry.value }))
    }
    else {
      newEntry = await timeEntryStore.createOrUpdateEntry(timeEntry.value.id)
    }
    if (newEntry) {
      timeEntry.value = unref({ ...(newEntry) })
    }
  } catch (error) {
    captureException(error)
    console.log(error)
    // logger.error('There was an error while trying to update or create an Entry', ex)
  }
  isLoading.value = false
  // TODO: Add vue notification
  if (!timeEntry.value?.tl.isDirty && timeEntry.value.id.toString() != 'tempNewEntry') {
    if (shouldHideModal) {
      hideModal()
    }
  }
  else if (timeEntry.value.tl.error_code == 1) {
    if (shouldHideModal) {
      hideModal()
    }
    // console.log('Something happen! Something bad happen! :) ')
  }
}
async function updateAndStop() {
  if (!formRefs.timeEntryForm.value.reportValidity()) {
    return
  }
  waitingForAction.value = true
  isLoading.value = true
  try {
    // await updateTimeEntry(false)
    for (let item of fields) {
      givenEntry.value[item] = unref(timeEntry.value[item])
    }
    let response = await timeEntryStore.stopActiveEntry()
    if (response != null) {
      isLoading.value = false

      hideModal()
    }
  } catch (error) {
    captureException(error)
    console.log(error)
  }
  isLoading.value = false
}
function resetQueryProject(isOpen = false) {
  if (isOpen) {
    return
  }
  timelinkStoresService.setOrRenewTimeout(
    'timeEntryModal',
    'queryProject',
    () => {
      queryProject.value = ''
    },
    120
  )
}
function resetQueryClient(isOpen = false) {
  if (isOpen) {
    return
  }
  timelinkStoresService.setOrRenewTimeout(
    'timeEntryModal',
    'queryClient',
    () => {
      queryClient.value = ''
    },
    120
  )
}
function resetQueryService(isOpen = false) {
  if (isOpen) {
    return
  }
  timelinkStoresService.setOrRenewTimeout(
    'timeEntryModal',
    'queryService',
    () => {
      queryService.value = ''
    },
    120
  )
}
function callProjectSearch() {
  timelinkStoresService.setOrRenewTimeout(
    'timeEntryModal',
    'queryProjectHandler',
    () => {
      projectsStore.search(queryProject.value, timeEntry.value?.client_id ?? null, {
        active: true
      })
    },
    250
  )
}
function callClientSearch() {
  timelinkStoresService.setOrRenewTimeout(
    'timeEntryModal',
    'queryClientHandler',
    () => {
      clientsStore.search(queryClient.value, {
        active: true
      })
    },
    250
  )
}
function callServiceSearch() {
  timelinkStoresService.setOrRenewTimeout(
    'timeEntryModal',
    'queryServiceHandler',
    () => {
      servicesStore.search(queryService.value, {
        active: true
      })
    },
    250
  )
}
function setToPreviousEntry() {
  timeEntry.value.started_at = new Date(Date.parse(previousEntry.value.ended_at)).toISOString()
}
function setToFollowingEntry() {
  if (followingEntry.value.id == 'now') {
    timeEntry.value.ended_at = datetime.removeMillisecondsFromISO(new Date(Date.now()))
    return
  }
  timeEntry.value.ended_at = new Date(Date.parse(followingEntry.value.started_at)).toISOString()
}

function saveKeyEvent(event) {
  // key logic is in useKeySave
  if (!showModal.value) {
    return
  }
  if (timeEntry.value) {
    updateTimeEntry()
  } else {
    return false
  }
}
</script>
