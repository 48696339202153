<template>
  <div class="flex flex-col items-center justify-center flex-grow w-full min-h-screen">
    <LoadingSpinner v-model="showSpinner" :fullPage="true"></LoadingSpinner>
    <div class="w-full max-w-xl space-y-8 bg-white border rounded-2xl border-mossgray-400">
      <div class="w-full p-12 pt-0 space-y-8">
        <div>
          <div class="flex justify-center mt-6 -mb-2">
            <img :src="img" />
          </div>
          <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
            {{ $t('login.sso.header') }}
          </h2>
        </div>
        <form class="space-y-4" action="#" method="POST">
          <input type="hidden" name="remember" value="true" />
          <div class="-space-y-px rounded-md shadow-sm">
            <div>
              <label for="email-address" class="sr-only">{{ $t('login.sso.company_domain') }}</label>
              <input v-model="domain_input" id="company-domain" name="domain" type="text" autocomplete="company-domain"
                required
                class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border rounded-md appearance-none border-mossgray-200 focus:outline-none focus:ring-mossgray focus:border-mossgray focus:z-10 sm:text-sm"
                :placeholder="$t('login.sso.domain_placeholder')" />
            </div>
          </div>
          <div class="text-sm text-red-500" v-text="messageSso"></div>

          <div>
            <button type="submit" @click.prevent="handleSso"
              class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md group bg-mossgray hover:bg-white hover:text-mossgray ring-2 ring-mossgray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mossgray">
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg class="w-5 h-5 text-white group-hover:text-mossgray" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd" />
                </svg>
              </span>
              {{ $t('next') }}
            </button>
          </div>
        </form>

        <router-link to="/login"
          class="flex flex-col mb-2 divide-y rounded-md divide-mossgray-200 ring-1 ring-inset ring-mossgray-200 hover:ring-mossgray">
          <span class="p-3 text-sm font-semibold">{{ $t('login.sso.back_to_login') }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import DesktopService from '@/services/desktop.service'
import { useAuthUserStore } from '@/stores/auth-user'
import axios from 'axios'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import img from '@/assets/timelink_primary_logo_5f_moosgrauRGB.svg'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { $t } from '@/config/i18n'
const desktop = DesktopService
const authUserStore = useAuthUserStore()
const api_domain = import.meta.env.VITE_API_URL

const route = useRoute()
const router = useRouter()

const message = ref('')
const messageSso = ref('')
const domain = ref(null)
const redirect_url = ref('')
const showSpinner = ref(false)
const loggedIn = computed(() => {
  return authUserStore.isAuthenticated
})
const domain_input = computed({
  get() {
    if (typeof domain.value == 'string' && domain.value.includes('@')) {
      return domain.value.split('@')[1]
    }
    return domain.value
  },
  set(value) {
    if (typeof value == 'string' && value.includes('@')) {
      domain.value = value.split('@')[1]
    } else {
      domain.value = value
    }
  }
})

function created() {
  if (authUserStore.salt == null) {
    authUserStore.salt = Math.random().toString(20).substring(2, 6)
  }
  if (loggedIn.value) {
    router.push('/profile')
  }
}

created()

onMounted(() => {
  if (desktop.getSsoDomain()) {
    domain.value = desktop.getSsoDomain()
  }
  if (authUserStore.salt == null) {
    authUserStore.salt = Math.random().toString(20).substring(2, 6)
  }
  showSpinner.value = false
})
function handleSso() {
  messageSso.value = ''
  showSpinner.value = true
  axios
    .post(import.meta.env.VITE_API_URL + '/api/v1/sso', {
      domain: domain.value
    })
    .then((response) => {
      redirect_url.value = window.location.toString()
      redirect_url.value = redirect_url.value.substring(0, redirect_url.value.indexOf('/sso'))
      redirect_url.value = redirect_url.value + '/login'
      let redirect_to = response.data.url
      redirect_to =
        redirect_to +
        '/' +
        authUserStore.salt +
        '?redirect=' +
        encodeURIComponent(redirect_url.value)

      window.location.href = redirect_to
    })
    .catch((error) => {
      showSpinner.value = false
      if (error.response.status == 404) {
        messageSso.value = $t('login.sso.errors.unknown')
      }
    })
}
</script>
