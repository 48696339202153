<template>
  <TemplateComponent>
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>

    <div class="flex items-center w-full py-3 mb-3">
      <h1 class="mr-auto text-2xl font-bold">
        {{ $t('subscription') }}
      </h1>
    </div>

    <!-- TRIAL ACTIVE ALERT -->
    <div class="mb-4">
      <AlertComponent v-if="company?.subscription?.trial_ends_at &&
        Date.parse(company?.subscription?.trial_ends_at) > Date.now()">
        <template #title>{{ $t('settings.subscriptions.test_phase_in_progress') }}</template>

        {{ $t('settings.subscriptions.test_phase_info_part_1') }}
        <b>{{ new Date(Date.parse(company?.subscription?.trial_ends_at)).toLocaleDateString() }}</b>
        {{ $t('settings.subscriptions.test_phase_info_part_2') }}
      </AlertComponent>

      <!-- TRIAL ENDED ALERT -->
      <AlertComponent v-else-if="company?.subscription?.product == 'trial' && !company?.subscription?.trial">
        <template #title>{{ $t('settings.subscription.test_phase_expired') }}</template>
        {{ $t('settings.subscription.subscribe_now') }}
      </AlertComponent>
    </div>



    <div class="pb-16 space-y-8 divide-y divide-mossgray-200">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-6">
        <!-- BILLING ADDRESS START -->
        <form class="bg-white shadow-sm ring-1 ring-mossgray-200 sm:rounded-xl md:col-span-4" ref="form"
          @submit.prevent="updateCompany">
          <div class="px-4 py-6 sm:p-8 sm:pt-6">
            <h2 class="mb-4 text-lg font-semibold leading-7">
              {{ $t('invoice_address') }}
            </h2>
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label for="company_name" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{
                  $t('company') }}</label>
                <div class="mt-2">
                  <input type="text" name="company_name" id="company_name" autocomplete="company" v-model="company.name"
                    class="w-full input" required />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="company_email" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{
                  $t('administrative_email') }}</label>
                <div class="mt-2">
                  <input type="email" name="company_email" id="company_email" autocomplete="company_email"
                    v-model="company.email" required class="w-full input" />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="company_invoice_email"
                  class="block text-sm font-medium leading-6 text-gray-900 label-required">{{ $t('invoice_email')
                  }}</label>
                <div class="mt-2">
                  <input type="email" name="company_invoice_email" id="company_invoice_email"
                    autocomplete="company_invoice_email" v-model="company.invoice_email" required
                    class="w-full input" />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label for="company_address" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{
                  $t('address') }}</label>
                <div class="mt-2">
                  <input type="text" name="company_address" id="company_address" autocomplete="company_address"
                    v-model="company.address" required class="w-full input" />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label for="company_zip" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{
                  $t('zip') }}</label>
                <div class="mt-2">
                  <input type="text" name="company_zip" id="company_zip" autocomplete="company_zip"
                    v-model="company.zip" required class="w-full input" />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="company_city" class="block text-sm font-medium leading-6 text-gray-900 label-required">{{
                  $t('city') }}</label>
                <div class="mt-2">
                  <input type="text" name="company_city" id="company_city" autocomplete="company_city"
                    v-model="company.city" required class="w-full input" />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label for="company_phone" class="block text-sm font-medium leading-6 text-gray-900">{{ $t('phone')
                  }}</label>
                <div class="mt-2">
                  <input type="text" name="company_phone" id="company_phone" autocomplete="company_phone"
                    v-model="company.phone" class="w-full input" />
                </div>
              </div>
            </div>
          </div>

          <div class="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8">
            <!-- <BaseButton @click="openPortal" class="button-mossgray">
              Zahlungs-/Rechnungsportal öffnen
            </BaseButton> -->
            <!-- <button type="button" class="button-gray">Zurücksetzen</button> -->
            <button type="button" @click="updateCompany" class="button-mossgray">
              {{ $t('save') }}
            </button>
          </div>
        </form>
        <!-- BILLING ADDRESS END -->

        <!-- SUBSCRIPTION START -->
        <div class="flex items-center justify-center md:col-span-2">
          <div
            class="flex flex-col w-full h-full bg-white shadow-sm ring-1 ring-mossgray-200 sm:rounded-xl place-content-between">
            <div class="p-4 text-4xl font-bold text-center">
              <span> Basic Abo</span>
              <div v-if="company?.subscription?.ends_at" class="p-4 text-sm text-center text-gray-900">
                <span class="font-normal"> {{ $t('settings.subscription.ends_at') }} </span>
                <span class="ml-1 font-bold" v-text="new Date(Date.parse(company?.subscription?.ends_at)).toLocaleDateString(authUserStore.user.language ?? 'de', {
                  dateStyle: 'short'
                })"></span>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="flex justify-center p-1 space-x-4 text-sm text-gray-600">
                <div class="text-4xl">7,90€</div>
                <div>
                  <div>{{ $t('settings.subscription.per') }}</div>
                  <div>{{ $t('settings.subscription.user') }}</div>
                </div>
              </div>
              <div class="text-xs font-semibold text-center text-gray-500">
                <!-- {{ $t('settings.subscription.presentation_without_discount') }} -->
                {{ $t('settings.subscription.monthly') }}

              </div>
            </div>
            <div class="flex flex-col p-4 space-y-2">
              <div class="flex items-baseline justify-center space-x-2">
                <input type="number" v-model="planed_quantity" class="w-24 input" id="quantity"
                  :disabled="company.autoupdate_quantity" /><span class="text-sm font-semibold text-gray-600">{{
                    $t('settings.subscription.user')
                  }}</span>
              </div>
              <div class="text-center break-words"
                v-if="planed_quantity != quantity && !company?.subscription?.trial_ends_at">
                <span v-text="quantity" class="pr-2 text-sm font-bold text-gray-600"></span><span
                  class="text-sm font-semibold text-gray-600">{{
                    $t('settings.subscription.user_until_end_of_month')
                  }}</span>
              </div>

              <div class="text-xs font-bold text-center text-apricot-600" v-if="company.autoupdate_quantity">
                {{ $t('settings.subscription.auto_update_licenses') }}
              </div>


            </div>
            <!-- <div class="flex justify-center" v-if="company.stripe_exists">
              <BaseButton @click="openPortal" class="button-lime">Zum Stripe-Kundenportal</BaseButton>
            </div> -->
            <div v-if="
              (company?.subscription?.trial && company?.subscription?.product == 'trial') ||
              company?.subscription?.status == 'canceled'
            " class="flex justify-end p-4 border-t border-gray-200">
              <BaseButton class="justify-center button-mossgray" @click="openSubscribeConfirmModal">{{
                $t('settings.subscription.subscribe')
                }}</BaseButton>
            </div>
            <div v-if="company?.subscription?.product != 'trial'"
              class="flex flex-row justify-around gap-4 p-4 border-t border-gray-200">
              <BaseButton class="justify-center button-mossgray" :disabled="disabledChangeButton"
                @click="showUpdateConfirmationModal = true"
                v-if="!company?.subscription?.ends_at && !company?.autoupdate_quantity"><span>{{ $t('change') }}</span>
              </BaseButton>
              <BaseButton v-else-if="company?.subscription?.ends_at" class="text-center button-mossgray"
                @click="showResumeSubscriptionModal = true">
                <span v-if="disabledChangeButton"> {{ $t('settings.subscription.resume') }}</span>
                <span v-else>{{ $t('settings.subscription.resume') }} + {{ $t('change') }}</span>
              </BaseButton>

              <BaseButton class="justify-center button-raspberry" @click="showCancelConfirmationModal = true"
                v-if="!company?.subscription?.ends_at">{{ $t('settings.subscription.cancel') }}</BaseButton>
            </div>
          </div>
        </div>




      </div>
    </div>
    <!-- START Delete Button -->
    <div class="flex justify-end w-full pr-2 -mt-4">
      <div class="flex">
        <BaseButton @click="showDeleteCompany = true" class="button-red">
          <span>{{ $t('settings.subscription.deleteCompany.button') }}</span>
        </BaseButton>
      </div>
    </div>
    <!-- <div
      class="flex flex-col h-full bg-white shadow-sm w-96 ring-1 ring-mossgray-200 sm:rounded-xl place-content-between">
      <div class="flex items-center justify-between px-4 py-6 sm:p-8 sm:pt-6">
        <h2 class="mb-4 text-lg font-semibold">
          {{ $t('settings.subscription.account_actions.header') }}
        </h2>
        <div class="">
          
        </div>

      </div>
    </div> -->
    <!-- END Delete Button -->
    <!-- SUBSCRIPTION END -->

    <BaseModal v-model="showCheckoutConfirmationModal" @close-modal="showCheckoutConfirmationModal = false">
      <template #header> {{ $t('confirmations.subscription.subscribe') }} </template>

      <div class="flex flex-col gap-6 p-6">
        <div
          v-if="companyStore.company?.subscription?.trial_ends_at && datetime.parse(companyStore.company?.subscription.trial_ends_at) > Date.now()">
          <span>
            {{ $t('confirmations.subscription.subscribe_description') }}
          </span>
        </div>

        <div class="text-apricot-800" v-if="planed_quantity < companyStore.company?.active_users_count">
          <i18n-t keypath="confirmations.subscription.subscribe_not_enough_licences" tag="p">
            <template v-slot:attention>
              <b class="font-bold">{{ $t('attention') }}</b>
            </template>
          </i18n-t>
        </div>
      </div>


      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="showCheckoutConfirmationModal = false"
            ref="cancelCheckoutConfirmationModalButton">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-mossgray" @click="subscribe">{{ $t('confirm') }}</BaseButton>
        </div>
      </template>
    </BaseModal>
    <BaseModal v-model="showUpdateConfirmationModal" @close-modal="showUpdateConfirmationModal = false">
      <template #header> {{ $t('confirmations.subscription.change') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirmations.subscription.change_description') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" ref="cancelUpdateConfirmationModalButton"
            @click="showUpdateConfirmationModal = false">
            {{ $t('cancel') }}
          </BaseButton>

          <BaseButton class="button-mossgray" @click="changeQuantity">
            {{ $t('confirm') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <BaseModal v-model="showCancelConfirmationModal" @close-modal="showCancelConfirmationModal = false">
      <template #header> {{ $t('confirmations.subscription.cancel') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirmations.subscription.cancel_description') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="showCancelConfirmationModal = false"
            ref="cancelCancelConfirmationModalButton">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-mossgray" @click="cancelSubscription">
            {{ $t('confirm') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <BaseModal v-model="showCancelSuccessModal" @close-modal="showCancelSuccessModal = false">
      <template #header> {{ $t('confirmations.subscription.cancel_success') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirmations.subscription.cancel_success_description') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-mossgray" @click="showCancelSuccessModal = false"
            ref="nextCancelSuccessModalButton">
            {{ $t('next') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <BaseModal v-model="showResumeSubscriptionModal" @close-modal="showResumeSubscriptionModal = false">
      <template #header> {{ $t('confirmations.subscription.renew') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirmations.subscription.renew_description') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="showResumeSubscriptionModal = false"
            ref="cancelResumeSubscriptionModalButton">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-mossgray" @click="resumeSubscription">
            {{ $t('confirm') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <BaseModal v-model="showSubscriptionSuccessModal" @close-modal="showSubscriptionSuccessModal = false">
      <template #header> {{ $t('confirmations.subscription.success') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirmations.subscription.success_description') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-mossgray" @click="showSubscriptionSuccessModal = false"
            ref="nextSubscriptionSuccessModalButton">
            {{ $t('next') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <BaseModal v-model="showDeleteCompany" @close-modal="showDeleteCompany = false">
      <template #header>
        {{ $t('confirmations.delete_company.header') }}
      </template>

      <div class="p-6">
        <div>
          <i18n-t keypath="confirmations.delete_company.text" tag="span" for="delete">
            <template v-slot:delete>
              <b>{{ $t('delete').toLowerCase() }}</b>
            </template>
          </i18n-t>
        </div>
        <div class="max-w-48">
          <BaseInput v-model="deleteConfirmationInput" />
        </div>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-red" type="button" @click="deleteCompany"
            :disabled="deleteConfirmationInput.toLowerCase() != $t('delete').toLowerCase()">
            {{ $t('delete') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <LoadingSpinner v-model="isLoading" :fullpage="true" :overAll="true" class="fixed top-0 left-0" />

  </TemplateComponent>
</template>

<script setup>
import { useCompanyStore } from '@/stores/company'
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import axios from 'axios'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useAlertsStore } from '@/stores/alerts'
import TemplateComponent from '@/components/settings/TemplateComponent.vue'
import AlertComponent from '@/components/general/AlertComponent.vue'
import apiService from '@/services/api.service'
import { captureException } from '@sentry/vue'
import { useAuthUserStore } from '@/stores/auth-user'
import datetime from '@/lib/datetime'
import {
  computed,
  nextTick,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  ref,
  useTemplateRef,
  watch
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { $t } from '@/config/i18n'
import BaseInput from '../general/BaseInput.vue'
const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const route = useRoute()
const router = useRouter()
const alertsStore = useAlertsStore()

const company = ref({},)
const quantity = ref(1)
const planed_quantity = ref(1)
const form = useTemplateRef('form')

const showUpdateConfirmationModal = ref(false)
const showCancelConfirmationModal = ref(false)
const showCancelSuccessModal = ref(false)
const showCheckoutConfirmationModal = ref(false)
const showResumeSubscriptionModal = ref(false)
const showSubscriptionSuccessModal = ref(false)
const isLoading = ref(false)



const cancelCheckoutConfirmationModalButton = useTemplateRef('cancelCheckoutConfirmationModalButton')
const cancelUpdateConfirmationModalButton = useTemplateRef('cancelUpdateConfirmationModalButton')
const cancelCancelConfirmationModalButton = useTemplateRef('cancelCancelConfirmationModalButton')
const nextCancelSuccessModalButton = useTemplateRef('nextCancelSuccessModalButton')
const cancelResumeSubscriptionModalButton = useTemplateRef('cancelResumeSubscriptionModalButton')
const nextSubscriptionSuccessModalButton = useTemplateRef('nextSubscriptionSuccessModalButton')

watch(() => showCheckoutConfirmationModal.value, async (newVal) => {
  if (newVal) {
    await nextTick()
    cancelCheckoutConfirmationModalButton.value?.focus()

  }
})

watch(() => showUpdateConfirmationModal.value, async (newVal) => {
  if (newVal) {
    await nextTick()
    cancelUpdateConfirmationModalButton.value?.focus()

  }
})
watch(() => showCancelConfirmationModal.value, async (newVal) => {
  if (newVal) {
    await nextTick()
    cancelCancelConfirmationModalButton.value?.focus()

  }
})
watch(() => showCancelSuccessModal.value, async (newVal) => {
  if (newVal) {
    await nextTick()
    nextCancelSuccessModalButton.value?.focus()

  }
})
watch(() => showResumeSubscriptionModal.value, async (newVal) => {
  if (newVal) {
    await nextTick()
    cancelResumeSubscriptionModalButton.value?.focus()

  }
})
watch(() => showSubscriptionSuccessModal.value, async (newVal) => {
  if (newVal) {
    await nextTick()
    nextSubscriptionSuccessModalButton.value?.focus()

  }
})

onMounted(() => {
  companyStore.fetch(() => {
    company.value = { ...companyStore.company }
    quantity.value = company.value?.subscription?.quantity ?? company.value?.active_users_count ?? 1
    planed_quantity.value = company.value?.licenses
  })
  company.value = { ...companyStore.company }
  window.echo
    .private('company.' + company.value.id)
    .listen('CompanyUpdated', (payload) => {
      let updateQuantity = false
      if (quantity.value == company.value?.subscription?.quantity) {
        quantity.value = payload.company?.subscription?.quantity
        planed_quantity.value = payload.company?.licenses

        updateQuantity = true
      }
      company.value = { ...payload.company }
      if (updateQuantity) {
        quantity.value =
          company.value?.subscription?.quantity ?? company.value?.active_users_count ?? 1
        planed_quantity.value = company.value?.licenses ?? 1
      }
    })
    .listen('.company.updated', (payload) => {
      let updateQuantity = false
      if (quantity.value == company.value?.subscription?.quantity) {
        quantity.value = payload.company?.subscription?.quantity ?? payload.company?.active_users_count ?? 1
        planed_quantity.value = payload.company?.licenses

        updateQuantity = true
      }
      company.value = { ...payload.company }
      if (updateQuantity) {
        quantity.value =
          company.value?.subscription?.quantity ?? company.value?.active_users_count ?? 1
        planed_quantity.value = company.value?.licenses ?? 1
      }
    })
  quantity.value = company.value?.subscription?.quantity ?? company.value?.active_users_count ?? 1
  planed_quantity.value = company.value?.licenses ?? 1

})

const disabledChangeButton = computed(() => {
  return planed_quantity.value == company.value?.licenses
})



async function updateCompany() {
  if (!form.value.reportValidity()) {
    return
  }
  isLoading.value = true
  await companyStore.updateData({
    name: company.value.name,
    address: company.value.address,
    email: company.value.email,
    invoice_email: company.value.invoice_email,
    city: company.value.city,
    zip: company.value.zip,
    phone: company.value.phone
  })
  alertsStore.successfullySaved()
  isLoading.value = false
}
async function openSubscribeConfirmModal() {
  if (!form.value.reportValidity()) {
    return
  }
  if (datetime.parse(companyStore.company?.subscription?.trial_ends_at ?? 0) < Date.now() && companyStore.company.active_users_count <= planed_quantity.value) {
    subscribe()
    return
  }
  showCheckoutConfirmationModal.value = true
}

async function subscribe() {
  showCheckoutConfirmationModal.value = false
  if (!form.value.reportValidity()) {
    return
  }
  isLoading.value = true
  if (
    companyStore.company.tl.origin.address == null ||
    companyStore.company.tl.origin.city == null ||
    companyStore.company.tl.origin.name == null ||
    companyStore.company.tl.origin.zip == null
  ) {
    await updateCompany()
    isLoading.value = true
  }
  let redirect_url = null
  redirect_url = window.location.toString()
  redirect_url = redirect_url.substring(
    0,
    redirect_url.indexOf(route.path) + route.path.length
  )
  // return
  axios
    .post(import.meta.env.VITE_API_URL + '/api/v1/subscription/subscribe', {
      quantity: planed_quantity.value,
      success: redirect_url + '?success',
      canceled: redirect_url + '?canceled'
    })
    .then((resp) => {
      let url = resp.data.url
      isLoading.value = false
      window.location.assign(url)
    })
    .catch(() => {
      alertsStore.error($t('errors.ups'), 60)
      isLoading.value = false
    })
}
function changeQuantity() {
  //TODO: Add modal with ask for submit

  isLoading.value = true
  showUpdateConfirmationModal.value = false
  axios
    .patch(import.meta.env.VITE_API_URL + '/api/v1/subscription', {
      quantity: planed_quantity.value
    })
    .then((response) => {
      // console.log(response)
      showUpdateConfirmationModal.value = false
      isLoading.value = false
      if (response?.data?.success) {
        alertsStore.successfullySaved()
      } else {
        if (response?.data?.errorCode) {
          alertsStore.error(
            $t(
              'errors.subscription.changeQuantity.' + response.data.errorCode,
              (response.data.errorCode == 'tooManyActiveUsers') ? { count: response?.data?.count } : null)
            , 30)
        }
        else {
          alertsStore.error($t('errors.ups'))
          alertsStore.error(response?.data?.message, 300)
        }
      }
    })
    .catch((error) => {
      console.log(error)
      alertsStore.error($t('errors.ups'))
      isLoading.value = false
    })
}
function cancelSubscription() {
  isLoading.value = true
  showCancelConfirmationModal.value = false
  axios
    .post(import.meta.env.VITE_API_URL + '/api/v1/subscription/cancel', {})
    .then(() => {
      showCancelConfirmationModal.value = false
      showCancelSuccessModal.value = true
      isLoading.value = false
    })
    .catch(() => {
      alertsStore.error($t('errors.ups'))
      isLoading.value = false
    })
}
function resumeSubscription() {
  isLoading.value = true
  showResumeSubscriptionModal.value = false
  axios
    .post(import.meta.env.VITE_API_URL + '/api/v1/subscription/resume', {})
    .then(() => {
      showResumeSubscriptionModal.value = false
      showSubscriptionSuccessModal.value = true
      isLoading.value = false
    })
    .catch(() => {
      alertsStore.error($t('errors.ups'))
      isLoading.value = false
    })
}
async function openPortal() {
  isLoading.value = true

  try {
    let redirect_url = null
    redirect_url = window.location.toString()
    redirect_url = redirect_url.substring(
      0,
      redirect_url.indexOf(route.path) + route.path.length
    )
    let response = await axios.post(import.meta.env.VITE_API_URL + '/api/v1/subscription/portal', { return_url: redirect_url })
    window.location.assign(response.data.url);
  }
  catch (error) {
    if (apiService.checkErrorAndNotify(error)) {
      //
    }
    else {
      captureException(error)
      console.log(error)
    }
  }
}

const showDeleteCompany = ref(false)
const deleteConfirmationInput = ref('')
watch(() => showDeleteCompany.value, (newVal) => {
  if (!newVal) {
    deleteConfirmationInput.value = ''
  }
})

async function deleteCompany() {
  if (authUserStore.user.admin < 9) {
    alertsStore.error($t('errors.no_permissions'))
    return
  }

  if (companyStore.company.subscription.status == 'active' && !companyStore.company.subscription.trial) {
    console.log(companyStore.company.subscription)
    alertsStore.error($t('delete_company.error.1'))

    return
  }

  isLoading.value = true
  try {
    const response = await axios.delete(import.meta.env.VITE_API_URL + '/api/v1/company')
    console.log(response)
    if (response.data.success) {
      alertsStore.success($t('delete_company.success'), 120)
      // TODO: Add logout and many more.
      try {
        setTimeout(() => {
          authUserStore.logout()
        }, 10_000)
      } catch (err) {
        console.log(err)
      }
    }
    else {
      alertsStore.error(response.data.error_code ? $t('delete_company.error.' + response.data.error_code) : $t('errors.ups'))
    }
  }
  catch (err) {
    if (apiService.checkErrorAndNotify(err)) {
      //
    }
    else {
      alertsStore.error($t('errors.ups'))
      console.error(err)
      captureException(err)
    }
  }

  showDeleteCompany.value = false
  isLoading.value = false
}
</script>
