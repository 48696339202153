<template>
  <!-- TODO: Use it for worker and service worker -->
  <div v-if="updateAvailable && appStore.showUpdateMessage" v>
    <div class="fixed left-0 right-0 flex justify-center max-w-xl mx-auto bottom-12 min-w-96">
      <div class="flex p-2 px-8 space-x-4 text-white rounded-full shadow-sm bg-mossgray">
        <span>
          {{ $t('update_available') }}
        </span>
        <button type="button" class="text-apricot hover:underline" @click="updateFrontend">
          {{ $t('update_restart') }}
        </button>
      </div>
    </div>
  </div>

  <FirstSteps v-model="showFirstDataSetup"></FirstSteps>
  <InvitationComplete v-model="showInvitationModal"></InvitationComplete>
</template>

<script setup>
import { useAuthUserStore } from '@/stores/auth-user'
import { useClientsStore } from '@/stores/clients'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import { useApiStore } from '@/stores/api'
import desktopService from '@/services/desktop.service'
import deckService from '@/services/deck.service'
import panelService from '@/services/panel.service'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useOverlayWindowStore } from '@/stores/overlay-window'
import featureFlagsService from '@/services/feature-flags.service'
import { useCompanyStore } from '@/stores/company'
import * as Sentry from '@sentry/vue'
import { useAppStore } from '@/stores/app'
import { useTimer } from 'vue-timer-hook'
import idleService from '@/services/idle.service'
import { useIdleStore } from '@/stores/idle'
import { useNotificationsStore } from '@/stores/notifications'
import FirstSteps from './modals/FirstSteps.vue'
import InvitationComplete from './modals/InvitationComplete.vue'
import ViewManager from '@/panel/ViewManager'
import { $t, i18n } from '@/config/i18n'

const idle = idleService
window.idleManager = idle
const authUserStore = useAuthUserStore()
const companyStore = useCompanyStore()
const overlayWindowStore = useOverlayWindowStore()
const appStore = useAppStore()
const timeEntryStore = useTimeEntryStore()
const clientsStore = useClientsStore()
const projectsStore = useProjectsStore()
const servicesStore = useServicesStore()
const notificationsStore = useNotificationsStore()
const apiStore = useApiStore()
const idleStore = useIdleStore()
const feature = featureFlagsService

const activeTimeEntryId = ref(null)
const hasActiveTimeEntry = ref(null)
const overlayWindowEvent = ref(null)
const isInBackground = ref(false)
const lastIntervalTime = ref(null)
const lastState = ref('blur')
const lastStateChange = ref(null)
const feedbackWidget = ref(null)
const updateAvailable = ref(true)
const retryTimer = ref(null)

const showFirstDataSetup = computed({
  get: () => {
    return companyStore.shouldShowFirstDataSetup && !authUserStore.showInvitationScreen
  },
  set: (value) => {
    companyStore.$patch({
      shouldShowFirstDataSetup: value
    })
  }
})
const showInvitationModal = computed({
  get: () => {
    return authUserStore.showInvitationScreen
  },
  set: (value) => {
    authUserStore.$patch({
      showInvitationScreen: value
    })
  }
})

onMounted(() => {
  lastState.value = 'focus'
  lastStateChange.value = Date.now()

  initSentry()

  initUpdateState()

  initStores()

  initApiQueue()

  initViewManager()

  registerEcho()

  registerOverlayEvent()

  checkIfApiQueue()

  registerEventListener()

  startBackgroundChecks()

  startIdleService()

  initTimeEntryStoreToDesktop()
})

onBeforeUnmount(() => {
  if (feedbackWidget.value) {
    feedbackWidget.value.removeFromDom()
  }
  deckService.clearAll()
  activeTimeEntryId.value = null
  window.echo.leave('user.' + authUserStore.userId)
  window.echo.leave('company.' + authUserStore.companyId)
  if (desktopService.isDesktop()) {
    window.removeEventListener('storage', overlayStorageEvent)
  }
  timelinkStoresService.removeAllTimeoutsFrom('controlPanel')
  timelinkStoresService.removeAllIntervalsFrom('controlPanel')
  window.removeEventListener('focus', focus)
  window.removeEventListener('blur', blur)
})


function checkIfApiQueue() {
  if (
    (!retryTimer.value || retryTimer.value.isExpired) &&
    (apiStore.needQueue || apiStore.connectionError)
  ) {
    const time = new Date()
    const seconds = 15
    time.setSeconds(time.getSeconds() + seconds)
    retryTimer.value = useTimer(time)
    timelinkStoresService.setOrRenewInterval(
      'controlPanel',
      'retryCalls',
      () => {
        retryTimer.value = null

        apiStore.createEntry.timeEntries?.forEach((item) => {
          timeEntryStore.createOrUpdateEntry(item, true)
        })
        apiStore.updateEntry.timeEntries?.forEach((item) => {
          timeEntryStore.createOrUpdateEntry(item, true)
        })
        apiStore.deleteEntry.timeEntries?.forEach((item) => {
          timeEntryStore.deleteId(item, true)
        })

        apiStore.updateEntry.notifications?.forEach((item) => {
          notificationsStore.markAsRead(item, true)
        })
        apiStore.deleteEntry.notifications?.forEach((item) => {
          notificationsStore.delete(item, true)
        })

        if (!apiStore.connectionError && !apiStore.needQueue) {
          timelinkStoresService.removeInterval('controlPanel', 'retryCalls')
        }
      },
      seconds * 1000
    )
  }
}
function overlayStorageEvent(event) {
  if (event.key == overlayWindowStore.$id) {
    const newVal = JSON.parse(event.newValue).last_push
    if (newVal > overlayWindowStore.last_push) {
      overlayWindowStore.last_push = newVal
      timelinkStoresService.setOrRenewTimeout(
        'control',
        'safelyFetchActiveTimeEntryAfterOverlay',
        () => {
          timeEntryStore.loadActive()
        },
        3000
      )
    }
  }
}

/*
 * Refs: https://developer.chrome.com/blog/timer-throttling-in-chrome-88?hl=de
 * https://developer.mozilla.org/en-US/docs/Web/API/setInterval
 * https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#timeouts_in_inactive_tabs
 * The execution of timeouts and intervals will be reduced to 1 second and will be packed inside a special queue
 */
function checkIfIsBackground() {
  if (Date.now() - lastIntervalTime.value > 30 * 1000) {
    lastIntervalTime.value = Date.now()
    isInBackground.value = true
  } else if (isInBackground.value) {
    lastIntervalTime.value = Date.now()
    isInBackground.value = false
    updateAllStores()
  }
  lastIntervalTime.value = Date.now()
  deckService.sendHeartBeat()
}
function focus() {
  if (
    lastState.value == 'blur' &&
    (Date.now() - lastStateChange.value > 5 * 60 * 1000 || lastStateChange.value == null)
  ) {
    updateAllStores()
  }
  timelinkStoresService.removeTimeout('controlPanel', 'updateFrontendInBackground')
  lastState.value = 'focus'
  lastStateChange.value = Date.now()
}
function blur() {
  lastState.value = 'blur'
  lastStateChange.value = Date.now()
  timelinkStoresService.setOrRenewTimeout(
    'controlPanel',
    'updateFrontendInBackground',
    () => {
      if (appStore.canUpdate() && !appStore.showModalState) {
        updateFrontend()
      }
    },
    60 * 1000
  )
}
function updateAllStores() {
  timelinkStoresService.setOrRenewTimeout(
    'controlPanel',
    'refreshAllStores',
    () => {
      timeEntryStore.fetchUpdates()
      timeEntryStore.loadActive()
      clientsStore.fetchUpdates()
      projectsStore.fetchUpdates()
      servicesStore.fetchUpdates()
      companyStore.fetch()
      authUserStore.fetchUpdate()
    },
    100
  )
}
function updateFrontend() {
  appStore.clientUpdateNeeded = false
  Sentry.close()
  appStore.updateFrontend()
  setTimeout(() => {
    window.location.reload()
  }, 20)
}
async function startIdleService() {
  idleService.init()
  idleService.listen(() => {
    let userState = idleService.userState()
    let screenState = idleService.screenState()
    idleStore.addState(userState, screenState)
    // console.log(userState, screenState)
    if (screenState == 'locked') {
      ViewManager.switchToLock()
    }
    if (screenState == 'unlocked') {
      ViewManager.unlockIfLocked()
    }
  })
  await idleService.start()
}

function initSentry() {
  Sentry.setUser({
    fullName: authUserStore.user.first_name + ' ' + authUserStore.user.last_name,
    email: authUserStore.user.email
  })
}

function initUpdateState() {
  updateAvailable.value = appStore.canUpdate()
  appStore.$subscribe(() => {
    updateAvailable.value = appStore.canUpdate()
  })
}

function initStores() {
  authUserStore.fetchUpdate()
  authUserStore.fetch()
  companyStore.initFetch()
  clientsStore.initFetch()
  projectsStore.initFetch()
  servicesStore.initFetch()
  timeEntryStore.initFetch()
  notificationsStore.initFetch()
  timelinkStoresService.setOrRenewTimeout('controlPanel', 'notificationsUnread', () => {
    notificationsStore.fetchUnread()
  }, 500)
}

function initApiQueue() {
  apiStore.checkIfQueueIsNeeded()
  apiStore.$subscribe(() => {
    checkIfApiQueue()
    updateAvailable.value = appStore.canUpdate()
  })
}

async function initViewManager() {
  timeEntryStore.isLoadingActive = false
  let num_1 = Date.now()
  // console.log('#1', num_1) // check timings of the first start of the viewmanager.
  await timeEntryStore.loadActive()
  if (timeEntryStore.activeTimeEntry) {
    desktopService.setTrackingActive()
    hasActiveTimeEntry.value = true
  }
  let num_2 = Date.now()
  // console.log('#2', num_2, num_2 - num_1)

  ViewManager.init()
  let num_3 = Date.now()
  // console.log('#3', num_3, num_3 - num_2, num_3 - num_1)
  if (navigator.hid != undefined && navigator.hid != null) {
    navigator.hid.addEventListener('connect', (event) => {

      deckService.init(() => {
        ViewManager.initHwPanel()
        timelinkStoresService.setOrRenewTimeout('controlPanel', 'initHwPanel', () => {
          ViewManager.initHwPanel()
        }, 500)
      })
    })

    navigator.hid.addEventListener('disconnect', () => {
      deckService.init()
    })
  }
  await nextTick()
  deckService.init(() => {
    ViewManager.initHwPanel()
  })
}

function registerEcho() {
  window.echo
    .private('user.' + authUserStore.userId)
    .listen('TimeEntryCreated', (payload) => {
      // console.log('Time entry created broadcast #1')
      timeEntryStore.resetIds()
      timeEntryStore.addOrUpdate(payload.timeEntry, true)
      timeEntryStore.internalUpdateActiveTimeEntry()

    })
    .listen('.timeEntry.created', (payload) => {
      // console.log('Time entry created broadcast #2')
      timeEntryStore.resetIds()
      timeEntryStore.addOrUpdate(payload.timeEntry, true)
      timeEntryStore.internalUpdateActiveTimeEntry()

    })
    .listen('TimeEntryUpdated', (payload) => {
      // console.log('Echo: TimeEntry updated! #1')
      timeEntryStore.resetIds()
      if (payload.timeEntry.id == timeEntryStore.activeTimeEntry) {
        timelinkStoresService.removeTimeout('control', 'safelyFetchActiveTimeEntryAfterOverlay')
      }
      timeEntryStore.addOrUpdate(payload.timeEntry, true)
    })
    .listen('.timeEntry.updated', (payload) => {
      // console.log('Echo: TimeEntry updated! #2')
      timeEntryStore.resetIds()
      if (payload.timeEntry.id == timeEntryStore.activeTimeEntry) {
        timelinkStoresService.removeTimeout('control', 'safelyFetchActiveTimeEntryAfterOverlay')
      }
      timeEntryStore.addOrUpdate(payload.timeEntry, true)
    })
    .listen('TimeEntryDeleted', (payload) => {
      timeEntryStore.resetIds()
      timeEntryStore.removeId(payload.timeEntryId)
    })
    .listen('.timeEntry.deleted', (payload) => {
      timeEntryStore.resetIds()
      timeEntryStore.removeId(payload.timeEntryId)
    })
    .listen('UserUpdated', (payload) => {
      // authUserStore.user = payload
      if (payload?.user?.id && authUserStore?.user?.id) {
        if (authUserStore.user.id == payload.user.id) {
          authUserStore.internalUpdateUserData(payload.user)
          i18n.global.locale.value = payload.user.language ?? i18n.global.locale.value
        }
      } else {
        throw new Error(
          'Payload does not have user infos in it.' +
          payload.user.toString() +
          ' ' +
          authUserStore?.user?.id +
          ' ' +
          authUserStore.userId
        )
      }
    })
    .listen('.user.updated', (payload) => {
      // authUserStore.user = payload
      if (payload?.user?.id && authUserStore?.user?.id) {
        if (authUserStore.user.id == payload.user.id) {
          authUserStore.internalUpdateUserData(payload.user)
          i18n.global.locale.value = payload.user.language ?? i18n.global.locale.value
        }
      } else {
        throw new Error(
          'Payload does not have user infos in it.' +
          payload.user.toString() +
          ' ' +
          authUserStore?.user?.id +
          ' ' +
          authUserStore.userId
        )
      }
    })
    .listen('FrontendVersionUpdateForced', (payload) => {
      if (!payload?.version) {
        return
      }
      appStore.checkVersionDiff(payload.version)
      if (appStore.canUpdate()) {
        updateFrontend()
      }
    })
    .listen('.frontend.version.update.forced', (payload) => {
      if (!payload?.version) {
        return
      }
      appStore.checkVersionDiff(payload.version)
      if (appStore.canUpdate()) {
        updateFrontend()
      }
    })
    .listen('.notification.updated', (payload) => {
      notificationsStore.addOrUpdate(notification)
    })
    .listen('.notification.deleted', (payload) => {
      notificationsStore.removeId(payload.notificationId)
    })
    .notification((notification) => {
      // console.log(notification)
      notificationsStore.addOrUpdate(notification)
    })
  // .listen('')
  window.echo
    .private('company.' + authUserStore.user.company_id)
    .listen('ClientUpdated', (payload) => {
      let exists = clientsStore.updateIfExists(payload.client)
      if (exists) {
        clientsStore.sortEntries()
      }
    })
    .listen('.client.updated', (payload) => {
      let exists = clientsStore.updateIfExists(payload.client)
      if (exists) {
        clientsStore.sortEntries()
      }
    })
    .listen('ClientDeleted', (payload) => {
      clientsStore.removeId(payload.clientId)
      clientsStore.sortEntries()
    })
    .listen('.client.deleted', (payload) => {
      clientsStore.removeId(payload.clientId)
      clientsStore.sortEntries()
    })
    .listen('ServiceCreated', (payload) => {
      servicesStore.addOrUpdate(payload.service)
      servicesStore.sortEntries()
    })
    .listen('.service.created', (payload) => {
      servicesStore.addOrUpdate(payload.service)
      servicesStore.sortEntries()
    })
    .listen('ServiceUpdated', (payload) => {
      servicesStore.addOrUpdate(payload.service)
      servicesStore.sortEntries()
    })
    .listen('.service.updated', (payload) => {
      servicesStore.addOrUpdate(payload.service)
      servicesStore.sortEntries()
    })
    .listen('ServiceDeleted', (payload) => {
      // console.log('Service', payload)

      servicesStore.removeId(payload.serviceId)
      servicesStore.sortEntries()
    })
    .listen('.service.deleted', (payload) => {
      // console.log('Service', payload)
      servicesStore.removeId(payload.serviceId)
      servicesStore.sortEntries()
    })
    .listen('ProjectUpdated', (payload) => {
      let exists = projectsStore.updateIfExists(payload.project)
      if (exists) {
        projectsStore.sortEntries()
      }
    })
    .listen('.project.updated', (payload) => {
      // console.log(payload)
      let exists = projectsStore.updateIfExists(payload.project)
      if (exists) {
        projectsStore.sortEntries()
      }
    })
    .listen('ProjectDeleted', (payload) => {
      projectsStore.removeId(payload.projectId)
      projectsStore.sortEntries()
    })
    .listen('.project.deleted', (payload) => {
      projectsStore.removeId(payload.projectId)
      projectsStore.sortEntries()
    })
    .listen('CompanyUpdated', (payload) => {
      companyStore.internalUpdate(payload.company)
    })
    .listen('.company.updated', (payload) => {
      companyStore.internalUpdate(payload.company)
    })
  window.echo
    .private('frontend')
    .listen('FrontendVersionUpdated', (payload) => {
      if (!payload?.version) {
        return
      }

      appStore.checkVersionDiff(payload.version)
    })
    .listen('.frontend.version.updated', (payload) => {
      if (!payload?.version) {
        return
      }

      appStore.checkVersionDiff(payload.version)
    })
    .listen('FrontendVersionUpdateForcedAll', (payload) => {
      if (!payload?.version) {
        return
      }
      appStore.checkVersionDiff(payload.version)
      if (appStore.canUpdate()) {
        updateFrontend()
      }
    })
    .listen('.frontend.version.update.force.all', (payload) => {
      if (!payload?.version) {
        return
      }
      appStore.checkVersionDiff(payload.version)
      if (appStore.canUpdate()) {
        updateFrontend()
      }
    })
}

function registerOverlayEvent() {
  if (desktopService.isDesktop()) {
    window.addEventListener('storage', overlayStorageEvent)
  }
}

function registerEventListener() {
  window.addEventListener('beforeunload', async () => {
    deckService.resetToLogo()
  })
  window.addEventListener('focus', focus)
  window.addEventListener('blur', blur)
}

function startBackgroundChecks() {
  lastIntervalTime.value = Date.now()
  try {
    if (document.hasFocus()) {
      focus()
    } else {
      blur()
    }
  } catch (error) {
    lastState.value = 'focus'
  }
  lastStateChange.value = Date.now()
  timelinkStoresService.setOrRenewInterval(
    'controlPanel',
    'backgroundCheck',
    checkIfIsBackground,
    2000
  )
}

function initTimeEntryStoreToDesktop() {
  timeEntryStore.$subscribe((mutation, payload) => {
    const hasActiveTimeEntryStore = Boolean(timeEntryStore.activeTimeEntry)
    if (hasActiveTimeEntryStore && !hasActiveTimeEntry.value) {
      hasActiveTimeEntry.value = true
      desktopService.setTrackingActive()
    }
    else if (!hasActiveTimeEntryStore && hasActiveTimeEntry.value) {
      hasActiveTimeEntry.value = false
      desktopService.setTrackingInactive()
    }
  })
}

</script>
