<template>
  <div>
    <div v-if="conflictCount != 0" class="relative p-0 m-0 border-none">
      <Popover v-slot="{ open, close }">
        <PopoverButton data-testid="button" class="overflow-visible text-white border rounded-full button-red">
          <font-awesome-icon :icon="['fa-kit', 'tl-calendar-error']" size="lg" />
        </PopoverButton>
        <PopoverPanel
          class="absolute right-0 z-10 h-full -mt-2 resize-y w-96 top-12 bottom-20 drop-shadow min-h-64 max-h-96">
          <div
            class="grid items-center max-h-full grid-cols-5 gap-0 overflow-y-auto bg-white border divide-y rounded-xl border-mossgray-200 divide-mossgray-200">
            <div class="col-span-3 px-4 py-2 font-bold">
              {{ $t('conflicts.panel.header') }} ({{ conflictCount }})
            </div>

            <template v-if="conflictCount == 0">
              <div class="w-full col-span-5 p-2 py-10 text-center">Keine Benachrichtigungen</div>
            </template>

            <template v-for="(conflict, index) in timeEntryStore.conflicts" :key="conflict.a + conflict.b">
              <div class="col-span-5">
                <div class="w-full px-4 py-3 bg-white">
                  <div class="flex flex-col">
                    <div class="font-bold">
                      {{ $t('conflicts.' + conflict.type + '.title') }}
                    </div>
                    <span v-html="$t('conflicts.' + conflict.type + '.message', {
                      date: '<b>' + new Date(
                        Date.parse(timeEntryStore.getId(conflict.a).started_at)
                      ).toLocaleString('de-DE', {
                        hour: 'numeric',
                        minute: 'numeric',
                        year: '2-digit',
                        month: 'numeric',
                        day: 'numeric'
                      }) + '</b>',
                      provider: useCompanyStore().company.push_provider
                    })"></span>

                    <div class="flex mt-1 space-x-2 place-self-end">
                      <button @click="() => {
                        switchTo(datetime.date(timeEntryStore.getId(conflict.a).started_at))
                        close()
                      }
                        " class="button-mossgray !px-2.5 !py-1.5" v-tippy="$t('conflicts.panel.goto')">
                        <font-awesome-icon :icon="['fa-kit', 'tl-calendar']" class="text-lg" />
                      </button>
                      <button v-if="conflict.type != 2" @click="openModalFor(index, conflict.a, conflict.type)"
                        class="button button-lime !px-2.5 !py-1.5 !text-black"
                        v-tippy="(conflict.type == 9 ? $t('edit_entry') : $t('conflicts.panel.openModal'))">
                        <font-awesome-icon :icon="['fa-kit', 'tl-calendar-edit']" class="text-lg" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </PopoverPanel>
      </Popover>
    </div>
    <TimeEntryConflictModal v-model="showConflictModal" v-model:index="conflictModalIndex" @switch-calendar="switchTo">
    </TimeEntryConflictModal>
    <TimeEntryModalComponent v-model="showTimeEntryModal" v-model:item="timeEntryModalModel"
      :is-active-time-entry="false" />
  </div>
</template>

<script setup>
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useCompanyStore } from '@/stores/company'
import { computed, ref, watchEffect } from 'vue'
import { $t } from '@/config/i18n'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import datetime from '@/lib/datetime'
import TimeEntryConflictModal from '@/components/modals/TimeEntryConflictModal.vue'
import TimeEntryModalComponent from '@/components/modals/TimeEntryModalComponent.vue'

const timeEntryStore = useTimeEntryStore()

const conflictCount = computed(() => {
  return (timeEntryStore.conflicts ?? []).length
})

const showConflictModal = ref(false)
const conflictModalIndex = ref(null)

const showTimeEntryModal = ref(false)
const timeEntryModalModel = ref(null)
watchEffect(() => {
  if (timeEntryModalModel.value == null) {
    timeEntryModalModel.value = timeEntryStore.newEntry()
  }
})

const emit = defineEmits(['switch-calendar'])

function switchTo(event) {
  emit('switch-calendar', event)
}

function openModalFor(index, id, type) {
  if (type != 9) {
    conflictModalIndex.value = index
    showConflictModal.value = true
  } else {
    timeEntryModalModel.value = timeEntryStore.getId(id)
    showTimeEntryModal.value = true
  }
}
</script>
