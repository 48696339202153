<template>
    <div class="p-2">
        <div class="text-lg">
            Allgemeine Funktionen:
        </div>
        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2 p-2">
                <div class="font-bold" @click="showViewManagerFirst = !showViewManagerFirst">
                    ViewManager
                </div>
                <div class="flex flex-row gap-4" v-show="showViewManagerFirst">
                    <BaseButton type="button" @click="ViewManager.debug = true" class="button-mossgray">
                        Aktiviere Debug
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.debug = false" class="button-mossgray">
                        Deaktiviere Debug
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.timing = true" class="button-mossgray">
                        Aktiviere Timings
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.timing = false" class="button-mossgray">
                        Deaktiviere Timings
                    </BaseButton>

                </div>
                <div @click="showPanelInteractionSwitch = !showPanelInteractionSwitch">
                    Panel-Interaktionen (mit Auswertung):
                </div>
                <div class="flex flex-row gap-4" v-show="showPanelInteractionSwitch">
                    <BaseButton type="button" @click="ViewManager.switchToDashboard()" class="button-mossgray">
                        -> Dashboard
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.switchToTracking()" class="button-mossgray">
                        -> Tracking
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.switchToSelection('client_id')"
                        class="button-mossgray">
                        -> Kundenauswahl
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.switchToSelection('project_id')"
                        class="button-mossgray">
                        -> Projektauswahl
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.switchToSelection('service_id')"
                        class="button-mossgray">
                        -> Leistungsauswahl
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.unlockIfLocked()" class="button-mossgray">
                        -> Verlasse Sperrbildschirm
                    </BaseButton>
                </div>
                <div class="flex flex-row gap-4" v-show="showPanelInteractionSwitch">
                    <BaseButton type="button" @click="ViewManager.switchToInnerView('last_used')"
                        class="button-mossgray">
                        -> Zuletzt benutzt
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.switchToInnerView('alphabetical')"
                        class="button-mossgray">
                        -> Alphabetisch
                    </BaseButton>

                </div>
                <div @click="showPanelInteractionShow = !showPanelInteractionShow">
                    Panel-Darstellungen (ohne Auswertung):
                </div>
                <div class="flex flex-row gap-4" v-show="showPanelInteractionShow">
                    <BaseButton type="button" @click="ViewManager.showDashboard()" class="button-mossgray">
                        -> Dashboard
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.showTracking()" class="button-mossgray">
                        -> Tracking
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.showSelection('client_id')" class="button-mossgray">
                        -> Kundenauswahl
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.showSelection('project_id')" class="button-mossgray">
                        -> Projektauswahl
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.showSelection('service_id')" class="button-mossgray">
                        -> Leistungsauswahl
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.showLock()" class="button-mossgray">
                        -> Sperrbildschirm
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.switchToPreviousScreen()" class="button-mossgray">
                        -> Verlasse Sperrbildschirm
                    </BaseButton>
                </div>
                <div class="flex flex-row gap-4" v-show="showPanelInteractionShow">
                    <BaseButton type="button" @click="ViewManager.showSelection(ViewManager.subView.value, 'last_used')"
                        class="button-mossgray">
                        -> Zuletzt benutzt
                    </BaseButton>
                    <BaseButton type="button"
                        @click="ViewManager.showSelection(ViewManager.subView.value, 'alphabetical')"
                        class="button-mossgray">
                        -> Alphabetisch
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.switchToNextPage()" class="button-mossgray">
                        Nächste Seite
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.switchToPage(0)" class="button-mossgray">
                        Reset Page
                    </BaseButton>
                    <BaseButton type="button" @click="ViewManager.switchToPreviousPage()" class="button-mossgray">
                        Vorherige Seite
                    </BaseButton>
                    <BaseButton type="button" @click="deckService.clearAll()" class="button-mossgray">
                        Lösche alles!
                    </BaseButton>
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2 p-2">
                <div class="font-bold" @click="showHwPanel = !showHwPanel">
                    HW-Panel
                </div>
                <div class="flex flex-row gap-4" v-show="showHwPanel">
                    <BaseButton type="button" @click="deckService.debug = true" class="button-mossgray">
                        Aktiviere Debug
                    </BaseButton>
                    <BaseButton type="button" @click="deckService.debug = false" class="button-mossgray">
                        Deaktiviere Debug
                    </BaseButton>
                    <BaseButton type="button" @click="deckService.timing = true" class="button-mossgray">
                        Aktiviere Timing
                    </BaseButton>
                    <BaseButton type="button" @click="deckService.timing = false" class="button-mossgray">
                        Deaktiviere Timing
                    </BaseButton>
                </div>
                <div>
                    Debug-Store:
                </div>
                <div class="flex flex-row gap-4">
                    <div>
                        <BaseButton type="button" @click="debuggingStore.enableDebuggingEverywhere()"
                            class="button-mossgray">
                            Alles aktivieren!
                        </BaseButton>
                    </div>
                    <div>
                        <BaseButton type="button" @click="debuggingStore.disableDebuggingEverywhere()"
                            class="button-mossgray">
                            Alles deaktivieren!
                        </BaseButton>
                    </div>
                    <div>
                        <BaseButton type="button" @click="debuggingStore.$reset()" class="button-mossgray">
                            Setze Store zurück
                        </BaseButton>
                    </div>
                    <div>
                        <BaseInput v-model="searchText">
                            Suche
                        </BaseInput>
                    </div>
                    <div>
                        <BaseInput type="number" v-model="debuggingStore.maxSize">Max. Einträge</BaseInput>
                    </div>
                </div>

            </div>
        </div>
        <div class="w-full overflow-y-auto max-h-[560px]">
            <table class="w-full table-auto">
                <thead class="border-b border-b-black">
                    <tr>
                        <td>
                            Type
                        </td>
                        <td>
                            Ort
                        </td>
                        <td>
                            Nachricht
                        </td>
                        <td>
                            Zeitstempel
                        </td>
                        <td>
                            Zusätzliche Daten:
                        </td>
                        <!-- <td>

                        </td> -->
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(entry, index) in debuggingStore.logEntries" hidden :key="entry.id">
                        <tr class="hover:bg-slate-100"
                            :class="{ 'bg-yellow-100': (searchText && (entry.message.toLowerCase().includes(searchText.toLowerCase()) || entry.source.toLowerCase().includes(searchText.toLowerCase()))), 'bg-orange-100': ((selectedEntry?.id ?? null) == entry.id) }">
                            <td>
                                {{ (entry.type).toString().toUpperCase() }}
                            </td>
                            <td>
                                {{ (entry.source) }}
                            </td>
                            <td>
                                {{ (entry.message) }}
                            </td>
                            <td @click="selectedEntry = entry" class="cursor-pointer">
                                {{ selectedEntry ? Math.round((entry.timestamp - selectedEntry.timestamp)) + 'ms'
                                    :
                                    entry.timestamp
                                }}
                            </td>
                            <td class="max-w-72">
                                <template v-if="entry.source == 'HW-Panel'">
                                    <template v-if="entry.data?.image ?? false">
                                        <span>
                                            {{ entry.data.id ?? null }}
                                        </span>
                                        <div v-if="entry.data.image" class="w-32 h-32 rounded">
                                            <img v-if="!entry.data.image.startsWith('<svg')" :src="entry.data.image"
                                                class="flex object-fill w-full h-full rounded-lg" />
                                            <div v-else class="w-full h-full object-fit">
                                                <div v-html="showDataSvg(entry.data.image, '#fff', '#000')"
                                                    class="flex items-center object-fill w-full h-full"></div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else-if="entry.data?.text">
                                        {{ entry.data.id ?? null }} | {{ entry.data.text }}
                                    </template>
                                    <template v-else>
                                        {{ entry.data }}
                                    </template>
                                </template>
                                <template v-else>
                                    {{ (entry.data) }}
                                </template>
                            </td>
                            <!-- <td @click="debuggingStore.removeId(entry.id)" class="cursor-pointer">
                                x
                            </td> -->
                        </tr>
                    </template>
                </tbody>
            </table>
            <div class="grid w-full h-full grid-cols-1 gap-1 overflow-y-auto divide-y divide-gray-200">
            </div>
        </div>
    </div>
</template>

<script setup>
import ViewManager from '@/panel/ViewManager';
import BaseButton from '../general/BaseButton.vue';
import { computed, ref } from 'vue';
import deckService from '@/services/deck.service';
import { useDebuggingStore } from '@/stores/debugging';
import BaseInput from '../general/BaseInput.vue';

function setDebugLevelViewManager(level) {
    //
}

const debuggingStore = useDebuggingStore()
const selectedEntry = ref(null)
const searchText = ref('')

const showAll = ref(true)
const showHwPanel = ref(true)
const showViewManagerFirst = ref(true)
const showPanelInteractionSwitch = ref(true)
const showPanelInteractionShow = ref(true)

function showDataSvg(data, color = null, bgColor = null) {
    if (data.startsWith('<svg')) {
        const div = document.createElement('div')
        div.innerHTML = data
        div.firstChild.setAttribute('fill', '#fff')
        if (color) {
            div.firstChild.setAttribute('fill', color)
        }
        if (bgColor) {
            div.firstChild.setAttribute('style', 'background-color: ' + bgColor + ';')
        }
        data = div.innerHTML
    }
    return data
}
</script>