<template>
  <!-- Panel -->
  <div class="flex flex-col w-full h-full px-6 site-background">
    <!-- TODO: move to Settings or somewhere else -->
    <div class="flex items-center justify-end w-full">
      <div class="flex flex-row items-center space-x-4" v-if="hasWebhid">
        <div class="text-lg font-semibold">
          <span v-if="decks.length != 0" class="text-green-600"> {{ $t('connected') }} </span>
          <span v-else class="text-mossgray">{{ $t('panel.no_device_found') }}</span>
        </div>
        <BaseButton @click="connnectDevice" class="button-apricot" type="button">
          <span> {{ $t('panel.connect_device') }}</span>
          <!-- <span v-if="decks.length == 0"> Gerät verbinden </span> -->
          <!-- <span v-else>Weiteres Gerät verbinden</span> -->
        </BaseButton>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center flex-grow">
      <div
        class="bg-black rounded-3xl w-full min-w-[600px] max-w-4xl justify-center justify-items-center content-center place-content-center shadow-xl p-10">
        <div class="grid grid-cols-5 gap-6 p-8 gap-x-4 bg-zinc-900 rounded-2xl">
          <template v-for="item in panelPage" v-bind:key="'t_' + item.id">
            <button @click="() => {
              viewManager.pressButton(item.id)
              return;
              const callback = item.callback(item.id)
              if (callback) {
                callback()
              }
            }
              " :title="item.text" type="button" class="w-full aspect-square squircle-clip" :style="{
                'background-color': item.bgColor ?? '#4d4d4d',
                color: item.color ?? '#fff',
                'min-width': '96px'
              }">

              <div v-if="item.image" class="w-full h-full rounded">
                <img v-if="!item.image.startsWith('<svg')" :src="item.image"
                  class="flex object-fill w-full h-full rounded-lg" />
                <div v-else class="w-full h-full object-fit">
                  <div v-html="showDataSvg(item.image, item.color, item.bgColor)"
                    class="flex items-center object-fill w-full h-full"></div>
                </div>
              </div>
              <div v-else class="p-2">
                <span class="text-white" v-if="!item.text"></span>
                <!-- :class="{
                  'text-base': item.text.length > 20 && item.text.length <= 30,
                  'text-sm': item.text.length > 30
                }" -->
                <span v-else class="font-semibold break-all overflow-clip" :class="{
                  'text-2xl': getStyleClass(item.text).textClass == '2xl',
                  'text-xl': getStyleClass(item.text).textClass == 'xl',
                  'text-lg': getStyleClass(item.text).textClass == 'lg',
                  'text-md': getStyleClass(item.text).textClass == 'md',
                }">
                  <template v-for="(item, index) in splitText(item.text)" :key="'t_text_' + item.id + index">
                    <div>
                      {{ item }}
                    </div>
                  </template>


                  <!-- </span> -->
                  <!-- {{ splitText(item.text)
                  }} -->
                </span>
              </div>
            </button>
          </template>
        </div>
        <div class="w-1/3 m-auto mt-10 text-center">
          <img :src="img" />
        </div>
      </div>
    </div>
  </div>
  <SquirclePath></SquirclePath>
</template>

<script setup>
import { useAuthUserStore } from '@/stores/auth-user'
import deckService from '@/services/deck.service'
import featureFlagsService from '@/services/feature-flags.service'
import timelinkStoresService from '@/services/timelink-stores.service'

import BaseButton from '@/components/general/BaseButton.vue'
import SquirclePath from '@/components/general/SquirclePath.vue'
import img from '@/assets/timelink_primary_logo_moosgrauRGB.png'
import { onMounted, ref } from 'vue'
import ViewManager from '@/panel/ViewManager'

const feature = featureFlagsService
const deck = deckService
const authUserStore = useAuthUserStore()
const panelPage = ref([])
const decks = ref([])
const viewManager = ViewManager

const hasWebhid = ref(false)
if (
  navigator != undefined &&
  navigator != null &&
  navigator.hid != undefined &&
  navigator.hid != null
) {
  hasWebhid.value = true
}

onMounted(() => {
  timelinkStoresService.setOrRenewTimeout(
    'virtual',
    'loadStreamDeckAndPanel',
    () => {
      decks.value = deck.getAllStreamDecks()
      panelPage.value = viewManager.getPanel()
    },
    50
  )
  timelinkStoresService.setOrRenewTimeout(
    'virtual',
    'getAllStreamDecks',
    () => {
      decks.value = deck.getAllStreamDecks()
    },
    1000
  )
})

async function connnectDevice() {
  await deckService.request()
  timelinkStoresService.setOrRenewTimeout(
    'virtual',
    'patchPanel',
    () => {
      viewManager.sendAllToHwPanel()
    },
    200
  )
}
function showDataSvg(data, color = null, bgColor = null) {
  if (data.startsWith('<svg')) {
    const div = document.createElement('div')
    div.innerHTML = data
    div.firstChild.setAttribute('fill', '#fff')
    if (color) {
      div.firstChild.setAttribute('fill', color)
    }
    if (bgColor) {
      div.firstChild.setAttribute('style', 'background-color: ' + bgColor + ';')
    }
    data = div.innerHTML
  }
  return data
}

function splitText(text) {
  const size = 144
  const canvas = new OffscreenCanvas(size, size)
  const ctx = canvas.getContext('2d')
  let { text: returnedText, highres, padding, symbol_height } = deck.getTextSettingsBasedOn({ text: text, highres: 1, padding: 32, symbol_height: 24 })
  ctx.textBaseline = 'top'
  ctx.textAlign = 'center'
  ctx.font = symbol_height + 'px Red Hat Display Variable'
  return deck.splitText(ctx, text, size - 2 * padding)
}

function getStyleClass(text) {
  let { text: returnedText, highres, padding, symbol_height } = deck.getTextSettingsBasedOn({ text: text, highres: 1, padding: 32, symbol_height: 24 })
  let textClass = 'xl'
  let paddingClass = 2

  if (symbol_height == 24) {
    textClass = 'xl'
  }
  if (symbol_height == 22) {
    textClass = 'xl'
  }
  if (symbol_height == 20) {
    textClass = 'lg'
  }
  if (symbol_height == 18) {
    textClass = 'md'
  }

  return { textClass, paddingClass }
}
</script>
