import { $t } from '@/config/i18n'
import arraylib from '@/lib/arraylib'
import { setActiveClient, setSelectableClient } from '@/panel/helper/clients'
import { setActiveProject } from '@/panel/helper/projects'
import { setActiveService } from '@/panel/helper/services'
import ViewManager from '@/panel/ViewManager'
import { useClientsStore } from '@/stores/clients'
import { useCompanyStore } from '@/stores/company'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { toValue, watch } from 'vue'
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useDebuggingStore } from '@/stores/debugging'

class Selection {
    showButtonId = 1
    showButtonTextI18n = 'panel.selection_selection'
    /**
     *
     * @param {ViewManager} manager
     */
    handle(manager) {
        /**
         * @var {ViewManagerInnerView} innerView
         */
        let innerView = toValue(manager.innerView)
        /**
         * @var {ViewManagerPage} page
         */
        let page = toValue(manager.page)
        const dims = manager.getDimensions()
        const diff = manager.getDiffOfActualAndPrevious()

        if (manager.debug) {
            useDebuggingStore().addEntry('debug', 'ViewManager', 'Selection: start', { ...this })
        }

        if ('view' in diff || 'subView' in diff) {
            // manager.resetIds(arraylib.range(0, dims.columns), true)
            manager.setId(0, manager.getCancelOrBackButton())
            manager.setId(this.showButtonId, {
                type: 'text',
                text: $t(this.showButtonTextI18n),
                image: null
            })
            this.header(manager)
            // not reset due to fully usage of the header
        }

        // prevent the usage of the view last used if there is no last used entry!
        if (innerView == 'last_used' && this.getLastUsed().length == 0) {
            diff['innerView'] = 'last_used'
            innerView = 'alphabetical'
            manager.innerView.value = 'alphabetical'
        }

        if ('view' in diff || 'subView' in diff || 'innerView' in diff || 'page' in diff) {
            innerView = innerView ?? manager.getDefaultInnerView()
            if ('view' in diff || 'subView' in diff) {
                this.initFetch(manager)
            }
            if (innerView == 'last_used') {
                this.showLastUsed(manager)
            }
            else if (innerView == 'alphabetical') {
                this.showAlphabetic(manager)
            }
            else if (innerView == 'historical') {
                this.showHistorical(manager)
            }
            else {
                throw new Error('No supported inner view found!')
            }
        }

        if ('view' in diff || 'subView' in diff || 'innerView' in diff) {
            this.showNextRotationButton(manager, innerView)
        }
    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    header(manager) {
        // the class should define it.
    }

    /**
     * 
     * @param {ViewManager} manager 
     * @param {import('../ViewManager').ViewManagerInnerView}} innerView 
     */
    showNextRotationButton(manager, innerView) {
        const dims = manager.getDimensions()
        const nextInnerView = manager.getNextRotationInnerView()
        const calcId = dims.columns - 1
        if (nextInnerView == 'last_used') {
            // should not run into the else caused by a condition from the caller function (handle). 
            if (this.getLastUsed().length > 0) {
                if (manager.getPanel()[calcId].text == $t('panel.last_ten_selected')) {
                    return
                }
                manager.setId(calcId, {
                    type: 'image',
                    text: $t('panel.last_ten_selected'),
                    image: manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-clock-rotate-left' })),
                    callback: () => {
                        return () => {
                            manager.switchToInnerView('last_used')
                        }
                    }
                })
            }
            else {
                manager.resetId(calcId)
            }
        }
        else if (nextInnerView == 'alphabetical') {
            if (manager.getPanel()[calcId].text == $t('panel.alphabetical')) {
                return
            }
            manager.setId(calcId, {
                type: 'image',
                text: $t('panel.alphabetical'),
                image: manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-arrow-down-az' })),
                callback: () => {
                    return () => {
                        manager.switchToInnerView('alphabetical')
                    }
                }
            })

        }
        else if (nextInnerView == 'historical') {
            // TODO: tbd
        }
        else {
            throw new Error('No supported inner view found!')
        }
    }
    /**
     * 
     * @param {ViewManager} manager 
     */
    showLastUsed(manager) {
        const dims = manager.getDimensions()
        let notSetIds = arraylib.range(dims.columns, dims.columns * 3)
        // manager.resetIds(arraylib.range(dims.columns, dims.columns * 3), true)
        let row = 1
        let column = 0
        let id = column + row * dims.columns

        if (this.canBeNull()) {
            this.setNullKey(manager)
            notSetIds = this.filterNotSetId(notSetIds, id)
            id = id + 1
        }

        const items = this.getLastUsed(manager)
        items.forEach((item) => {
            if (id >= dims.columns * dims.rows) {
                return
            }
            // setSelectableClient(manager, id, item)
            this.setSelectableItem(manager, id, item)
            notSetIds = this.filterNotSetId(notSetIds, id)
            id = id + 1
        })
        manager.resetIds(notSetIds)
    }

    getLastUsed(manager) {
        return []
    }

    setSelectableItem(manager, id, item) {
        return
    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    showAlphabetic(manager) {
        const dims = manager.getDimensions()
        let notSetIds = arraylib.range(dims.columns, dims.columns * 3)
        // manager.resetIds(arraylib.range(dims.columns, dims.columns * 3), true)

        let column = 0
        let row = 1
        let id = column + row * dims.columns
        let pagination = null
        let needToLoadMore = false
        const page = toValue(manager.page) ?? 0
        let count = this.getAlphabeticCount()

        pagination = manager.getPagination(row, column, dims.rows, dims.columns, count, page, this.canBeNull())
        let lastChange = toValue(manager.lastChange)
        if (page == 0 && this.canBeNull()) {
            this.setNullKey(manager)
            notSetIds = this.filterNotSetId(notSetIds, id)
            id = id + 1
        }

        let items = []
        items = this.getAlphabetic()
        let tempCount = items.length
        if (tempCount - pagination.end < count - pagination.end) {
            needToLoadMore = true
        } else {
            if (pagination.next) {
                manager.setId(dims.rows * dims.columns - 1, manager.getPaginationNextButton(page))
                notSetIds = this.filterNotSetId(notSetIds, dims.rows * dims.columns - 1)
            }
        }
        items = items.slice(pagination.start, pagination.end)

        this.fetchAlphabetic(manager, page, pagination, dims, needToLoadMore)

        items.forEach((item) => {
            if (pagination) {
                if (pagination.previous && id == 0 + (dims.rows - 1) * dims.columns) {
                    id = id + 1
                }
                if (pagination.next && id == dims.rows * dims.columns - 1) {
                    id = id + 1
                }
                if (id >= dims.rows * dims.columns) {
                    return
                }
            }
            this.setSelectableItem(manager, id, item)
            notSetIds = this.filterNotSetId(notSetIds, id)
            // setSelectableClient(manager, id, item)
            id = id + 1
        })

        if (pagination?.previous) {
            manager.setPaginationPreviousButton(0 + (dims.rows - 1) * dims.columns)
            // manager.setId(0 + (dims.rows - 1) * dims.columns, manager.getPaginationPreviousButton(page))
            notSetIds = this.filterNotSetId(notSetIds, 0 + (dims.rows - 1) * dims.columns)
        }

        if (pagination?.next) {
            if (page == null || !needToLoadMore) {
                manager.setPaginationNextButton(dims.rows * dims.columns - 1)
                // manager.setId(dims.rows * dims.columns - 1, manager.getPaginationNextButton(page))
            } else {
                manager.setId(dims.rows * dims.columns - 1, {
                    type: 'image',
                    text: $t('panel.loading_dots'),
                    image: manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-loader' })),
                    color: '#555',
                    deckColor: '#aaa'

                })
            }
            notSetIds = this.filterNotSetId(notSetIds, dims.rows * dims.columns - 1)
        }
        manager.resetIds(notSetIds)
    }

    /**
     * 
     * @param {Array} arr 
     * @param {*} id 
     * @returns 
     */
    filterNotSetId(arr, id) {
        return arr.filter((item) => item != id)
    }

    /**
     * 
     * @param {ViewManager} manager 
     * @returns {number}
     */
    getAlphabeticCount(manager) {
        throw new Error('Abstract function!')
    }

    /**
     * @param  {ViewManager} manager
     * @returns {Array<any>}
     */
    getAlphabetic(manager) {
        throw new Error('Abstract function!')

    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    async initFetch(manager) {
        throw new Error('Abstract function!')
    }


    /**
     * 
     * @param {ViewManager} manager 
     * @param {number} page 
     * @param {any} pagination 
     * @param {any} dims 
     * @param {any} needToLoadMore 
     */
    async fetchAlphabetic(manager, page, pagination, dims) {
        throw new Error('Abstract function!')

    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    showHistorical(manager) {
        throw new Error('Not implemented yet!')

    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    setClient(manager) {
        setActiveClient(manager, 1)
    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    setProject(manager) {
        setActiveProject(manager, 2)
    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    setService(manager) {
        setActiveService(manager, 3)
    }

    canBeNull() {
        return false
    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    setNullKey(manager) {
        throw new Error('Not implemented yet!')
    }
}

export default Selection