import { acceptHMRUpdate, defineStore } from 'pinia'
import { useTimeEntryStore } from './timeEntry'
import semver from 'semver'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useServicesStore } from './services'
import { useClientsStore } from './clients'
import { useProjectsStore } from './projects'
import { usePanelStore } from './panel'
import { captureMessage } from '@sentry/vue'
import { useApiStore } from './api'
import { useDebuggingStore } from './debugging'

/*
 * Persist is set to false due to only store data between components inside a session.
 */
export const useAppStore = defineStore('app', {
  state: () => {
    return {
      showModalState: false,
      selectedTimeEntryId: null,
      clientUpdateNeeded: false,
      showUpdateMessage: false,
      idWorkers: []
    }
  },
  actions: {
    checkVersionDiff(data) {
      if (semver.valid(data)) {
        // eslint-disable-next-line no-undef
        if (semver.gt(data, APP_VERSION)) {
          let diff
          try {
            // eslint-disable-next-line no-undef
            diff = semver.diff(data, APP_VERSION)
          } catch (error) {
            diff = 'minor'
          }
          this.$patch({ clientUpdateNeeded: true, showUpdateMessage: diff != 'patch' })
        }
      }
    },
    addIdWorker(type) {
      this.idWorkers[type] = (this.idWorkers[type] ?? 0) + 1
    },
    removeIdWorker(type, store) {
      this.idWorkers[type] = (this.idWorkers[type] ?? 1) - 1
      if (this.idWorkers[type] == 0) {
        timelinkStoresService.setOrRenewTimeout(
          'appStore',
          'resetIds',
          () => {
            store.resetIds()
          },
          10
        )
      }
    },
    canUpdate() {
      return this.clientUpdateNeeded && !useApiStore().needQueue && !useApiStore().connectionError
    },
    updateFrontend() {
      if (
        !useTimeEntryStore().timeEntries.find((item) => timelinkStoresService.isTempId(item.id))
      ) {
        useTimeEntryStore().$reset()
      } else {
        captureMessage('There are temp timeEntries inside the timeEntry store!')
      }
      useServicesStore().$reset()
      useClientsStore().$reset()
      useProjectsStore().$reset()
      usePanelStore().$reset()
      useDebuggingStore().$reset()
      this.$reset()
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
