<template>
  <div>
    <LoadingSpinner v-model="isLoading" :overAll="true" />
    <div v-if="isOverlayWindow && loggedIn">
      <div class="w-full h-full overflow-auto">
        <RouterView />
      </div>
    </div>
    <div class="relative z-0 flex flex-row text-mossgray" v-else>
      <div class="z-40 relativ">
        <div class="relative z-40 flex h-screen">
          <div
            class="flex flex-col justify-between flex-shrink-0 w-32 space-y-2 text-center border-r bg-ivory-200 text-mossgray border-mossgray-200"
            v-if="loggedIn">
            <!-- TODO: Move navigation to own component? -->
            <div class="flex flex-col flex-shrink-0 w-32 text-center">
              <div class="flex items-center justify-center px-8 mt-8 mb-8">
                <img class="w-full drop-shadow-sm" :src="imgLogo" />
              </div>

              <router-link v-if="is_dev" to="/" class="py-4 hover:text-mossgray hover:bg-ivory"
                active-class="menu-active-background text-mossgray"><font-awesome-icon :icon="['fa-kit', 'tl-home']"
                  class="mb-1 text-4xl" fixed-width /><br />{{
                    $t('menu.dashboard')
                  }}</router-link>

              <!-- TODO: Own component for Navigatiom Item? -->
              <router-link to="/calendar"
                class="py-4 border-t border-b border-r hover:text-mossgray border-r-mossgray-200 border-ivory-200 hover:bg-ivory-400 hover:border-ivory-400"
                active-class="menu-active-background text-mossgray border-r !border-r-ivory border-t border-t-mossgray-200 border-b border-b-mossgray-200"><font-awesome-icon
                  :icon="['fa-kit', 'tl-calendar']" class="mb-1 text-4xl" fixed-width /><br />
                {{ $t('menu.calendar') }}</router-link>

              <router-link to="/reports" v-if="is_dev" class="py-4 hover:text-mossgray hover:bg-ivory"
                active-class="menu-active-background text-mossgray"><font-awesome-icon
                  :icon="['fa-kit', 'tl-chart-pie-1']" class="mb-1 text-4xl" fixed-width /><br />{{
                    $t('menu.reports')
                  }}</router-link>

              <router-link to="/panel"
                class="py-4 border-t border-b border-r hover:text-mossgray border-r-mossgray-200 border-ivory-200 hover:bg-ivory-400 hover:border-ivory-400"
                active-class="menu-active-background text-mossgray border-r !border-r-ivory border-t border-t-mossgray-200 border-b border-b-mossgray-200"><font-awesome-icon
                  :icon="['fa-kit', 'tl-panel']" class="mb-1 text-4xl" /><br />{{ $t('menu.panel') }}</router-link>
              <div class="" v-if="loggedIn && isRouterReady && authUserStore.companyId != null">
                <ControlPanel />
              </div>
            </div>
            <div class="flex flex-col flex-shrink-0 w-32 pb-4 text-center">
              <router-link :to="{ name: 'Account' }"
                class="py-4 border-t border-b border-r hover:text-mossgray border-r-mossgray-200 border-ivory-200 hover:bg-ivory-400 hover:border-ivory-400"
                active-class="menu-active-background text-mossgray border-r !border-r-ivory border-t border-t-mossgray-200 border-b border-b-mossgray-200">
                <font-awesome-icon :icon="['fa-kit', 'tl-profil']" class="mb-1 text-4xl" />
                <div>{{ $t('menu.myAccount') }}</div>
              </router-link>
              <router-link :to="{ name: 'Settings' }"
                class="py-4 border-t border-b border-r hover:text-mossgray border-r-mossgray-200 border-ivory-200 hover:bg-ivory-400 hover:border-ivory-400"
                active-class="menu-active-background-secondary text-mossgray border-r !border-r-ivory border-t border-t-mossgray-200 border-b border-b-mossgray-200">
                <font-awesome-icon :icon="['fa-kit', 'tl-settings']" class="mb-1 text-4xl" />
                <div>{{ $t('menu.settings') }}</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full max-h-screen overflow-hidden site-background">
        <HeaderComponent v-if="
          loggedIn &&
          (currentRouteName == 'Dashboard' || currentRouteName == 'Tracking' || currentRouteName == 'Panel') &&
          authUserStore.companyId != null
        ">
        </HeaderComponent>

        <div class="flex-grow overflow-y-auto">
          <!-- <RouterView /> -->
          <router-view v-slot="{ Component }">
            <keep-alive :exclude="/LoginUser|SSO|ResetPassword/" :include="/TrackingComponent/">
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </div>
    </div>
  </div>
  <AlertComponent />
  <NotificationPopup />
</template>

<script setup>
import { useAuthUserStore } from '@/stores/auth-user'
import ControlPanel from '@/components/ControlPanel.vue'
import TimeEntryModalComponent from '@/components/modals/TimeEntryModalComponent.vue'
import { useAppStore } from './stores/app'
import axios from 'axios'
import semver from 'semver'
import { useTimeEntryStore } from './stores/timeEntry'
import deck from '@/services/deck.service'
import { useRoute, useRouter } from 'vue-router'
import featureFlagsService from '@/services/feature-flags.service'
import desktopService from './services/desktop.service'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { useVersionsStore } from './stores/versions'
import BaseButton from '@/components/general/BaseButton.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
import imgLogo from './assets/timelink_bookmark_signet_elfenbeinRGB.png'
import AlertComponent from './components/AlertComponent.vue'
import NotificationPopup from './components/NotificationPopup.vue'

import '@fontsource-variable/red-hat-display'
import '@fontsource-variable/space-grotesk'
import '@fontsource/space-mono/400.css';
import '@fontsource/space-mono/700.css';
import { i18n } from './config/i18n'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

const authUserStore = useAuthUserStore()
const feature = featureFlagsService

const router = useRouter()
const route = useRoute()

const isRouterReady = ref(false)
const is_dev = ref(import.meta.env.DEV)
const isOverlayWindow = ref(false)
const lastLoginState = ref(false)
const hasActiveTimeEntry = ref(false)
const authSub = ref(null)
const isLoading = ref(false)

const loggedIn = computed(() => {
  return authUserStore.isAuthenticated
})
const currentRouteName = computed(() => {
  return route.name
})

router
  .isReady()
  .then(() => {
    isRouterReady.value = true
    updateIsOverlayWindow()
  })

onMounted(() => {
  authSub.value = useAuthUserStore().$subscribe(() => {
    if (loggedIn.value != lastLoginState.value) {
      lastLoginState.value = loggedIn.value
      if (lastLoginState.value) {
        onLoggedIn()
      }
    }
  })
  if (loggedIn.value) {
    onLoggedIn()
  }
  setInterval(
    () => {
      if (is_dev.value) {
        return
      }
      try {
        axios.get('/version.json').then((response) => {
          if (semver.valid(response.data)) {
            // eslint-disable-next-line no-undef
            useAppStore().checkVersionDiff(response.data)
          }
        })
      } catch (error) {
        //
      }
    },
    5 * 60 * 1000
  )
  useTimeEntryStore().$subscribe((mutation, state) => {
    hasActiveTimeEntry.value = state.activeTimeEntry != undefined && state.activeTimeEntry != null
  })
  hasActiveTimeEntry.value =
    useTimeEntryStore().activeTimeEntry != undefined &&
    useTimeEntryStore().activeTimeEntry != null

  useVersionsStore().addActualVersion()
})

onBeforeUnmount(() => {
  if (authSub.value) {
    authSub.value()
  }
})

function updateIsOverlayWindow() {
  isOverlayWindow.value = route.name == 'OverlayWindow'
}

function onLoggedIn() {
  i18n.global.locale.value = authUserStore.user?.language ?? i18n.global.locale.value
  desktopService.setLoggedIn()
}

</script>
