import { createRouter, createWebHistory } from 'vue-router'
import { useAuthUserStore } from '@/stores/auth-user'
import { useAppStore } from '@/stores/app'
import RegistrationComponentVue from '@/components/public/RegistrationComponent.vue'
import LoginUserVue from '@/components/public/LoginUser.vue'
import SsoComponent from '@/components/public/SsoComponent.vue'
import ResetPassword from '@/components/public/ResetPassword.vue'
import ResetPasswordWithToken from '@/components/public/ResetPasswordWithToken.vue'
import DashboardComponentVue from '@/components/pages/DashboardComponent.vue'
import CompleteRegistration from '@/components/pages/CompleteRegistration.vue'
import ReportsComponentVue from '@/components/pages/ReportsComponent.vue'
import PanelComponentVue from '@/components/pages/PanelComponent.vue'
import TrackingComponentVue from '@/components/pages/TrackingComponent.vue'
import MyAccountComponentVue from '@/components/pages/MyAccountComponent.vue'
import SettingsComponentVue from '@/components/pages/SettingsComponent.vue'
import OverlayWindowVue from '@/components/OverlayWindow.vue'
import ClientsList from '@/components/settings/ClientsList.vue'
import ClientsEdit from '@/components/settings/ClientsEdit.vue'
import GeneralComponentVue from '@/components/settings/GeneralComponent.vue'
import ProjectsEdit from '@/components/settings/ProjectsEdit.vue'
import ServicesEdit from '@/components/settings/ServicesEdit.vue'
import ServicesList from '@/components/settings/ServicesList.vue'
import UsersList from '@/components/settings/UsersList.vue'
import UsersEdit from '@/components/settings/UsersEdit.vue'
import NotFound from '@/components/error/NotFound.vue'
import SubscriptionComponent from '@/components/settings/SubscriptionComponent.vue'
import IntegrationComponent from '@/components/settings/IntegrationComponent.vue'
import TestComponent from '@/components/TestComponent.vue'
import NotificationsComponent from '@/components/settings/NotificationsComponent.vue'
import { useAlertsStore } from '@/stores/alerts'
import { $t } from '@/config/i18n'
import DebugComponent from '@/components/pages/DebugComponent.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: DashboardComponentVue
    },
    {
      path: '/login',
      meta: { requireAuth: false },
      children: [
        {
          path: '',
          name: 'Login',
          component: LoginUserVue
        },
        {
          path: ':token',
          name: 'LoginWithToken',
          component: LoginUserVue
        }
      ]
    },
    {
      path: '/sso',
      name: 'SSO',
      component: SsoComponent,
      meta: { requireAuth: false }
    },
    {
      path: '/reset-password',

      meta: { requireAuth: false },
      children: [
        {
          path: '',
          name: 'ResetPassword',
          component: ResetPassword
        },
        {
          path: ':token',
          name: 'ResetPasswordWithToken',
          component: ResetPasswordWithToken
        }
      ]
    },
    {
      path: '/registration',
      name: 'Registration',
      component: RegistrationComponentVue,
      meta: { requireAuth: false }
    },
    {
      path: '/reports',
      name: 'Reports',
      component: ReportsComponentVue
    },
    {
      path: '/calendar',
      name: 'Tracking',
      alias: '/tracking',
      component: TrackingComponentVue
    },
    {
      path: '/panel',
      name: 'Panel',
      alias: '/virtual',
      component: PanelComponentVue
    },
    {
      path: '/account',
      alias: '/myAccount',
      name: 'Account',
      component: MyAccountComponentVue
    },
    {
      path: '/overlayWindow',
      name: 'OverlayWindow',
      component: OverlayWindowVue
    },

    {
      path: '/completeRegistration',
      name: 'CompleteRegistration',
      component: CompleteRegistration,
      meta: { isSetupPage: true }
    },
    {
      path: '/settings',
      name: 'Settings',
      component: SettingsComponentVue,
      children: [
        {
          path: 'general',
          name: 'General',
          component: GeneralComponentVue
        },
        {
          path: 'notifications',
          name: 'NotificationSettings',
          component: NotificationsComponent
        },
        {
          path: 'clients',
          children: [
            {
              path: '',
              name: 'ClientsList',
              component: ClientsList
              // props: (route) => ({ ...route.query, ...route.params })
            },
            {
              path: ':client_id',
              children: [
                {
                  path: '',
                  name: 'ClientEdit',
                  component: ClientsEdit
                },
                {
                  path: 'projects/:project_id',
                  name: 'ProjectEdit',
                  component: ProjectsEdit
                }
              ]
            }
          ]
        },
        {
          path: 'services',
          children: [
            {
              path: '',
              name: 'ServicesList',
              component: ServicesList
            },
            {
              path: ':service_id',
              name: 'ServiceEdit',
              component: ServicesEdit
            }
          ]
        },
        {
          path: 'users',
          meta: { requireAdmin: true },
          children: [
            {
              path: '',
              name: 'UsersList',
              component: UsersList
            },
            {
              path: ':user_id',
              name: 'UserEdit',
              component: UsersEdit
            }
          ]
        },
        {
          path: 'subscription',
          name: 'Subscription',
          component: SubscriptionComponent,
          meta: { requireAdmin: true }
        },
        {
          path: 'integrations',
          name: 'Integrations',
          component: IntegrationComponent,
          meta: { requireAdmin: true }
        }
      ]
    },
    {
      path: '/debug',
      name: 'debug',
      component: DebugComponent,
      meta: { emfitsRequired: true }
    },
    {
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: NotFound
    },
    ...(import.meta.env.DEV ? [{ path: '/test', name: 'Test', component: TestComponent }] : [])
  ]
})
router.beforeEach(async (to, from, next) => {
  const authUserStore = useAuthUserStore()
  const appStore = useAppStore()
  const alertsStore = useAlertsStore()
  const authRequired = to?.meta?.requireAuth ?? true
  const isSetupPage = to?.meta?.isSetupPage ?? false
  const adminRequired = to?.meta?.requireAdmin ?? false
  const emfitsRequired = to?.meta?.requireEmfits ?? false
  const is_dev = import.meta.env.DEV

  if (appStore.canUpdate() && to.name != 'Settings' && to.name != 'General') {
    useAppStore().clientUpdateNeeded = false
    useAppStore().updateFrontend()

    window.location.href = to.fullPath
    next(false)
    return
  }

  if (authRequired && !authUserStore.isAuthenticated) {
    // if(appStore.clientUpdateNeeded) {
    // }

    next('/login')
  } else if (
    authRequired &&
    authUserStore.isAuthenticated &&
    !authUserStore.companyId &&
    !isSetupPage
  ) {
    next('/completeRegistration')
  } else if (
    authRequired &&
    authUserStore.isAuthenticated &&
    authUserStore.companyId &&
    isSetupPage
  ) {
    next('/tracking')
  } else if (!authRequired && authUserStore.isAuthenticated && !isSetupPage) {
    next('/tracking')
  } else if (
    authRequired &&
    authUserStore.isAuthenticated &&
    adminRequired &&
    authUserStore.user.admin < 9
  ) {
    alertsStore.warning($t('errors.redirect.adminRequired'))
    next({ name: 'Dashboard' })
  } else if (to.name == 'Dashboard' && authUserStore.isAuthenticated && !is_dev) {
    next('/tracking')
  } else if (to.name == 'Settings') {
    next({ name: 'General' })
  } else if (emfitsRequired && !authUserStore.user.email.includes('@emfits.de')) {
    next({ name: 'Dashboard' })
  } else {
    next()
  }
})

export default router
