<template>
  <label for="at_date" class="input-label" v-if="!noLabel">
    <slot name="label">
      {{ $t('timeEntries.start') }}
    </slot>
  </label>

  <div
    class="flex w-full max-w-sm divide-x rounded-full ring-1 ring-mossgray-200 divide-mossgray-200 focus-within:ring-2 focus-within:ring-mossgray">
    <input v-if="!withoutDate" type="date" id="at_date" name="at_date" v-model="at_date" data-testid="dateInput"
      class="pl-4 text-sm leading-none border-0 rounded-l-full basis-5/12 focus:outline-none focus:ring-0" :class="{
        'basis-5/12': $slots.button && !withoutTime,
        'basis-10/12': $slots.button && withoutTime,
        'basis-6/12': !$slots.button
      }" ref="date_input" />
    <input v-if="!withoutTime" id="at_time" name="at_time" v-model="at_time" autocomplete="off" type="text"
      data-testid="timeInput" maxlength="8" @focus="focusTimeInput = true" @blur="focusTimeInput = false"
      class="text-sm leading-none border-0 rounded-none basis-5/12 min-w-16 focus:outline-none focus:ring-0" :class="{
        'rounded-none min-w-16 basis-5/12': $slots.button,
        'rounded-r-full min-w-16 basis-6/12': !$slots.button
      }" ref="time_input" />
    <slot name="button"
      :classes="'basis-2/12 rounded-r-full border-0 cursor-pointer focus:outline-none focus:ring-0 focus:bg-mossgray focus:text-white disabled:text-mossgray-500 disabled:cursor-auto'">
    </slot>
  </div>
  <div class="pt-1 text-sm leading-4 text-red-500">
    <span v-show="!tmpTimeInputValid" data-testid="timeInputError">
      {{ $t('timeEntries.wrong_input') }}
    </span>
  </div>
</template>

<script setup>
import { $t } from '@/config/i18n';
import datetime from '@/lib/datetime'
import { computed, nextTick, onMounted, ref, useTemplateRef, watch } from 'vue';

const props = defineProps(['noLabel', 'allowNullable', 'withoutTime', 'withoutDate']);
const model = defineModel()

const focusTimeInput = ref(false)
const tmpTimeInput = ref(null)
const tmpTimeInputValid = ref(true)
const lastValid = ref(null)

const date_input = useTemplateRef('date_input')
const time_input = useTemplateRef('time_input')


onMounted(() => {
  updateTimeInputs()
  lastValid.value = model.value
})

const at_date = computed({
  get() {
    if (props.withoutDate) {
      return datetime.date(Date.now()).toDateString()
    }

    // datetime.validateTimeInput is responsible 
    if (props.allowNullable && model.value == null) {
      return null
    }
    return datetime.getDate(model.value, lastValid.value)
  },
  set(newVal) {
    if (newVal.split('-')[0] < 2000) {
      return
    }
    model.value = datetime.setDate(model.value, newVal, lastValid.value)
  }
})
const at_time = computed({
  get() {
    if (props.withoutTime) {
      return '00:00:00'
    }
    // return '00:00:00'
    if (focusTimeInput.value || !tmpTimeInputValid.value) {
      return tmpTimeInput.value
    }
    if (props.allowNullable && model.value == null) {
      return null
    }
    return datetime.getTime(model.value, 0, 0, false, lastValid.value)
  },
  set(newVal) {
    if (newVal == '') {
      newVal = null
    }
    if (focusTimeInput.value) {
      tmpTimeInput.value = newVal
      if (newVal == null && props.allowNullable) {
        tmpTimeInputValid.value = true
      } else {
        tmpTimeInputValid.value = datetime.validateTimeInput(newVal)
      }
    } else {
      if (tmpTimeInputValid.value) {
        if (newVal == null && props.allowNullable) {
          tmpTimeInput.value = null
        } else {
          tmpTimeInput.value = datetime.setTime(model.value, newVal, lastValid.value)
        }
      }
    }
    if (tmpTimeInputValid.value) {
      model.value = datetime.setTime(model.value, newVal, lastValid.value)
    }
  }
})

watch(() => focusTimeInput.value, (newVal) => {
  if (!newVal) {
    updateTimeInputs()
  }
})
watch(() => model.value, (newVal) => {
  updateTimeInputs()
  if (tmpTimeInputValid.value && newVal !== undefined) {
    lastValid.value = newVal
  }
})
function updateTimeInputs() {
  tmpTimeInput.value = at_time.value
}
function focus() {
  focusDate()
}
async function focusDate() {
  await nextTick()
  date_input.value?.focus()
}
async function focusTime() {
  await nextTick()
  time_input.value?.focus()
}

defineExpose({
  focusTime,
  focusDate,
})
</script>
