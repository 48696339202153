<template>
  <button class="button" :disabled="disableButton" ref="button">
    <slot></slot>
  </button>
</template>

<script setup>
import { useCompanyStore } from '@/stores/company';
import { computed, ref } from 'vue'

const companyStore = useCompanyStore()

const props = defineProps({
  disabled: { type: Boolean, default: false },
  onlyActiveSub: {
    type: Boolean, default: false
  },
  isLoading: {
    type: Boolean, default: false
  }
})
const button = ref(null)

function focus() {
  button.value?.focus()
}

const disableButton = computed(() => {
  return (props.onlyActiveSub ? !companyStore.subscriptionActive : false) || props.disabled || props.isLoading
})

defineExpose({ focus })
</script>
