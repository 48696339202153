import { useTimeEntryStore } from '@/stores/timeEntry'
import timelinkStoresService from '@/services/timelink-stores.service'

// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'
import { nextTick, watch } from 'vue'
import { useServicesStore } from '@/stores/services'
import ViewManager from '../ViewManager'
import { $t } from '@/config/i18n'

/**
 *
 * @param {ViewManager} manager
 * @param {*} id
 * @param {*} item
 * @param {*} _options
 */
function setSelectableService(
  manager,
  id,
  item,
  // eslint-disable-next-line no-unused-vars
  _options = {
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {
  manager.setIdForCPS(id, {
    type: 'text',
    color: _options.color ?? null,
    bgColor: _options.bgColor ?? null,
    init: (panelItem) => {
      // manager.getImageFor(item, panelItem)
      let watcher = null
      watcher = (service) => {
        return watch(service, () => {
          timelinkStoresService.removeWatcher('panel', manager.getHandlerName(panelItem))
          manager.updateIdForCPS(id, {
            color: _options.color ?? null,
            bgColor: _options.bgColor ?? null,
          }, item)
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            manager.getHandlerName(panelItem),
            watcher(item)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        manager.getHandlerName(panelItem),
        watcher(item)
      )
    },
    callback: () => {
      return () => {
        const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
        if (activeTimeEntry) {
          activeTimeEntry.service_id = item.id
          timelinkStoresService.updateTl(activeTimeEntry)
          useTimeEntryStore().delayUpdateId(activeTimeEntry?.id, 1000, true)
          manager.switchToTracking()
        }
      }
    }
  }, item)
}

/**
 *
 * @param {ViewManager} manager
 * @param {*} id
 * @param {boolean} showNoEntry
  * @param {Object} _options
 */
function setActiveService(
  manager,
  id,
  showNoEntry = false,
  // eslint-disable-next-line no-unused-vars
  _options = {
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {

  const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
  if (!activeTimeEntry.service_id) {
    manager.setId(id, {
      type: 'text',
      text: $t(showNoEntry ? 'panel.no_service' : 'panel.select_service'),
      callback: () => {
        return () => {
          manager.switchToSelection('service_id')
        }
      },
      init: (item) => {
        timelinkStoresService.setOrRenewWatcher('panel', manager.getHandlerName(item), watch(() => activeTimeEntry.service_id, () => {
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
          if (activeTimeEntry && activeTimeEntry.service_id) {
            setActiveService(manager, id, showNoEntry)
          }
        }))
      }
    })
  }
  else {
    manager.setIdForCPS(
      id,
      {
        color: _options.color ?? null,
        bgColor: _options.bgColor ?? null,
        init: (item) => {
          let watcher = null
          watcher = (timeEntry) => {
            let timeWatch = watch(timeEntry, async () => {
              timelinkStoresService.removeWatcher('panel', manager.getHandlerName(item))
              const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
              if (!activeTimeEntry) {
                timelinkStoresService.removeWatcher('panel', manager.getHandlerName(item))
                return
              }

              if (activeTimeEntry.service_id) {
                manager.updateIdForCPS(
                  id,
                  {
                    color: _options.color ?? null,
                    bgColor: _options.bgColor ?? null,
                  },
                  useServicesStore().getId(activeTimeEntry.service_id)
                )
              } else {
                setActiveService(manager, id, showNoEntry)
                return
              }
              timelinkStoresService.setOrRenewWatcher(
                'panel',
                manager.getHandlerName(item),
                watcher(activeTimeEntry)
              )
            })
            let service = useServicesStore().getId(activeTimeEntry.service_id)
            let serviceWatch = service
              ? watch(service, () => {
                timelinkStoresService.removeWatcher('panel', manager.getHandlerName(item))
                manager.updateIdForCPS(
                  id,
                  {
                    color: _options.color ?? null,
                    bgColor: _options.bgColor ?? null,
                  },
                  useServicesStore().getId(activeTimeEntry.service_id)
                )
                timelinkStoresService.setOrRenewWatcher(
                  'panel',
                  manager.getHandlerName(item),
                  watcher(activeTimeEntry)
                )
              })
              : () => {
                //
              }
            return () => {
              timeWatch()
              serviceWatch()
            }
          }
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            manager.getHandlerName(item),
            watcher(activeTimeEntry)
          )
        },
        callback: () => {
          return () => {
            manager.switchToSelection('service_id')
          }
        }
      },
      useServicesStore().getId(activeTimeEntry.service_id)
    )
  }
}

export { setActiveService, setSelectableService }
