<template>
  <TransitionRoot as="template" :show="showModal" ref="animationroot">
    <BaseDialog as="div" class="relative z-40" ref="modal" @close="cancelModal" @keyup.esc="cancelModal">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 z-30 w-screen overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-2xl shadow-gray-600 sm:my-8 sm:w-full sm:max-w-3xl">
              <div class="flex flex-row items-stretch justify-between h-full border-b border-gray-300 bg-mossgray-100"
                v-if="$slots.header">
                <div class="flex px-8 py-3 grow">
                  <h1 class="inline-block text-lg font-bold">
                    <slot name="header"></slot>
                  </h1>
                </div>

                <div class="flex grow-0" v-if="!hideCloseButton">
                  <button
                    class="w-12 rounded-lg text-mossgray-800 ring-inset hover:text-mossgray focus:outline-none focus:ring-0"
                    @click="cancelModal" tabindex="-1">
                    <font-awesome-icon :icon="['fa-kit', 'tl-cross']" size="xl" />
                  </button>
                </div>
              </div>
              <div ref="mainContent">
                <slot></slot>
              </div>

              <div class="flex w-full px-8 py-3 border-t border-gray-300 bg-mossgray-100" v-if="$slots.footer"
                ref="footerContent">
                <slot name="footer"></slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
        <LoadingSpinner v-model="isLoading" :overAll="true" />
      </div>
    </BaseDialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog as BaseDialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  stopAutoFocus: {
    type: Boolean,
    default: false
  },
  hideCloseButton: {
    type: Boolean,
    default: false
  }
})
const showModal = defineModel({ default: false })
const emits = defineEmits(['closeModal', 'closeAnimationFinished'])
const isLoading = computed(() => props.loading ?? false)

const modal = ref(null)
/**
 * @type {import('vue').Ref<?Element>}
 */
const animationroot = ref(null)

const mainContent = ref(null)
const footerContent = ref(null)

watch(
  () => showModal.value,
  async (newVal) => {
    if (!newVal) {
      return
    }
    if (props.stopAutoFocus) {
      return
    }
    await nextTick()
    let input =
      Array.from(mainContent.value?.getElementsByTagName('input')).filter(
        (item) => !item.hasAttribute('hidden')
      )[0] ?? null
    if (input) {
      input.focus()
      return
    }
    let select =
      Array.from(mainContent.value?.getElementsByTagName('select')).filter(
        (item) => !item.hasAttribute('hidden')
      )[0] ?? null
    if (select) {
      select.focus()
      return
    }

    let textarea =
      Array.from(mainContent.value?.getElementsByTagName('textarea')).filter(
        (item) => !item.hasAttribute('hidden')
      )[0] ?? null
    if (textarea) {
      textarea.focus()
      return
    }

    let button =
      Array.from(mainContent.value?.getElementsByTagName('button')).filter(
        (item) =>
          !item.hasAttribute('disabled') ||
          (item.hasAttribute('disabled') && item.getAttribute('disabled') == false)
      )[0] ?? null
    if (button) {
      button.focus()
      return
    }

    input =
      Array.from(footerContent.value?.getElementsByTagName('input')).filter(
        (item) => !item.hasAttribute('hidden')
      )[0] ?? null
    if (input) {
      input.focus()
      return
    }

    select =
      Array.from(footerContent.value?.getElementsByTagName('select')).filter(
        (item) => !item.hasAttribute('hidden')
      )[0] ?? null
    if (select) {
      select.focus()
      return
    }

    textarea =
      Array.from(footerContent.value?.getElementsByTagName('textarea')).filter(
        (item) => !item.hasAttribute('hidden')
      )[0] ?? null
    if (textarea) {
      textarea.focus()
      return
    }

    button =
      Array.from(footerContent.value?.getElementsByTagName('button')).filter(
        (item) =>
          !item.hasAttribute('disabled') ||
          (item.hasAttribute('disabled') && item.getAttribute('disabled') == false)
      )[0] ?? null
    if (button) {
      button.focus()
      return
    }
  }
)

function cancelModal() {
  emits('closeModal')
}
</script>
