import { useTimeEntryStore } from '@/stores/timeEntry'
import { useClientsStore } from '@/stores/clients'
import deckService from '@/services/deck.service'

import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import { $t } from '@/config/i18n'
// eslint-disable-next-line no-unused-vars
import { setClient, setSelectableClient } from '../helper/clients'
import { useAuthUserStore } from '@/stores/auth-user'
import { setCPS } from '../helper/cups'
import timelinkStoresService from '@/services/timelink-stores.service'
import ViewManager from '../ViewManager'
import arraylib from '@/lib/arraylib'
import router from '@/router'

/**
 *
 * @param {ViewManager} manager
 */
async function viewDashboard(manager) {
  // useClientsStore().fetchActiveCount()
  const dims = manager.getDimensions()
  useClientsStore().fetch(
    {
      limit: dims.columns * dims.rows * 1,
      page: 1,
      orders: [
        {
          column: 'name',
          direction: 'asc'
        }
      ]
    },
    false,
    true
  )

  manager.setId(0, {
    type: 'image',
    text: $t('panel.start'),
    image: manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-play' })),
    bgColor: '#FA78AB',
    init: (item) => {
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        manager.getHandlerName(item),
        useTimeEntryStore().$subscribe((mutation, payload) => {
          timelinkStoresService.setOrRenewTimeout('panel', manager.getHandlerName(item), () => {
            setStartWithPreviousEntryData(manager)
          }, 200)
        })
      )
    },
    callback: (item, panelArr, type) => {
      return async () => {
        if (!manager.acquireLock()) {
          return
        }
        const actState = manager.getActualStateAsObject()
        timelinkStoresService.setOrRenewTimeout('panel', manager.getHandlerName(item), () => {
          manager.updateId(item, {
            type: 'image',
            image: manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-loader' }))
          }, actState)
        }, 500)
        // showing loading indicator
        if (!(await useTimeEntryStore().createNewActiveTimeEntry(null, false, true))) {
          throw new Error('No active time entry was created!')
        }
        manager.switchToTracking()
        manager.releaseLock()
      }
    },
  })
  manager.resetIds([1, 2, 3])




  setLatestClients(manager)
  setLatestProjects(manager)
  setStartWithPreviousEntryData(manager)
}

/**
 * @param {ViewManager} manager
 * @param {*} arr
 */
function setStartWithPreviousEntryData(manager, arr) {
  const dims = manager.getDimensions()

  const timeEntries = useTimeEntryStore()
    .getInterval(Date.now() - 2 * 24 * 60 * 60 * 1000, Date.now())
    .sort((a, b) => {
      return Date.parse(b.started_at) - Date.parse(a.started_at)
    })

  const timeEntry = timeEntries[0]

  let calcId = dims.columns - 1
  // && manager.getPanel[calcId].text != $t('panel.start')
  if (timeEntry) {
    if (manager.getPanel()[calcId].text == $t('panel.start_previous')) {
      return
    }
    manager.setId(calcId, {
      type: 'image',
      text: $t('panel.start_previous'),
      image: manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-clock-rotate-left' })),
      bgColor: '#FA78AB',
      callback: (item, panelArr, type) => {
        return async () => {
          const timeEntries = useTimeEntryStore()
            .getInterval(Date.now() - 2 * 24 * 60 * 60 * 1000, Date.now())
            .sort((a, b) => {
              return Date.parse(b.started_at) - Date.parse(a.started_at)
            })
          const timeEntry = timeEntries[0]
          if (
            await useTimeEntryStore().createNewActiveTimeEntry(
              {
                client_id: timeEntry.client_id ?? null,
                project_id: timeEntry.project_id ?? null,
                service_id: timeEntry.service_id ?? null
              },
              false,
              true
            )
          ) {
            manager.switchToTracking()
          } else {
            throw new Error('No active time entry was created!')
          }
        }
      }
    })
  } else {
    manager.resetId(calcId)
  }
}


/**
 *
 * @param {ViewManager} manager
 * @param {*} arr
 */
function setLatestClients(manager) {
  const dims = manager.getDimensions()

  // const latest_clients = useAuthUserStore().lastUsed('clients')

  const lc = useClientsStore().clients.slice(0, 4)

  // add checks to set or updateId

  if (lc.length == 0) {
    manager.resetIds(arraylib.range(dims.columns + 1, dims.columns * 2))
    manager.setId(dims.columns, {
      text: '',
      type: 'text',
      init: (id) => {
        timelinkStoresService.setOrRenewWatcher(
          'panel',
          manager.getHandlerName(id),
          useClientsStore().$subscribe((mutation, payload) => {
            timelinkStoresService.setOrRenewInterval('panel', manager.getHandlerName(id), () => {
              setLatestClients(manager)
            }, 200)
          })
        )
      }
    })
    return
  }

  manager.setId(dims.columns, {
    text: $t('client', 2),
    type: 'image',
    color: '#fff',
    bgColor: '#D1D973',
    image: manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-clients' })),
    init: (id) => {
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        manager.getHandlerName(id),
        useClientsStore().$subscribe((mutation, payload) => {
          timelinkStoresService.setOrRenewInterval('panel', manager.getHandlerName(id), () => {
            setLatestClients(manager)
          }, 200)
        })
      )
    }
  })

  let i = 1

  lc.forEach((client) => {
    setCPS(manager, dims.columns + i, client, {
      // color: '#fff',
      // bgColor: '#D1D973',
      callback: () => {
        return async () => {
          if (!manager.acquireLock()) {
            return
          }
          await useTimeEntryStore().createNewActiveTimeEntry({ client_id: client.id }, false, true)
          manager.switchToTrackingThroughQuickAccess('client_id')
          manager.releaseLock(20)
        }
      }
    })
    i++
  })

  if (lc.length < 4) {
    manager.resetIds(arraylib.range(dims.columns * 1 + lc.length + 1, dims.columns * 2 - 1), true)
    manager.setId(dims.columns * 2 - 1, {
      type: 'text',
      text: $t('panel.new_client'),
      callback: () => {
        return () => {
          router.push({ name: 'ClientsList' })
        }
      }
    })
  }

}

/**
 *
 * @param {ViewManager} manager
 */
function setLatestProjects(manager) {
  const dims = manager.getDimensions()

  const lc = useProjectsStore().projects.slice(0, 4)

  if (lc.length == 0) {
    manager.resetIds(arraylib.range(dims.columns * 2 + 1, dims.columns * 3))
    manager.setId(dims.columns * 2, {
      text: '',
      type: 'text',
      init: (item) => {
        timelinkStoresService.setOrRenewTimeout('panel', manager.getHandlerName(item), () => {
          useProjectsStore().fetch()
        }, 2000)
        timelinkStoresService.setOrRenewWatcher(
          'panel',
          manager.getHandlerName(item),
          useProjectsStore().$subscribe((mutation, payload) => {
            timelinkStoresService.setOrRenewInterval('panel', manager.getHandlerName(item), () => {
              setLatestProjects(manager)
            }, 200)
          })
        )
      }
    })
    return
  }

  manager.setId(dims.columns * 2, {
    text: $t('project', 2),
    type: 'image',
    color: '#fff',
    bgColor: '#B0D9EB',
    image: manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-projects' })),
    init: (item) => {
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        manager.getHandlerName(item),
        useProjectsStore().$subscribe((mutation, payload) => {
          timelinkStoresService.setOrRenewInterval('panel', manager.getHandlerName(item), () => {
            setLatestProjects(manager)
          })
        })
      )
    }
  })

  let i = 1

  lc.forEach((project) => {
    setCPS(manager, dims.columns * 2 + i, project, {
      // color: '#fff',
      // bgColor: '#B0D9EB',
      callback: (item, panelArr, type) => {
        return async () => {
          if (!manager.acquireLock()) {
            return
          }
          await useTimeEntryStore().createNewActiveTimeEntry(
            { client_id: project.client_id, project_id: project.id },
            false,
            true
          )
          manager.switchToTrackingThroughQuickAccess('project_id')
          manager.releaseLock(20)
        }
      }
    })
    i++
  })
  if (lc.length < 4) {
    manager.resetIds(arraylib.range(dims.columns * 2 + lc.length + 1, dims.columns * 3 - 1), true)
    manager.setId(dims.columns * 3 - 1, {
      type: 'text',
      text: $t('panel.new_project'),
      callback: () => {
        return () => {
          router.push({ name: 'ClientsList' })
        }
      }
    })
  }
}

export default viewDashboard
