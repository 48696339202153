<template>
  <!-- Calendar -->
  <div class="w-full site-background">
    <loading-spinner-vue v-model="isLoading" :fullPage="true"></loading-spinner-vue>
    <div class="flex flex-col px-6 pb-16" @keypress="zoomKey">
      <!-- <div class="grid flex-wrap items-stretch grid-cols-3 mb-4"> -->
      <div class="flex flex-wrap items-center justify-between mb-4">
        <div class="relative flex items-center gap-3">
          <div class="inline-flex isolate ">
            <BaseButton type="button" class="button-white relative !rounded-r-none !pl-3 !pr-2"
              @click="vuecalRef.previous()">
              <font-awesome-icon :icon="['fa-kit', 'tl-arrow-left']" class="text-lg" />
            </BaseButton>
            <BaseButton class="button-white !rounded-none -ml-px" @click="vuecalRef.switchView(activeView, new Date())">
              {{ $t('today') }}
            </BaseButton>
            <BaseButton class="button-white !rounded-none -ml-px relative" @click.="showPicker = !showPicker">
              <font-awesome-icon :icon="['fa-kit', 'tl-calendar']" class="text-lg" />
            </BaseButton>
            <BaseButton type="button" class="button-white relative -ml-px !rounded-l-none !pl-2 !pr-3"
              @click="vuecalRef.next()">
              <font-awesome-icon :icon="['fa-kit', 'tl-arrow-right']" class="text-lg" />
            </BaseButton>
          </div>
          <div class="absolute left-0 z-40 w-32 h-64 top-10" v-show="showPicker">
            <VueDatePicker v-model="pickerDate" inline auto-apply :enable-time-picker="false"
              :locale="authUserStore.user?.language ?? 'de'" :week-picker="activeView == 'week'" />
          </div>
        </div>

        <div class="text-3xl text-center">
          <!-- <div class="flex justify-center w-full text-3xl text-center text-mossgray"> -->
          <span v-if="activeView === 'month'">{{ selectedDate.format('MMMM YYYY') }}</span>
          <div class="flex items-center" v-else-if="activeView === 'week'">
            <b>
              {{
                selectedDate.toLocaleString(authUserStore?.user?.language ?? 'de', {
                  month: 'long',
                  year: 'numeric'
                })
              }}</b>
            <span class="py-1 pl-4 pr-3 ml-3 text-base text-white rounded-l-full bg-mossgray">
              {{ $t('calendar.short.calender_week') }} {{ selectedDate.getWeek() }}
            </span>
            <span class="flex items-center py-1 pl-3 pr-4 text-base font-normal rounded-r-full bg-lime text-grotesk">
              <span>{{ calcWeek(selectedDate) }}</span>
            </span>
          </div>
          <span class="flex items-center" v-else-if="activeView === 'day'"><b>
              {{
                selectedDate.toLocaleString(authUserStore?.user?.language ?? 'de', {
                  weekday: 'short'
                })
              }},
              {{
                selectedDate.toLocaleString(authUserStore?.user?.language ?? 'de', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })
              }}</b>
            <span class="py-1 pl-4 pr-3 ml-3 text-base text-white rounded-l-full bg-mossgray">
              {{ $t('calendar.short.calender_week') }} {{ selectedDate.getWeek() }}
            </span>
            <span class="flex items-center py-1 pl-3 pr-4 text-base font-normal rounded-r-full bg-lime text-grotesk">
              <span>{{ calcDay(selectedDate) }}</span>
            </span>
          </span>
          <div class="text-lg font-semibold">
            <!-- <span v-if="activeView == 'week'"> {{ calcWeek(selectedDate) }} </span> -->
            <!-- <span v-if="activeView == 'day'"> {{ calcDay(selectedDate) }} </span> -->
          </div>
        </div>



        <div class="flex items-center gap-3">
          <!-- <div class="flex items-center justify-end gap-3"> -->
          <TimeEntryConflictButton @switch-calendar="switchTo" />

          <div class="inline-flex isolate">
            <BaseButton type="button" class="button-white relative !rounded-r-none !pl-4 !pr-3" @click="zoomIn()"
              v-tippy="$t('tracking.zoom_in')">
              <font-awesome-icon :icon="['fa-kit', 'tl-zoom-in']" class="text-lg" />
            </BaseButton>
            <BaseButton type="button" class="button-white relative -ml-px !rounded-l-none font-bold !pl-3 !pr-4"
              @click="zoomOut()" v-tippy="$t('tracking.zoom_out')">
              <font-awesome-icon :icon="['fa-kit', 'tl-zoom-out']" class="text-lg" />
            </BaseButton>
          </div>

          <div class="inline-flex isolate">
            <BaseButton
              class="button-white !rounded-r-none !pr-3.5 relative font-semibold disabled:!bg-mossgray disabled:text-white"
              :disabled="activeView == 'day'" v-tippy="$t('tracking.open_day_view')" @click="activeView = 'day'">
              <font-awesome-icon :icon="['fa-kit', 'tl-calendar-daily']" class="text-base" />
            </BaseButton>
            <BaseButton
              class="button-white !rounded-l-none relative -ml-px !pl-3.5 font-semibold disabled:!bg-mossgray disabled:text-white"
              :disabled="activeView == 'week'" v-tippy="$t('tracking.open_week_view')" @click="activeView = 'week'">
              <font-awesome-icon :icon="['fa-kit', 'tl-calendar-weekly']" class="text-lg" />
            </BaseButton>
          </div>
        </div>
      </div>



      <div class="overflow-y-auto grow">
        <vue-cal class="bg-white vuecal--blue-theme" ref="vuecal" v-model:active-view="activeView"
          v-model:selected-date="selectedDate" :events="events" :time-from="((authUserStore.user?.settings?.showTimeInterval == undefined ||
            authUserStore.user?.settings?.showTimeInterval == null
            ? null
            : authUserStore.user?.settings?.showTimeInterval) ?? [6, 20])[0] * 60
            " :time-to="((authUserStore.user?.settings?.showTimeInterval == undefined ||
              authUserStore.user?.settings?.showTimeInterval == null
              ? null
              : authUserStore.user?.settings?.showTimeInterval) ?? [6, 20])[1] * 60
              " :disable-views="['years', 'year', 'month']" :time-step="60" :time-cell-height="cellHeight"
          watchRealTime hide-title-bar hide-view-selector :locale="authUserStore?.user?.language ?? 'de'"
          :hide-weekdays="authUserStore.user?.settings?.hideWeekDays ?? []" @view-change="getEvents"
          @event-change="changeEvent" :dblclickToNavigate="false" :clickToNavigate="false" :onEventDblclick="dbClick"
          :cellContextmenu="true" @cell-dblclick="dbClickCell" @event-resizing="eventResize" @event-drag="dragStarted"
          :editable-events="{
            title: false,
            drag: true,
            resize: true,
            delete: false,
            create: false
          }">
          <template v-slot:weekday-heading="{ heading }">
            <div class="font-grotesk">
              <span v-if="heading.date" class="block text-3xl font-bold font-grotesk">{{
                heading.date.format('D')
                }}</span>
              <span>{{ heading.label.toUpperCase() }}</span>
              <div class="text-sm font-grotesk">{{ calcDay(heading.date) }}</div>
            </div>
          </template>
          <template v-slot:no-event="{}">
            <span class="">{{ $t('no_entries') }}</span>
          </template>

          <template v-slot:event="{ event, view }">
            <div @drag="dragStarted" class="relative flex flex-row h-full group" :class="{
              grow: view == 'week',
              'entry-sync-error':
                event.timeEntry?.push_state >= 5 ||
                (event.timeEntry?.tl?.error_code != undefined &&
                  event.timeEntry?.tl?.error_code != null)
            }" @contextmenu="(e) => {
              contextMenu(event, e)
            }
              ">
              <div class="min-w-4 max-w-4 grow-0"
                :style="{ backgroundColor: timeEntryStore.getColor(event.timeEntry).concat('', 'a6') }"></div>
              <div class="px-2 py-1 text-left grow mask-text">
                <div class="flex justify-between vuecal__event-title" style="font-size: 13px; line-height: 1.3"
                  v-show="!isResizing">
                  <div class="flex flex-col font-semibold">
                    <!-- Project: -->
                    <span v-if="event.timeEntry?.project_id">
                      {{ projectsStore.getId(event.timeEntry?.project_id)?.name }}
                    </span>

                    <!-- Client: -->
                    <span v-if="event.timeEntry?.client_id">
                      {{ clientsStore.getId(event.timeEntry?.client_id)?.name }}
                    </span>
                    <span v-else>{{ $t('tracking.no_client_selected') }}</span>
                  </div>

                  <div class="ml-auto">
                    <div v-if="event.isActive" class="text-red-500 pt-1.5 overflow-visible cursor-pointer"
                      @click="openModalWithEntry(event.id)">
                      <font-awesome-icon :icon="['fa-kit', 'tl-stopwatch']" size="xl" fade
                        style="--fa-animation-duration: 3s; --fa-fade-opacity: 0.3" />
                    </div>
                    <span class="overflow-visible text-sm font-bold text-red-500" v-if="
                      timelinkService.isTempId(event.id) &&
                      timeEntryModalModel?.id !== event.id
                    ">
                      <font-awesome-icon :icon="['fa-kit', 'tl-error']" class="text-red-500"
                        v-tippy="$t('errors.no_update_or_create_due_to_connection')" />
                    </span>
                    <font-awesome-icon :icon="['fa-kit', 'tl-cloud-sync']"
                      v-tippy="$t('tracking.entry_will_be_transferred')" v-show="!timelinkService.isTempId(event.id) &&
                        event.timeEntry?.tl.isDirty &&
                        event.timeEntry?.tl?.isUpdating &&
                        (event.timeEntry?.tl?.error_code == undefined ||
                          event.timeEntry?.tl?.error_code == null)
                        " />
                    <font-awesome-icon :icon="['fa-kit', 'tl-error']" class="text-red-700"
                      v-tippy="$t('errors.no_provider_sync')" v-show="!timelinkService.isTempId(event.id) &&
                        !event.timeEntry?.tl.isDirty &&
                        event.timeEntry?.push_state >= 5
                        " />
                    <font-awesome-icon :icon="['fa-kit', 'tl-no-signal']" v-tippy="$t('errors.no_update_retry')"
                      class="text-red-700" v-show="event.timeEntry?.tl?.error_code == 1" />
                  </div>
                </div>
                <span class="vuecal__event-time" style="font-size: 13px; line-height: 1.3">
                  <!-- Using Vue Cal injected Date prototypes -->
                  <div class="flex flex-row justify-between">
                    <span>{{ datetime.getTime(event.start, 0, 0, true) }} -
                      <span v-if="!event.isActive">{{
                        datetime.getTime(event.end, 0, 0, true)
                        }}</span><span v-else>{{ $t('calendar.now') }}</span></span>
                    <span v-if="!event.isActive" class="font-bold">{{
                      showDiff(event.timeEntry.started_at, event.timeEntry.ended_at)
                      }}</span>
                  </div>

                  <div v-if="event.timeEntry?.service_id" v-show="!isResizing" class="flex flex-col">
                    <!-- Service: -->
                    <span>{{ servicesStore.getId(event.timeEntry?.service_id)?.name }}</span>
                  </div>

                  <!-- Description: -->
                  <div v-if="event.timeEntry?.description" v-show="!isResizing"
                    class="w-full py-1 pt-1 mt-1 border-t border-mossgray-200">
                    <span v-text="event.timeEntry?.description"></span>
                  </div>
                </span>
              </div>
            </div>
          </template>
        </vue-cal>
      </div>
      <!-- ENDE VUECAL -->
      <template v-if="componentIsVisible">
        <Teleport :to="'.vuecal__header'"
          v-if="activeView == 'day' && (authUserStore.user?.settings?.hideWeekDays ?? []).includes(selectedDate.getDay() == 0 ? 7 : selectedDate.getDay())">
          <div class="w-full px-8 py-1 text-sm font-semibold text-center bg-apricot-100/50 text-apricot-800"
            :class="{ 'rounded-t-xl': activeView == 'day' }">
            <span>
              {{ $t('calendar.notice.selected_day_outside_of_calender_view') }}
            </span>
          </div>
        </Teleport>
        <!-- START NOTICE FOR OUTSIDE OF VUECAL VIEW -->
        <Teleport :to="'.vuecal__header'" v-else-if="entriesOutsideView">
          <div class="w-full px-8 py-1 text-sm font-semibold text-center bg-apricot-100/50 text-apricot-800"
            :class="{ 'rounded-t-xl': activeView == 'day' }">
            <span>
              {{ $t('calendar.notice.entries_outside_of_calender_view') }}
            </span>
          </div>
        </Teleport>
      </template>
      <!-- END NOTICE FOR OUTSIDE OF VUECAL VIEW -->

      <TimeEntryList :timeEntries="selectedTimeEntries"> </TimeEntryList>
    </div>

    <div class="!bg-white absolute z-30 border border-mossgray-200 shadow-sm rounded-md shadow-mossgray-400"
      v-show="activeContextMenu" id="contextMenu" ref="contextMenuDiv">
      <div class="flex flex-col items-start justify-start w-56 text-left divide-y">
        <button class="w-full px-4 py-2 text-left hover:bg-gray-200 basis-full hover:shadow-inner hover:font-semibold"
          type="button" @click="editEvent">
          <font-awesome-icon :icon="['fa-kit', 'tl-calendar-edit']" class="pr-2" />
          <span v-if="timeEntryStore.activeTimeEntry != selectedContextEvent?.id">{{
            $t('tracking.edit_entry')
            }}</span><span v-else>{{ $t('tracking.edit_active_entry') }}</span>
        </button>
        <button class="w-full px-4 py-2 text-left hover:bg-gray-200 basis-full hover:shadow-inner hover:font-semibold"
          type="button" @click="contextMenuDeleteItem">
          <font-awesome-icon :icon="['fa-kit', 'tl-trash']" class="pr-2 text-red-500" />
          <span v-if="timeEntryStore.activeTimeEntry != selectedContextEvent?.id">{{
            $t('tracking.delete_entry')
            }}</span><span v-else>{{ $t('tracking.delete_active_entry') }}</span>
        </button>
      </div>
    </div>
    <BaseModal v-model="showDeleteModal" :loading="isLoadingDelete" @close-modal="closeDeleteModal">
      <template #header> {{ $t('confirm_deletion') }} </template>

      <div class="p-6">
        <span>
          {{ $t('confirm_deletion_descriptions.timeEntry') }}
        </span>
      </div>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="closeDeleteModal" ref="closeDeleteModalButton">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-red" @click="deleteItem" ref="deleteModalButton">{{
            $t('delete')
            }}</BaseButton>
        </div>
      </template>
    </BaseModal>

    <TimeEntryModalComponent v-model="showTimeEntryModal" v-model:item="timeEntryModalModel"
      :is-active-time-entry="timeEntryModalIsActiveTimeEntry" />
  </div>
  <SquirclePath></SquirclePath>
</template>

<script setup>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import { useTimeEntryStore } from '@/stores/timeEntry'
import date from 'date-and-time'
import { useAuthUserStore } from '@/stores/auth-user'
import { useClientsStore } from '@/stores/clients'
import { useAppStore } from '@/stores/app'
import { useProjectsStore } from '@/stores/projects'
import { useServicesStore } from '@/stores/services'
import { onClickOutside } from '@vueuse/core'
import LoadingSpinnerVue from '@/components/LoadingSpinner.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import BaseButton from '@/components/general/BaseButton.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import datetime from '@/lib/datetime'
import TimeEntryConflictButton from '@/components/buttons/TimeEntryConflictButton.vue'
import { captureException } from '@sentry/vue'
import TimeEntryModalComponent from '@/components/modals/TimeEntryModalComponent.vue'
import TimeEntryList from '@/components/general/TimeEntryList.vue'
import SquirclePath from '@/components/general/SquirclePath.vue'
import { computed, nextTick, onActivated, onBeforeUnmount, onDeactivated, onMounted, ref, useTemplateRef, watch, watchEffect } from 'vue'
import AlertComponent from '../general/AlertComponent.vue'
import { walk } from 'vue/compiler-sfc'
import { $t } from '@/config/i18n'
import { useRouter } from 'vue-router'
import { useCompanyStore } from '@/stores/company'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const timeEntryStore = useTimeEntryStore()
const appStore = useAppStore()
const clientsStore = useClientsStore()
const projectsStore = useProjectsStore()
const servicesStore = useServicesStore()
const authUserStore = useAuthUserStore()
const timelinkService = timelinkStoresService
const companyStore = useCompanyStore()

const router = useRouter()

const vuecalRef = useTemplateRef('vuecal')
const events = ref([])
const tmp = ref([])
const activeView = ref('week')
const selectedDate = ref(new Date())
const selectedEndDate = ref(null)
const cellHeight = ref(50)
const selectedClient = ref(null)
const clients = ref([])
const newTimeEntry = ref(null)
const activeContextMenu = ref(false)
const contextHandler = ref(null)
const selectedContextEvent = ref(null)
const isLoading = ref(false)
const dbClickRegistered = ref(false)
const isResizing = ref(false)
const resizeTimeout = ref(null)
const images = ref({})
const selectedDeleteId = ref(null)
const showDeleteModal = ref(false)
const isLoadingDelete = ref(false)
const activeTimeEntry = ref(null)
const activeTimeEntryEvent = ref(null)
const isDev = ref(false)
const dragActive = ref(false)
const wasDeactivated = ref(false)
const showConflictModal = ref(false)
const showTimeEntryModal = ref(false)
const timeEntryModalId = ref(null)
const timeEntryModalModel = ref(null)
const timeEntryModalIsActiveTimeEntry = ref(false)
const closeDeleteModalButton = useTemplateRef('closeDeleteModalButton')
const contextMenuDiv = useTemplateRef('contextMenuDiv')
const deleteModalButton = useTemplateRef('deleteModalButton')
const selectedTimeEntries = computed(() => {
  let startDate = new Date(
    selectedDate.value.getFullYear(),
    selectedDate.value.getMonth(),
    selectedDate.value.getDate()
  )
  if (activeView.value == 'week') {
    startDate = date.addDays(startDate, 1 - startDate.getDay())
  }

  let { start, end } = calcInterval(startDate, activeView.value)
  let entries = useTimeEntryStore().getInterval(start, end)
  entries = entries.sort((a, b) => {
    return Date.parse(a.started_at) - Date.parse(b.started_at)
  })

  return entries
})

const showPicker = ref(false)
const pickerDate = computed({
  get: () => {
    if (activeView.value == 'week') {
      return [selectedDate.value, date.addMilliseconds(date.addDays(selectedDate.value, 7), -1)]
    }
    return selectedDate.value
  },
  set: (value) => {
    if (Array.isArray(value)) {
      selectedDate.value = value[0]
    }
    else {
      selectedDate.value = value
    }
  }
})
// watchEffect(() => {
//   if (Array.isArray(pickerDate.value)) {
//     selectedDate.value = value[0]
//   }
//   else {
//     selectedDate.value = value
//   }
// })

// watch(() => selectedDate.value, (newVal) => {
//   console.log(newVal)
// })

watch(() => activeTimeEntry.value, (newVal, oldVal) => {
  const serviceName = 'updateActiveTimeEntry'
  if (newVal) {
    if (timelinkStoresService.isTempId(oldVal)) {
      events.value = events.value.filter((item) => item.id != oldVal)
    }
    addOrUpdate(timeEntryStore.getActiveTimeEntry)
    timelinkStoresService.setOrRenewInterval(
      'tracking',
      serviceName,
      () => {
        addOrUpdate(timeEntryStore.getActiveTimeEntry)
      },
      30 * 1000
    )
  } else {
    if (oldVal && !timeEntryStore.findId(oldVal)) {
      events.value = events.value.filter((item) => item.id != oldVal)
    }
    timelinkStoresService.removeInterval('tracking', serviceName)
  }
})

watch(() => showDeleteModal.value, async (newVal) => {
  if (newVal) {

    await nextTick()
    closeDeleteModalButton.value?.focus()
  }
})

const entriesOutsideView = computed(() => {
  // console.log('comp')
  if (events.value && events.value.length == 0) {
    return false
  }
  let start = activeView.value == 'week' ? datetime.getStartOfWeek(selectedDate.value) : datetime.getStartOfDay(selectedDate.value)
  let end = activeView.value == 'week' ? date.addDays(start, 7) : date.addDays(start, 1)
  end = date.addMilliseconds(end, -1)
  // console.log(start, end, activeView.value, selectedDate.value)

  let startDay = (authUserStore.user?.settings?.showTimeInterval ?? [6, 20])[0]
  let endDay = (authUserStore.user?.settings?.showTimeInterval ?? [6, 20])[1] ?? 20

  const hideWeekDays = authUserStore.user?.settings?.hideWeekDays ?? []

  for (const entry of events.value) {
    if (datetime.isNotVisibleInCalendar({ start: entry.start, end: entry.end }, start, end, startDay, endDay, hideWeekDays)) {
      return true
    }
  }
  return false
})
const componentIsVisible = computed(() => router.currentRoute.value.name == "Tracking")

onMounted(() => {
  isDev.value = import.meta.env.DEV
  timelinkStoresService.removeTimeout('timeEntries', 'clearOld')
  if (events.value.length == 0) {
    isLoading.value = true
  }
  contextHandler.value = onClickOutside(contextMenuDiv.value, closeContextMenu)
  timeEntryStore.$subscribe((mutation, state) => {
    if (
      Object.hasOwn(mutation, 'payload') &&
      Object.hasOwn(mutation.payload, 'activeTimeEntry')
    ) {
      activeTimeEntry.value = mutation.payload.activeTimeEntry
    } else {
      activeTimeEntry.value = timeEntryStore.activeTimeEntry
    }
    if (
      state.createdIds.length == 0 &&
      state.changedIds.length == 0 &&
      state.newIds.length == 0 &&
      state.deletedIds.length == 0
    ) {
      return
    }
    useAppStore().addIdWorker('timeEntries')
    if (state.createdIds.length > 0) {
      // isLoading.value = true
      state.createdIds.forEach((createdItem) => {
        timelinkService.removeWatcher('tracking', createdItem.old)
        events.value = events.value.filter((item) => item.id != createdItem.old)
        addOrUpdate(useTimeEntryStore().getId(createdItem.new))
      })
    }
    if (state.changedIds.length > 0) {
      // isLoading.value = true

      state.changedIds.forEach((item) => {
        addOrUpdate(useTimeEntryStore().getId(item))
      })
    }
    if (state.newIds.length > 0) {
      // isLoading.value = true

      state.newIds.forEach((item) => {
        addOrUpdate(useTimeEntryStore().getId(item))
      })
    }
    if (state.deletedIds.length > 0) {
      // isLoading.value = true

      state.deletedIds.forEach((id) => {
        timelinkService.removeWatcher('tracking', id)
        events.value = events.value.filter((item) => item.id != id)
      })
    }
    // stopLoading()
    useAppStore().removeIdWorker('timeEntries', timeEntryStore)
  })
  window.echo
    .private('user.' + useAuthUserStore().userId)
    .listen('TimeEntryDeleted', (payload) => {
      events.value = events.value.filter((item) => item.id != payload.timeEntryId)
    })
    .listen('.timeEntry.deleted', (payload) => {
      events.value = events.value.filter((item) => item.id != payload.timeEntryId)
    })
  timelinkStoresService.setOrRenewTimeout(
    'tracking',
    'initStart',
    () => {
      initStart()
    },
    1000
  )
  clients.value = useClientsStore().clients
  activeTimeEntry.value = timeEntryStore.activeTimeEntry
})
onActivated(() => {

  if (wasDeactivated.value) {
    timelinkStoresService.removeTimeout('timeEntries', 'clearOld')
    contextHandler.value = onClickOutside(contextMenuDiv.value, closeContextMenu)
    fetchEvents({ view: activeView.value, startDate: selectedDate.value }, false)
  }
  wasDeactivated.value = false
  const divEvents = document.querySelectorAll('.vuecal__event')
  divEvents.forEach((item) => {
    item.removeEventListener('dragstart', dragStarted)
    item.addEventListener('dragstart', dragStarted)
    item.removeEventListener('dragend', dragEnded)
    item.addEventListener('dragend', dragEnded)
  })
})

onBeforeUnmount(() => {

  (contextHandler.value)()
  timelinkStoresService.setOrRenewTimeout(
    'timeEntries',
    'clearOld',
    () => {
      useTimeEntryStore().clearOld()
    },
    5000
  )
})

onDeactivated(() => {
  if (contextHandler.value) {
    (contextHandler.value)()
  }
  timelinkStoresService.setOrRenewTimeout(
    'timeEntries',
    'clearOld',
    () => {
      useTimeEntryStore().clearOld()
    },
    1000 * 30
  )
  wasDeactivated.value = true
  const divEvents = document.querySelectorAll('.vuecal__event')
  divEvents.forEach((item) => {
    item.removeEventListener('dragstart', dragStarted)
    item.removeEventListener('dragend', dragEnded)
  })
})


function zoomIn() {
  if (cellHeight.value < 290) {
    cellHeight.value = cellHeight.value * 1.25
  }
}
function zoomOut() {
  if (cellHeight.value > 33) {
    cellHeight.value = cellHeight.value / 1.25
  }
}
function zoomKey(e) {
  if (e.key == '+') {
    zoomIn()
  } else if (e.key == '-') {
    zoomOut()
  }
}
function initStart() {
  let now = new Date()
  let start = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  start = date.addDays(start, 1 - start.getDay())
  getEvents({ view: 'week', startDate: start })
  timelinkStoresService.setOrRenewTimeout(
    'tracking',
    'fetchStart',
    () => {
      fetchStart(false)
    },
    1000
  )
  stopLoading()
}
function fetchStart() {
  let now = new Date()
  let start = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  start = date.addDays(start, 1 - start.getDay())
  selectedDate.value = start
  let end = date.addDays(start, 6)
  end = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 999)
  fetchEvents({ view: 'week', startDate: start, endDate: end }, false)
  selectedDate.value = start
}
function calcInterval(startDate, view) {
  let start = startDate
  let end =
    view == 'day'
      ? date.addMilliseconds(date.addDays(start, 1), -1)
      : date.addMilliseconds(date.addDays(start, 7), -1)

  let diff = view == 'day' ? 1 : 7
  let previous_start = date.addDays(start, -diff)
  let previous_end = date.addDays(end, -diff)

  let follow_start = date.addDays(start, diff)
  let follow_end = date.addDays(end, diff)
  return {
    start,
    end,
    previous_start,
    previous_end,
    follow_start,
    follow_end
  }
}
async function switchTo(newDate) {
  let start = newDate
  if (activeView.value == 'week') {
    start = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())
    start = date.addDays(start, 1 - start.getDay())
  }
  selectedDate.value = start
  // await getEvents({ view: activeView.value, startDate: start })
}
async function getEvents({ view, startDate }) {
  selectedDate.value = startDate
  activeView.value = view ?? activeView.value
  let { start, end, previous_start, previous_end, follow_start, follow_end } =
    calcInterval(startDate, view)
  useTimeEntryStore().selectedTimeInterval.start = previous_start
  useTimeEntryStore().selectedTimeInterval.end = follow_end
  await getInterval(start, end, true, false)
  await loadInterval(start, end, false)
  getInterval(previous_start, previous_end, false, false)
  getInterval(follow_start, follow_end, false, false)
  loadInterval(previous_start, previous_end, false)
  loadInterval(follow_start, follow_end, false)
}
async function fetchEvents({ view, startDate }, showLoading = true) {

  selectedDate.value = startDate
  activeView.value = view ?? activeView.value
  let { start, end, previous_start, previous_end, follow_start, follow_end } =
    calcInterval(startDate, view)
  await loadInterval(start, end, showLoading)
  loadInterval(previous_start, previous_end, false)
  loadInterval(follow_start, follow_end, false)
}
async function loadInterval(start, end, showLoading = true) {
  try {
    return await timeEntryStore.fetchInterval(start, end, () => {
      getInterval(start, end, showLoading, false)
    })
  } catch (error) {
    console.log('Error')
    captureException(error)
    await getInterval(start, end, showLoading, false)
  }
}
async function getInterval(start, end, showLoading = false, loadIfNull = true) {
  let entries = timeEntryStore.getInterval(start, end)
  if (entries.length == 0) {
    if (showLoading) {
      isLoading.value = true
    }
    if (loadIfNull) {
      try {
        await timeEntryStore.fetchInterval(start, end)
      } catch (error) {
        captureException(error)
      }
      entries = timeEntryStore.getInterval(start, end)
    }
  }
  entries.forEach((x) => {
    addOrUpdate(x)
  })
  if (showLoading) {
    stopLoading()
  }
  return entries.length > 0
}
function addOrUpdate(timeEntry, isActiveTimeEntry = false, ignoreConditions = false) {
  if (dragActive.value) {
    timelinkStoresService.setOrRenewTimeout(
      'tracking',
      'addOrUpdate_id_' + timeEntry?.id,
      () => {
        addOrUpdate(timeEntry, isActiveTimeEntry, ignoreConditions)
      },
      Math.floor(Math.random() * 10 + 5)
    )
    return
  }
  if (timeEntry?.started_at) {
    let start = new Date(Date.parse(timeEntry.started_at))
    let end = null
    if (timeEntry.ended_at) {
      end = new Date(Date.parse(timeEntry.ended_at))
    } else {
      isActiveTimeEntry = true
      end = new Date(Date.now())
    }

    let eventData = {
      id: timeEntry.id,
      title: timeEntry.description,
      start: start,
      end: end,
      class: 'ring-1 ring-inset ring-mossgray-200 bg-white',
      color: timeEntryStore.getColor(timeEntry).concat('', 'a6'),
      timeEntry: timeEntry,
      paid: timeEntry.paid,
      isActive: isActiveTimeEntry,
      draggable: !isActiveTimeEntry,
      resizable: !isActiveTimeEntry,
      last_update: timeEntry.updated_at
    }
    let foundEvent = null
    if ((foundEvent = events.value.find((item) => item.id == timeEntry.id))) {
      if (
        ignoreConditions ||
        (foundEvent.last_update ?? 0) != eventData.last_update ||
        timeEntry?.tl?.isDirty ||
        timeEntry.id == timeEntryStore.activeTimeEntry
      ) {
        Object.entries(eventData).forEach((item) => {
          foundEvent[item[0]] = item[1]
        })
      }
    } else {
      events.value.push(eventData)
    }
  }
  const divEvents = document.querySelectorAll('.vuecal__event')
  divEvents.forEach((item) => {
    item.removeEventListener('dragstart', dragStarted)
    item.addEventListener('dragstart', dragStarted)
    item.removeEventListener('dragend', dragEnded)
    item.addEventListener('dragend', dragEnded)
  })
}

function stopLoading() {
  timelinkStoresService.setOrRenewTimeout(
    'tracking',
    'isLoadingHandler',
    () => {
      isLoading.value = false
    },
    200
  )
}
function changeEvent({ event }) {
  let foundEvent = events.value.find((item) => item.id == event.id)
  if (foundEvent) {
    foundEvent.start = event.start
    foundEvent.end = event.end
  }
  if (event.timeEntry) {
    event.timeEntry.started_at = event.start.toISOString()
    event.timeEntry.ended_at = event.end.toISOString()

    timeEntryStore.update([event.timeEntry.id])
  }
}
function dbClick(event) {
  dbClickRegistered.value = true
  // TODO: Second look with martin.
  // if (event.id == timeEntryStore.activeTimeEntry) {
  //   this.$router.push({ name: 'Recording' })
  //   return
  // }
  openModalWithEntry(event.id)
  timelinkStoresService.setOrRenewTimeout(
    'tracking',
    'dbClickRegistered',
    () => {
      dbClickRegistered.value = false
    },
    50
  )
}
function dbClickCell(time) {
  if (dbClickRegistered.value) {
    return
  }
  if (!companyStore.subscriptionActive) {
    return
  }
  const selDate = new Date(Date.parse(time))
  let minutes = selDate.getMinutes()
  minutes = minutes - (minutes % 5)
  const roundedMinutes = new Date(
    selDate.getFullYear(),
    selDate.getMonth(),
    selDate.getDate(),
    selDate.getHours(),
    minutes,
    0
  )
  openModalWithNewEntry(null, roundedMinutes)
}
function editEvent() {
  openModalWithEntry(selectedContextEvent.value.id)
  closeContextMenu()
}
function contextMenu(event, e) {
  contextMenuDiv.value.style.top = e.pageY + 'px'
  contextMenuDiv.value.style.left = e.pageX + 'px'
  activeContextMenu.value = true
  selectedContextEvent.value = event
}
function closeContextMenu() {
  activeContextMenu.value = false
  selectedContextEvent.value = null
}
async function deleteItem() {
  let id = selectedDeleteId.value
  if (!id) {
    return
  }
  isLoadingDelete.value = true
  useTimeEntryStore().deleteId(id)
  selectedDeleteId.value = null
  showDeleteModal.value = false
  isLoadingDelete.value = false
}
async function showDeleteModalForId(id) {
  selectedDeleteId.value = id
  showDeleteModal.value = true
  await nextTick()
  deleteModalButton.value?.focus()
  isLoadingDelete.value = false
}
function closeDeleteModal() {
  selectedDeleteId.value = null
  showDeleteModal.value = false
  isLoadingDelete.value = false
}
function contextMenuDeleteItem() {
  if (selectedContextEvent.value) {
    if (selectedContextEvent.value.timeEntry) {
      showDeleteModalForId(selectedContextEvent.value.timeEntry.id)
    } else {
      events.value = events.value.filter((item) => item.id != null)
    }
    closeContextMenu()
  }
}
function eventResize() {
  isResizing.value = true
  timelinkStoresService.setOrRenewTimeout(
    'tracking',
    'resizeTimeout',
    () => {
      isResizing.value = false
    },
    500
  )
}
function showDiff(start, end) {
  // TODO: Move duration calculation to TimeEntryModel?
  let diff = Date.parse(end) - Date.parse(start)

  return datetime.convertDateNumberToString(diff)
}
function convertDateNumberToString(dateNumber, withoutSeconds = false) {
  let seconds = Math.floor((dateNumber / 1000) % 60)
    .toString()
    .padStart(2, '0')
  let minutes = Math.floor((dateNumber / (60 * 1000)) % 60)
    .toString()
    .padStart(2, '0')
  let hours = Math.floor((dateNumber / (60 * 60 * 1000)) % 24)
    .toString()
    .padStart(2, '0')
  let days = Math.floor(dateNumber / (24 * 60 * 60 * 1000))
    .toString()
    .padStart(2, '0')
  let output = ''
  if (days != '00') {
    output = `${days} d `
  }
  output = output + `${hours}:${minutes}`
  if (!withoutSeconds) {
    output = output + `:${seconds}`
  }
  return output
}
function calcDay(day) {
  return datetime.calcDay(day, timeEntryStore.timeEntries, activeTimeEntry.value)
}
function calcWeek(day) {
  return datetime.calcWeek(day, timeEntryStore.timeEntries, activeTimeEntry.value)
}
function dragStarted() {
  dragActive.value = true
}
function dragEnded() {
  dragActive.value = false
}
function openModalWithNewEntry(event, startTime = null) {
  timeEntryModalIsActiveTimeEntry.value = false
  timeEntryModalModel.value = timeEntryStore.newEntry(startTime)
  showTimeEntryModal.value = true
}
function openModalWithEntry(id) {
  timeEntryModalIsActiveTimeEntry.value = false
  timeEntryModalId.value = id
  if (id == useTimeEntryStore().activeTimeEntry) {
    timeEntryModalIsActiveTimeEntry.value = true
  }
  timeEntryModalModel.value = timeEntryStore.getId(id)
  showTimeEntryModal.value = true
}
</script>
