import ViewManager from '@/panel/ViewManager'
import deckService from '@/services/deck.service'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref, toValue, unref } from 'vue'
import { useTimeEntryStore } from './timeEntry'

export const useDebuggingStore = defineStore('debugging',
  /**
   * @returns 
   */
  () => {
    const logEntries = ref([])
    const maxSize = ref(500)

    function $reset() {
      logEntries.value = []
    }
    /**
     * 
     * @param {string} type 
     * @param {string} source 
     * @param {string} message 
     * @param {any} data 
     */
    function addEntry(type, source, message, data = null) {
      logEntries.value.push({ id: (logEntries.value[logEntries.value.length - 1]?.id ?? 0) + 1, type: type, source: source, message: message, data: unref(toValue(data)), timestamp: Date.now() })
      console.log(Date.now(), type, source, message, data)
      if (logEntries.value.length > maxSize.value) {
        logEntries.value = logEntries.value.splice(-100, logEntries.value.length)
      }
    }

    // TODO: For more complex logging!
    const filterTypes = {
      1: 'info',
      2: 'warning',
      4: 'error',
      8: 'fatal',
      16: 'debug',
      32: 'trace'
    }



    function changeFilter() {

    }

    function changeFilterAndRemoveOthers() {

    }

    function enableDebuggingEverywhere() {
      ViewManager.debug = true
      ViewManager.timing = true
      deckService.debug = true
      deckService.timing = true
      useTimeEntryStore().debug = true
    }

    function disableDebuggingEverywhere() {
      ViewManager.debug = false
      ViewManager.timing = false
      deckService.debug = false
      deckService.timing = false
      useTimeEntryStore().debug = false
    }

    function isDebuggingEnabledAnywhere() {
      return ViewManager.debug || deckService.debug
    }

    // function removeId(id) {
    //   logEntries.value = logEntries.value.filter((item) => item.id !== id)
    // }
    return { logEntries, addEntry, maxSize, enableDebuggingEverywhere, $reset, disableDebuggingEverywhere, isDebuggingEnabledAnywhere }
  }, {
  persist: true,
  storage: localStorage,
  pick: ['logEntries']
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDebuggingStore, import.meta.hot))
}
